import React, { useState , useEffect } from 'react';
import { Link } from 'react-router-dom';
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import swal from 'sweetalert';

import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ReceiptIcon from '@material-ui/icons/Receipt';
import LocalMallIcon from '@material-ui/icons/LocalMall';



import pay from '../assets/images/pay.png';



import icon1 from '../assets/images/icon1 1.png';
import icon2 from '../assets/images/icon2 1.png';
import icon3 from '../assets/images/icon3 1.png';
import icon4 from '../assets/images/icon4 1.png';import {Helmet} from "react-helmet";








const SubCategoriesProduct = (props)=> {
    const [CategoriesUrl , setCategoriesUrl] = useState("")
    const [Cities , setCities] = useState(true)
    const [Product , setProduct] = useState([])
    const [Product1 , setProduct1] = useState([])
    const [Product2 , setProduct2] = useState([])
    const [Categories , setCategories] = useState([])
    const [fal , setFal] = useState(false)
    const [fal1 , setFal1] = useState(false)
    const [CategoriesName , setCategoriesname] = useState({})
    const [SubCategories , setSubCategories] = useState([])
    const [SubClassCategories , setSubClassCategories] = useState([])
    const [subCate , setsubCate] = useState([])
    const [allproduct , setallproduct] = useState([])
    const [Subproduct , setSubproduct] = useState([])
    const [Subproduct1 , setSubproduct1] = useState([])
    const [SortedData , setSortedData] = useState([])
    const [SortedName , setSortedName] = useState([])
    const [SliderPhoto , setSliderPhoto] = useState([])
    const [DataPart2 , setDataPart2] = useState([])
    const [MainCatogories , setMainCatogories] = useState([])
    const [dispaluy53 , setdispaluy53] = useState(true)
    const [Cate1 , setCate1] = useState([])
    const [Cate2 , setCate2] = useState([])
    // categoriesName subcategories

    useEffect(() =>{

        window.scrollTo(0, 0)
        // console.log( props.match.params);
        let Categoriesname = props.match.params ;
        setCategoriesname(Categoriesname)

        // const Inter = setInterval(()=>{
            //  if (!JSON.parse(localStorage.getItem("CateProduct")) ){
            //  var data1 = JSON.parse(localStorage.getItem("Cate")) 

        // var Category =  props.match.url.split("/")
        // setCategoriesUrl(data1)
        // var Category1 =  CategoriesUrl.split("-").join(" ")
        fetch("https://pak-dream-back.vercel.app/AllProduct",{
                    method: "GET",
                    headers :  {
                    "Content-Type" : "application/json" , 
                } ,
                })
                .then(res7=>res7.json())
                .then(res8=>{
                    setallproduct(res8)
                    const NonActive = res8.filter((res9,i)=>{
                        // console.log(res9.status , res9.Product_Catagories , "Cars"  , res9.Product_Sub_Catagories , Categoriesname.subCate );
                        if(res9.status && res9.Product_Catagories === "Cars"  ){
                            return res9 
                        }
                    })

                    setSubproduct(NonActive)
                    console.log(NonActive)
                })

        
       

                // fetch("https://pak-dream-back.vercel.app/AllProduct",{
                //     method: "GET",
                //     headers :  {
                //     "Content-Type" : "application/json" , 
                // } ,
                // })
                // .then(res7=>res7.json())
                // .then(res8=>{
                //     setallproduct(res8)
                //     const NonActive = res8.filter((res9,i)=>{
                //         console.log(res9.status , res9.Product_Catagories , "Cars"  , res9.Product_Sub_Catagories , Categoriesname.subCate );
                //         if(res9.status && res9.Product_Catagories === "Cars"  ){
                //             return res9 
                //         }
                //     })

                //     setSubproduct(NonActive)
                //     console.log(NonActive)
                // })

        

      



},[])


    const setSortPrice1 = (e)=>{
      setdispaluy53(!dispaluy53)
      if(Subproduct.length !== 0){

        SortedName.push(e)
        console.log(e,Subproduct);
        if (e === "Low"){
            const Pro6 = Subproduct.sort(function (a, b) {
                    return parseFloat(a.Product_Price) - parseFloat(b.Product_Price)  
                  });
                  console.log(Pro6);
                  let reversed_array = [];
                  Pro6.forEach((element) => {
                    reversed_array.unshift(element);
                });
                  console.log(Pro6,reversed_array)
                  setSubproduct(reversed_array)
                  setSubproduct(Pro6)
                  setdispaluy53(!dispaluy53)
                  setdispaluy53(!dispaluy53)
    
        }
        if (e === "High"){
            const Pro6 = Subproduct.sort((a, b)=> {
                    return parseFloat(b.Product_Price) - parseFloat(a.Product_Price)  
                    
                });
              //   let reversed_array = [];
              //   Pro6.forEach((element) => {
              //     reversed_array.unshift(element);
              // });
              //   console.log(Pro6,reversed_array)
                setSubproduct(Pro6)
                setdispaluy53(!dispaluy53)
                setdispaluy53(!dispaluy53)

        }
        if (e === "A"){
            const Pro6 = Subproduct.sort((a, b)=> {
                var nameA = a.Product_Name.toUpperCase(); 
                var nameB = b.Product_Name.toUpperCase(); 
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
    
                return 0;
                });
                console.log(Pro6);
                setSubproduct(Pro6)
                
              }
              
              setSubproduct1([])
              setdispaluy53(!dispaluy53)

            }
            else{
        
                SortedName.push(e)
                console.log(e);
                if (e === "Low"){
                    const Pro6 = Subproduct.sort((a, b)=> {
                            return parseFloat(b.Product_Price) - parseFloat(a.Product_Price)  
                            
                        });
                        setSubproduct1(Pro6)
            
                }
                if (e === "High"){
                    const Pro6 = Subproduct.sort((a, b)=> {
                            return parseFloat(b.Product_Price) - parseFloat(a.Product_Price)  
                            
                        });
                        setSubproduct1(Pro6.reverse())
            
                }
                if (e === "A"){
                    const Pro6 = Subproduct.sort((a, b)=> {
                        var nameA = a.Product_Name.toUpperCase(); 
                        var nameB = b.Product_Name.toUpperCase(); 
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
            
                        return 0;
                        });
                        console.log(Pro6);
                        setSubproduct1(Pro6)
                        
                      }
                      
                      
                      setSubproduct([])
                    }
                    setdispaluy53(!dispaluy53)

                  }
  

  
const addtocartproduct = (productDetail) =>{
  swal("Update!", "Your Item Add in Cart!", "success");

      
  // document.getElementById("myDi").style.visibility = "visible"
  // setTimeout(()=>{
  // document.getElementById("myDi").style.visibility = "hidden"

  // },1000)
  console.log(productDetail)
  if (productDetail[0].Product_Price_Discounted === null){
  const data =  {...productDetail[0] ,
      Percentage : 0,
      DeliveryStatus : "Pending",
      Pieces : 1 ,
      Total_Product_Price  : productDetail[0].Product_Price * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id ){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
  return {...item,
      Pieces : 1 + item.Pieces,
      Total_Product_Price  : (productDetail[0].Product_Price * 1 )+ item.Total_Product_Price}
  }
  else{
  console.log("double not match");
  return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }

  }
  else if (productDetail[0].Product_Price_Discounted){
  const data =  {...productDetail[0] ,
      Percentage : 0,
      DeliveryStatus : "Pending",
      Pieces : 1 ,
      Total_Product_Price  : productDetail[0].Product_Price_Discounted * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id ){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
  return {...item,
      Pieces : 1 + item.Pieces,
      Total_Product_Price  : (productDetail[0].Product_Price_Discounted * 1 )+ item.Total_Product_Price}
  }
  else{
  console.log("double not match");
  return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }

  }
  else if (productDetail[0].Size_Discounted[0]){
      
          const data =  {...productDetail[0] ,
              Percentage : 0,
              DeliveryStatus : "Pending",
              Pieces : 1 ,
              Size : productDetail[0].Size[0],
              Product_Price_Discounted : productDetail[0].Size_Discounted[0],
              Total_Product_Price  : productDetail[0].Size_Discounted[0] * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
      return {...item,
          Pieces : 1 + item.Pieces,
          Total_Product_Price  : (productDetail[0].Size_Discounted[0] * 1 )+ item.Total_Product_Price}
  }
  else{
      console.log("double not match");
      return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }

  }
  else{




          const data =  {...productDetail[0] ,
              Percentage : 0,
              DeliveryStatus : "Pending",
              Pieces : 1 ,
              Size : productDetail[0].Size[0],
              Product_Price_Discounted : productDetail[0].Price[0],
              Total_Product_Price  : productDetail[0].Price[0] * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
      return {...item,
          Pieces : 1 + item.Pieces,
          Total_Product_Price  : (productDetail[0].Price[0] * 1 )+ item.Total_Product_Price}
  }
  else{
      console.log("double not match");
      return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }


  }


      

}


const addtocartproduct8 = (productDetail) =>{
    swal("Update!", "Item Save!", "success");
  
        
    // document.getElementById("myDi").style.visibility = "visible"
    // setTimeout(()=>{
    // document.getElementById("myDi").style.visibility = "hidden"
  
    // },1000)
    console.log(productDetail)
    if (productDetail[0].Product_Price_Discounted === null){
    const data =  {...productDetail[0] ,
        Percentage : 0,
        DeliveryStatus : "Pending",
        Pieces : 1 ,
        Total_Product_Price  : productDetail[0].Product_Price * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id ){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
    return {...item,
        Pieces : 1 + item.Pieces,
        Total_Product_Price  : (productDetail[0].Product_Price * 1 )+ item.Total_Product_Price}
    }
    else{
    console.log("double not match");
    return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
    }
    else if (productDetail[0].Product_Price_Discounted){
    const data =  {...productDetail[0] ,
        Percentage : 0,
        DeliveryStatus : "Pending",
        Pieces : 1 ,
        Total_Product_Price  : productDetail[0].Product_Price_Discounted * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id ){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
    return {...item,
        Pieces : 1 + item.Pieces,
        Total_Product_Price  : (productDetail[0].Product_Price_Discounted * 1 )+ item.Total_Product_Price}
    }
    else{
    console.log("double not match");
    return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
    }
    else if (productDetail[0].Size_Discounted[0]){
        
            const data =  {...productDetail[0] ,
                Percentage : 0,
                DeliveryStatus : "Pending",
                Pieces : 1 ,
                Size : productDetail[0].Size[0],
                Product_Price_Discounted : productDetail[0].Size_Discounted[0],
                Total_Product_Price  : productDetail[0].Size_Discounted[0] * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
        return {...item,
            Pieces : 1 + item.Pieces,
            Total_Product_Price  : (productDetail[0].Size_Discounted[0] * 1 )+ item.Total_Product_Price}
    }
    else{
        console.log("double not match");
        return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
    }
    else{
  
  
  
  
            const data =  {...productDetail[0] ,
                Percentage : 0,
                DeliveryStatus : "Pending",
                Pieces : 1 ,
                Size : productDetail[0].Size[0],
                Product_Price_Discounted : productDetail[0].Price[0],
                Total_Product_Price  : productDetail[0].Price[0] * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
        return {...item,
            Pieces : 1 + item.Pieces,
            Total_Product_Price  : (productDetail[0].Price[0] * 1 )+ item.Total_Product_Price}
    }
    else{
        console.log("double not match");
        return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
  
    }
  
  
        
  
  }
  
        return (
       




<>
           <Helmet>
                <link rel="canonical" href="https://pakdreamrentacar.com/rent-a-car-in-karachi" />
            </Helmet>
          <main className="main">
          <div className="text-center">
            <div className="container">
              <h2 className="page-title" style={{fontSize:"27px",fontWeight:"bold", margin:"0px 10px",padding:0,textTransform:"uppercase"}}>Rent a Car in Karachi  with driver<span></span></h2>
            </div>{/* End .container */}
          </div>{/* End .page-header */}
<hr  style={{margin:"0px"}}/>
          <div className="page-content">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="toolbox">
                    <div className="toolbox-left" style={{width:"100%"}}>
                      <div className="toolbox-info" style={{width:"100%"}}>
                     <div className='dskjffkj' style={{width:"100%",display:"flex",color : "black"}}>
                       <div className='sfsdf' style={{display:"flex",flexDirection :"column",width:"140px",marginRight : "100px"}}>
                         <span style={{color : "black",fontSize:"17px"}}>Price </span>
                         <span style={{marginRight : "20px",width:"140px",color : "black",fontSize:"15px"}} > Rs : 4500 to 20000 </span>
                         <div style={{display:"flex"}}>

                         <input className='gklfgkl' type="range" name="" id="" value={0} min="0" max="9500" style={{color:"blue",color : "black",fontSize:"17px",padding:"0px",width:"60px"}}/>
                         <input className='gklfgkl' type="range" name="" id="" value={19500} min="9500" max="20000" style={{color:"blue",color : "black",fontSize:"17px",padding:"0px",width:"60px"}}/>
                         </div>
                        </div>
                        <div>
                          <span style={{color : "black",fontSize:"18px"}}>{Subproduct.length} <b>Cars</b>  founds</span>
                        </div>
                        </div> 
                        
                      </div>{/* End .toolbox-info */}
                    </div>{/* End .toolbox-left */}
                    <div className="toolbox-right" style={{width:"100%",justifyContent:"right",alignItems:"flex-end"}}>
                      <div className="toolbox-sort">
                        <label htmlFor="sortby">Sort by:</label>
                        <div className="select-custom">
                          <select name="sortby" id="sortby" className="form-control">
                            <option value="popularity" selected="selected">Most Popular</option>
                            <option value="Low" onClick={()=>setSortPrice1("Low")}>Low → High</option>
                          <option value="High" onClick={()=>setSortPrice1("High")}>High → Low</option>
                          <option value="A" onClick={()=>setSortPrice1("A")}>A → Z</option>
                            <option value="rating">Most Rated</option>
                            <option value="date">Date</option>
                          </select>
                        </div>
                      </div>{/* End .toolbox-sort */}
                      
                    </div>{/* End .toolbox-right */}
                   </div>{/* End .toolbox */}
                  <div style={{display:"flex",justifyContent:"space-evenly",color:"black",fontSize:"19px",fontWeight:"500"}}>

{/* <input className='gklfgkl' type="radio" name="" id="" value={0} min="0" max="9500" style={{color:"blue",color : "black",fontSize:"17px",padding:"0px",width:"60px"}}/>
<input className='gklfgkl' type="radio" name="" id="" value={19500} min="9500" max="20000" style={{color:"blue",color : "black",fontSize:"17px",padding:"0px",width:"60px"}}/> */}
<span>
 <input className='gklfgkl' type="radio" value={Cities} name="Cities1"  onClick={()=>setCities(true)}  style={{accentColor:"firebrick", backgroundColor:"#EFE5CE",border: "10px solid #EFE5CE",marginRight:"9px"}}/> In City
</span>
<span>
 <input className='gklfgkl' type="radio" value={Cities} name="Cities1"  onClick={()=>setCities(false)}   style={{accentColor:"firebrick", backgroundColor:"#EFE5CE",border: "10px solid #EFE5CE",marginRight:"9px"}}/> Out City

</span>
<span>
 <input className='gklfgkl' type="radio" value={Cities} name="Cities1"  onClick={()=>setCities(true)}   style={{accentColor:"firebrick", backgroundColor:"#EFE5CE",border: "10px solid #EFE5CE",marginRight:"9px"}}/> Short Rental

</span>

</div>
                  <div className="products mb-3">
                    <div className="row justify-content-center">
                    {Cities ? 
                    Subproduct.map((res,i)=>{
                        return(
      <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                              <div className="product product-7 text-center" style={{height : "auto",
    border: "2px solid rgb(149, 6, 13)",
    borderRadius: "20px",
    padding: "10px 0px",
    margin: "11px"}}>
                                <figure className="product-media">
                                  <Link  to={"/"+res.Product_Name_Full}>
                                    <img src={res.Product_Image_Upload} alt="Product image" className="product-image" />
                                  </Link>
                                  <div className="product-action-vertical">
                                    <a className="btn-product-icon btn-wishlist btn-expandable"  onClick={()=>addtocartproduct8([res])}><span>add to wishlist</span></a>
                                    <Link to={"/"+res.Product_Name_Full} className="btn-product-icon btn-quickview" title="Quick view"><span>Quick view</span></Link>
                                  </div>{/* End .product-action-vertical */}
                                </figure>{/* End .product-media */}
                                <div className="product-body">
                                  <div className="product-cat">
                                  </div>{/* End .product-cat */}
                                  <h3 className="product-title"   style={{maxWidth:"100%",maxHeight : "59px",overflow : "hidden",textAlign:"left"}}><Link to={"/"+res.Product_Name_Full}>{res.Product_Name}</Link></h3>{/* End .product-title */}


                            <div style={{display :"flex",flexDirection : "row",justifyContent :"space-between"}}>
                            <div>
                                <div style={{display:"flex",margin:"0px 0px 15px 0px"}}>
                                <img src={icon1} style={{height:"23px",width:"20px",color: "#95060D",fontSize : "15",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.person && res.person } Seats</p>
                            </div>
                                <div style={{display:"flex",}}>
                                <img src={icon2} style={{height:"23px",width:"20px",color: "#95060D",fontSize : "15",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>Automatic</p>
                            </div>
                            </div>
                            <div>
                               
                                <div style={{display:"flex",flexDirection:"column"}}>
                                  <div>

                                {res.Product_Price_Discounted ? 
                  <div style={{display:"flex"}}>
                  <del><span className="product-price" style={{fontSize:"13px",fontWeight:"bold",color:"black"}}>Rs {res.Product_Price} <br /></span></del>
                    <span className="product-price" style={{fontSize:"19px",fontWeight:"bold",color:"rgb(149, 6, 13)",padding:"0px 20px"}}>Rs {res.Product_Price_Discounted} <br /> / Day</span> 
                  </div>
                  :
                  <span className="product-price" style={{fontSize:"19px",fontWeight:"bold",color:"rgb(149, 6, 13)",padding:"0px 20px"}}>                  Rs {(res.Product_Price)} <br />
             / Day
                  </span>            
                                    }
                                  </div>
                              <div>
                              <button class="animated-button8" style={{marginBottom:"0px"}}>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  With Driver
</button>
                              </div>

                             
                            </div>
                           
                            </div>
                            </div>
                            <div style={{display :"flex",flexDirection : "row",justifyContent :"space-between"}}>
                            <div>
                                <div style={{display:"flex",margin:"0px 0px 15px 0px"}}>
                                <img src={icon3} style={{height:"16px",width:"20px",color: "#95060D",fontSize : "15",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black",textAlign:"left"}}> ( 10hrs/day )
                                {/* <p style={{fontSize:11,fontWeight :"600",color:"black",textAlign:"left"}}>With Driver (10hrs/day) */}
                                </p>
                            </div>
                                <div style={{display:"flex",}}>
                                <img src={icon4} style={{height:"19px",width:"20px",color: "#95060D",fontSize : "15",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>Overtime: PKR {res.Overtime}/hr</p>
                            </div>
                            </div>
                            <div>
                                <div style={{display:"flex",marginTop:"40px"}}>
                                <div style={{display:"flex",marginRight: "28px"}}>
                                <ReceiptIcon style={{height:"19px",width:"20px",color: "rgb(205 112 112)",fontSize : "18",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>Without Tax</p>
                            </div>
                                {/* <button class="animated-button8">
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  With Driver
</button> */}
                                {/* {res.Product_Price_Discounted ? 
                  <><span className="product-price" style={{fontSize:"14px",fontWeight:"bold",color:"black"}}>Rs {res.Product_Price_Discounted} <br /> / Day</span></>
                  :
                  <span  style={{fontSize:"14px",fontWeight:"bold",color:"black"}}>
                  Rs {(res.Product_Price)} <br />
             / Day
                  </span>            
                                    } */}
                            </div>
                            </div>
                            </div>
                        







                                  {/* <hr style={{margin:"0px"}}/>           */}
                                  {/* { res && res.luggage &&  res.luggage > 0 ?
                            <div style={{display :"flex",flexDirection : "row",justifyContent :"space-between"}}>
                            <div>
                                <div style={{display:"flex",}}>
                                <DirectionsCarIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.doors && res.doors} Doors</p>
                            </div>
                                <div style={{display:"flex",}}>
                                <MeetingRoomIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.petrol && res.petrol}</p>
                            </div>
                            </div>
                            <div>
                                <div style={{display:"flex",}}>
                                <WhatshotIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.cc && res.cc} cc</p>
                            </div>
                                <div style={{display:"flex",}}>
                                <LocalMallIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.luggage && res.luggage} Lugg</p>
                            </div> 
                            </div>
                            <div>
                                <div style={{display:"flex",}}>
                                <ReceiptIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.ac && res.ac === "yes" ? "Ac" : "No AC"}</p>
                            </div>
                            <div style={{display:"flex",}}>
                                <SupervisorAccountIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.person && res.person} </p>
                            </div>
                            </div>
                            </div>
                            :""           
                      } */}
                      {/* <hr  style={{margin:"0px"}}/>
                    <div style={{display:"flex",marginTop:"10px"}}>
                         <h3 className="product-title"   style={{maxWidth:"100%",maxHeight : "59px",overflow : "hidden",textAlign:"left"}}>Earn upto 5% Off  </h3>
                         
                        </div>  */}
                      {/* <hr  style={{margin:"0px"}}/>               */}
                                  <div className="product-action position-relative visible" style={{display:"flex",justifyContent:"space-between",marginTop:"10px"}}>
                                    {/* {res.Product_Price_Discounted ? 
                    <><span className="product-price" style={{fontSize:"14px",fontWeight:"bold",color:"black"}}>Rs {res.Product_Price_Discounted} <br /> / Day</span></>
                    :
                    <span  style={{fontSize:"14px",fontWeight:"bold",color:"black"}}>
                    Rs {(res.Product_Price)} <br />
                / Day
                    </span>            
                                        }  */}
                                    <span className="shadow-none" style={{borderRadius:"15px",maxWidth:"150px",color:"white",background :"rgb(87 1 6)",padding :"10px 15px",color  :"white",fontSize:"12px",fontWeight:"bold"}} ><a href="tel:+923332221927" style={{color  :"white"}}>Call Now</a> </span>
                                    <span className="shadow-none" style={{borderRadius:"15px",maxWidth:"150px",color:"white",background :"rgb(87 1 6)",padding :"10px 15px",color  :"white",fontSize:"12px",fontWeight:"bold"}} onClick={()=>addtocartproduct([res])}>Book Now</span>
                                </div>     
                                </div>
                              </div>
                            </div>
       )
    })
    :
                    Subproduct.map((res,i)=>{
                        return(
      <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                              <div className="product product-7 text-center" style={{height : "auto",
    border: "2px solid rgb(149, 6, 13)",
    borderRadius: "20px",
    padding: "10px 0px",
    margin: "11px"}}>
                                <figure className="product-media">
                                  <Link  to={"/"+res.Product_Name_Full}>
                                    <img src={res.Product_Image_Upload} alt="Product image" className="product-image" />
                                  </Link>
                                  <div className="product-action-vertical">
                                    <a className="btn-product-icon btn-wishlist btn-expandable"  onClick={()=>addtocartproduct8([res])}><span>add to wishlist</span></a>
                                    <Link to={"/"+res.Product_Name_Full} className="btn-product-icon btn-quickview" title="Quick view"><span>Quick view</span></Link>
                                  </div>{/* End .product-action-vertical */}
                                </figure>{/* End .product-media */}
                                <div className="product-body">
                                  <div className="product-cat">
                                  </div>{/* End .product-cat */}
                                  <h3 className="product-title"   style={{maxWidth:"100%",maxHeight : "59px",overflow : "hidden",textAlign:"left"}}><Link to={"/"+res.Product_Name_Full}>{res.Product_Name}</Link></h3>{/* End .product-title */}


                            <div style={{display :"flex",flexDirection : "row",justifyContent :"space-between"}}>
                            <div>
                                <div style={{display:"flex",margin:"0px 0px 15px 0px"}}>
                                <img src={icon1} style={{height:"23px",width:"20px",color: "#95060D",fontSize : "15",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.person && res.person } Seats</p>
                            </div>
                                <div style={{display:"flex",}}>
                                <img src={icon2} style={{height:"23px",width:"20px",color: "#95060D",fontSize : "15",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>Automatic</p>
                            </div>
                            </div>
                            <div>
                               
                                <div style={{display:"flex",flexDirection:"column"}}>
                                  <div>

                 
                  <span className="product-price" style={{fontSize:"19px",fontWeight:"bold",color:"rgb(149, 6, 13)",padding:"0px 20px"}}>                  Rs {(res.OutCity)} <br />
             / Day
                  </span>            
                                  
                                  </div>
                              <div>
                              <button class="animated-button8" style={{marginBottom:"0px"}}>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  With Driver
</button>
                              </div>

                             
                            </div>
                           
                            </div>
                            </div>
                            <div style={{display :"flex",flexDirection : "row",justifyContent :"space-between"}}>
                            <div>
                                <div style={{display:"flex",margin:"0px 0px 15px 0px"}}>
                                <img src={icon3} style={{height:"16px",width:"20px",color: "#95060D",fontSize : "15",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black",textAlign:"left"}}> ( 24hrs/day )
                                {/* <p style={{fontSize:11,fontWeight :"600",color:"black",textAlign:"left"}}>With Driver (10hrs/day) */}
                                </p>
                            </div>
                                <div style={{display:"flex",}}>
                                <ReceiptIcon style={{height:"19px",width:"20px",color: "rgb(205 112 112)",fontSize : "15",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>Without Tax</p>
                            </div>
                            </div>
                            <div>
                                <div style={{display:"flex",marginTop:"20px"}}>
                                {/* <button class="animated-button8">
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  With Driver
</button> */}
                                {/* {res.Product_Price_Discounted ? 
                  <><span className="product-price" style={{fontSize:"14px",fontWeight:"bold",color:"black"}}>Rs {res.Product_Price_Discounted} <br /> / Day</span></>
                  :
                  <span  style={{fontSize:"14px",fontWeight:"bold",color:"black"}}>
                  Rs {(res.Product_Price)} <br />
             / Day
                  </span>            
                                    } */}
                            </div>
                            </div>
                            </div>
                        







                                  {/* <hr style={{margin:"0px"}}/>           */}
                                  {/* { res && res.luggage &&  res.luggage > 0 ?
                            <div style={{display :"flex",flexDirection : "row",justifyContent :"space-between"}}>
                            <div>
                                <div style={{display:"flex",}}>
                                <DirectionsCarIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.doors && res.doors} Doors</p>
                            </div>
                                <div style={{display:"flex",}}>
                                <MeetingRoomIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.petrol && res.petrol}</p>
                            </div>
                            </div>
                            <div>
                                <div style={{display:"flex",}}>
                                <WhatshotIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.cc && res.cc} cc</p>
                            </div>
                                <div style={{display:"flex",}}>
                                <LocalMallIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.luggage && res.luggage} Lugg</p>
                            </div> 
                            </div>
                            <div>
                                <div style={{display:"flex",}}>
                                <ReceiptIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.ac && res.ac === "yes" ? "Ac" : "No AC"}</p>
                            </div>
                            <div style={{display:"flex",}}>
                                <SupervisorAccountIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.person && res.person} </p>
                            </div>
                            </div>
                            </div>
                            :""           
                      } */}
                      {/* <hr  style={{margin:"0px"}}/>
                    <div style={{display:"flex",marginTop:"10px"}}>
                         <h3 className="product-title"   style={{maxWidth:"100%",maxHeight : "59px",overflow : "hidden",textAlign:"left"}}>Earn upto 5% Off  </h3>
                         
                        </div>  */}
                      {/* <hr  style={{margin:"0px"}}/>               */}
                                  <div className="product-action position-relative visible" style={{display:"flex",justifyContent:"space-between",marginTop:"10px"}}>
                                    {/* {res.Product_Price_Discounted ? 
                    <><span className="product-price" style={{fontSize:"14px",fontWeight:"bold",color:"black"}}>Rs {res.Product_Price_Discounted} <br /> / Day</span></>
                    :
                    <span  style={{fontSize:"14px",fontWeight:"bold",color:"black"}}>
                    Rs {(res.Product_Price)} <br />
                / Day
                    </span>            
                                        }  */}
                                    <span className="shadow-none" style={{borderRadius:"15px",maxWidth:"150px",color:"white",background :"rgb(87 1 6)",padding :"10px 15px",color  :"white",fontSize:"12px",fontWeight:"bold"}} ><a href="tel:+923332221927" style={{color  :"white"}}>Call Now</a> </span>
                                    <span className="shadow-none" style={{borderRadius:"15px",maxWidth:"150px",color:"white",background :"rgb(87 1 6)",padding :"10px 15px",color  :"white",fontSize:"12px",fontWeight:"bold"}} onClick={()=>addtocartproduct([res])}>Book Now</span>
                                </div>     
                                </div>
                              </div>
                            </div>
       )
    })
}
      
<div className='container' style={{margin : "30px 10px"}}>
            <div className='row'  >
            <div className="col-10 col-lg-10  col-sm-12" style={{margin  :"auto"}}>
             
<section>

<div class="car-rental-section">
    {/* <h1>Rent a Car in Karachi, Pakistan – Convenient, Safe, and Affordable</h1> */}
    <p>Renting a car in Karachi, Pakistan has never been easier! At Pak Dream Rent A Car, we offer a seamless car rental service tailored to meet your needs. Whether you're visiting Karachi for business or leisure, our diverse fleet of vehicles and professional drivers ensure a hassle-free and comfortable experience.</p>
    <p>We take pride in offering top-notch customer service, ensuring that each journey with us is smooth, safe, and enjoyable. Our vehicles are well-maintained, clean, and ready for your next adventure, whether you're exploring the city, heading to a business meeting, or embarking on a day trip.</p>

    <h2>Rent a Car in Karachi, Pakistan with Driver – Relax and Enjoy Your Journey</h2>
    <p>Navigating Karachi’s busy streets can be challenging, especially for first-time visitors or those unfamiliar with the local traffic. With our “Rent a Car in Karachi with Driver” service, you can leave the driving to our professional chauffeurs and focus on what matters most to you – enjoying the journey.</p>
    <p>Our experienced drivers are well-versed in Karachi’s roads and traffic patterns, ensuring that you get to your destination safely and efficiently. Whether you’re looking for a reliable ride to a business conference, exploring tourist hotspots, or attending a social event, you can count on us to get you there with ease.</p>

    <h3>Key Benefits of Renting a Car with a Driver:</h3>
    <ul>
        <li>Avoid the stress of driving in heavy traffic.</li>
        <li>Enjoy a smooth and relaxing ride while our driver handles the route.</li>
        <li>Get expert recommendations from local drivers who know the best spots in the city.</li>
        <li>Travel in comfort and style with our fleet of top-quality vehicles.</li>
    </ul>

    <h2>Vehicles Available for Rent in Karachi, Pakistan with Driver</h2>
    <p>At Pak Dream Rent A Car, we offer a wide selection of vehicles to suit your travel needs and preferences. From compact cars perfect for navigating the city to luxury sedans for business meetings or special events, we have the ideal car for every occasion.</p>

    <h3>Our Fleet Includes:</h3>
    <ul>
        <li><strong>Luxury Cars:</strong> For those who want to travel in style, our Mercedes-Benz, Audi, and BMW options are perfect for corporate events, VIP travel, and special occasions.</li>
        <li><strong>Sedans:</strong> Our Honda Civic, Toyota Corolla, and Honda City sedans offer a comfortable, economical, and reliable option for daily travel, sightseeing, and business trips.</li>
        <li><strong>SUVs:</strong> Choose from our Toyota Land Cruiser, Prado, or Fortuner for a more spacious and rugged option, perfect for both urban and off-road adventures.</li>
        <li><strong>Vans and Coasters:</strong> Whether you're traveling with a group or need extra luggage space, our 12-seater Toyota Hiace or 22-seater Coaster rentals are ideal for family trips, group outings, and corporate events.</li>
    </ul>

    <h2>Why Choose Pak Dream Rent A Car for Your Car Rental Needs in Karachi?</h2>
    <ul>
        <li><strong>Convenient Booking:</strong> Our simple online booking system and mobile app allow you to book your rental car with driver from anywhere, at any time. Just select your vehicle, pickup location, and time – and we’ll handle the rest.</li>
        <li><strong>Well-Maintained Vehicles:</strong> We pride ourselves on maintaining a modern fleet of cars that are regularly serviced and thoroughly cleaned. Travel with confidence knowing you're in a safe, reliable vehicle.</li>
        <li><strong>Experienced Drivers:</strong> Our drivers are not only professional and courteous but also knowledgeable about the city. Need suggestions for the best restaurants, shopping spots, or tourist attractions? Ask away!</li>
        <li><strong>Affordable Prices:</strong> We offer competitive rates with no hidden charges. Our pricing structure is clear and transparent, ensuring that you know exactly what you're paying for.</li>
    </ul>

    <h2>How to Rent a Car in Karachi, Pakistan with Driver</h2>
    <p>Booking your car rental with driver is easy with Pak Dream Rent A Car. Follow these simple steps:</p>
    <ol>
        <li><strong>Browse Our Fleet:</strong> Explore our wide range of vehicles available for rent in Karachi.</li>
        <li><strong>Choose Your Dates:</strong> Select the rental duration and pickup time.</li>
        <li><strong>Book Online or Call Us:</strong> Use our user-friendly website or mobile app to book your car. Alternatively, you can call us at 03332221927 or email info@pakdreamrentacar.com for assistance.</li>
        <li><strong>Enjoy Your Ride:</strong> Our professional driver will be at your doorstep at the agreed time, ready to take you on your journey.</li>
    </ol>
</div>
</section>
    </div>
            </div>
            </div>
         

                      
                    </div>{/* End .row */}
                  </div>{/* End .products */}
                  <nav aria-label="Page navigation">
                    <ul className="pagination justify-content-center">
                      <li className="page-item disabled">
                        <a className="page-link page-link-prev" href="#" aria-label="Previous" tabIndex={-1} aria-disabled="true">
                          <span aria-hidden="true"><i className="icon-long-arrow-left" /></span>Prev
                        </a>
                      </li>
                      <li className="page-item active" aria-current="page"><a className="page-link" href="#">1</a></li>
                      {/* <li className="page-item"><a className="page-link" href="#">2</a></li>
                      <li className="page-item"><a className="page-link" href="#">3</a></li>
                      <li className="page-item-total">of 6</li> */}
                      <li className="page-item">
                        <a className="page-link page-link-next" href="#" aria-label="Next">
                          Next <span aria-hidden="true"><i className="icon-long-arrow-right" /></span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>{/* End .col-lg-9 */}
                
              </div>{/* End .row */}
            </div>{/* End .container */}
          </div>{/* End .page-content */}
        </main>{/* End .main */}




</>
        )
    }



export default SubCategoriesProduct;