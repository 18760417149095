import React, { useState , useEffect } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import ch6 from '../asset1/images/contact-header-bg.jpg';
import ch1 from '../assets/images/pd1-724x1024.jpg';
import ch2 from '../assets/images/pd2-724x1024.jpg';
import ch3 from '../assets/images/pd3-724x1024.jpg';
import ch4 from '../assets/images/pd4-724x1024.jpg';
import ch5 from '../assets/images/pd5-724x1024.jpg';







const Contact = (props)=>  {
    const [name, setname] = useState("") 
    const [lname, setlname] = useState("") 
    const [email, setemail] = useState("") 
    const [subject, setsubject] = useState("") 
    const [Message, setMessage] = useState("") 
    const [phone, setphone] = useState("") 
    const [drop, setdrop] = useState("") 
    const [car, setcar] = useState("") 
    const [day1, setday1] = useState("")
    const [Product_Image_Upload , setProduct_Image_Upload] = useState("")
 
    useEffect(() =>{
        window.scrollTo(0, 0)

    },[])

    const Submitdata = (e)=>{
        e.preventDefault()

        const data = new FormData()
        data.append("file", Product_Image_Upload)
        data.append("upload_preset", "commerce")
        data.append("cloud_name", "freedeveloperss123")
        fetch("https://api.cloudinary.com/v1_1/freedeveloperss123/image/upload",{ 
            method : "POST",
            body : data , 
        })
        .then(res=>res.json())
        .then((res2)=>{
          console.log(res2)
          // this.setState({ url : res2.url})
          // setUrl(res2.url)
          if(res2.url !== ""){
                              console.log(res2)
        fetch("https://pak-dream-back.vercel.app/Corporate-Enquiries",{
            method: 'POST' , 
            headers :  {
              "Content-Type" : "application/json" , 
            } , 
            body : JSON.stringify({
              name  ,
              lname  ,
              email  ,
              phone  ,
              car  ,
              Enquiry : drop,
              day1  ,
              setProduct_Image_Upload : res2.url  ,
              Message  ,
            })
          })
          .then((res)=>res.json())
          .then((res2)  =>{
              console.log(res2)
              if (res2){
                swal("Data Send ! Wait For Our Team Contact You "  )
                setname ("") 
                setlname("") 
                    setemail("") 
                    setsubject("") 
                    setMessage("") 
                // localStorage.setItem("User" , JSON.stringify("Yes") )
                    props.history.push("/")
              }
              else if (res2.Error){
                swal(res2.Error )
              }
              else{
                swal("Plz Try Again !"  )
              }
            // console.log(res2)
          })
          .catch((err)=>console.log(err))
        }
        else{
            swal("Upload Again")                  
        }
        })
        
    }



        return (
            <> 

            <div>
                 <Helmet>
                <link rel="canonical" href="https://pakdreamrentacar.com" />
            </Helmet>
          <main className="main">
        <nav aria-label="breadcrumb" className="breadcrumb-nav border-0 mb-0">
          <div className="container">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="">Home</a></li>
              {/* <li className="breadcrumb-item"><a href="#">Pages</a></li> */}
              <li className="breadcrumb-item active" aria-current="page">Company & Corporate Car Rental Service
</li>
            </ol>
          </div>{/* End .container */}
        </nav>{/* End .breadcrumb-nav */}
        <div className="container">
          <div className="page-header page-header-big text-center" style={{backgroundImage:  'url('+ch6+')'}}>
            <h1 className="page-title text-white">Company & Corporate Enquiries
<span className="text-white">Please fill out the form below and our representatives will be in touch with you shortly.

</span></h1>
          </div>{/* End .page-header */}
        </div>{/* End .container */}
        <div className='container' style={{margin : "30px 10px"}}>
            <div className='row'  >
            <div className="col-10 col-lg-10  col-sm-12" style={{margin  :"auto"}}>
    <h2>Rent a Car Services for Corporate and B2B Clients</h2>
    <p>
        At <strong>Pak Dream Rent A Car</strong>, we specialize in providing exceptional car rental services tailored to meet 
        the diverse needs of both individual and corporate clients. Whether you're a business professional in need of reliable 
        transportation for meetings, a company looking for fleet solutions, or a corporate event manager requiring luxury 
        vehicles for special occasions, we have the right vehicle for every need.
    </p>

    <h2>Why Choose Us for Corporate and B2B Rentals?</h2>

    <h3>1. Dedicated Corporate Solutions</h3>
    <p>
        We understand the importance of punctuality, comfort, and professionalism in business. That's why we offer custom car 
        rental solutions for companies, including flexible rental durations, corporate discounts, and priority service. Our 
        fleet includes executive sedans, SUVs, and luxury vehicles designed to impress your clients or cater to your business 
        travel needs.
    </p>

    <h3>2. Fleet Management for Businesses</h3>
    <p>
        For companies requiring multiple vehicles for staff, guests, or events, we provide complete fleet management services. 
        Our corporate packages allow businesses to rent vehicles on a daily, weekly, or monthly basis, with options to change 
        or upgrade the fleet as needed. We handle everything from vehicle maintenance to insurance, giving you peace of mind.
    </p>

    <h3>3. B2B Partnerships</h3>
    <p>
        We partner with businesses across various industries, offering tailored rental solutions to meet their specific 
        requirements. Whether you are in the hospitality industry, event management, or logistics, we provide reliable 
        transportation for your team, clients, or guests. Our competitive pricing and top-tier service make us the preferred 
        partner for businesses throughout the region.
    </p>

    <h3>4. Flexible Booking & Payments</h3>
    <p>
        Our hassle-free online booking system ensures that businesses can quickly reserve the vehicles they need without 
        delays. We offer flexible payment plans, including invoicing options for long-term business clients, allowing you to 
        manage your budget efficiently.
    </p>

    <h3>5. Wide Range of Vehicles</h3>
    <p>
        Our diverse fleet includes economy cars for everyday use, luxury vehicles for VIPs, and SUVs for group transportation. 
        Whether you need vehicles for corporate tours, business events, or daily staff commuting, we have the perfect match.
    </p>

    <h2>Key Benefits for Corporate Clients:</h2>
    <ul>
        <li>Priority booking and exclusive deals</li>
        <li>Professional drivers available for added convenience</li>
        <li>Custom rental agreements based on company needs</li>
        <li>24/7 customer support and roadside assistance</li>
    </ul>

    <p>
        <strong>Pak Dream Rent A Car</strong> is committed to offering the best car rental experience for businesses, providing 
        you with reliable, stylish, and efficient vehicles that enhance your corporate image and ensure smooth operations.
    </p>
</div>
</div>
</div>



        <div className='container' style={{}}>
          
          <div class="row justify-content-center">
              <div class="col-lg-4 col-sm-6">
                    <img src={ch1} alt="" />
              </div>
              <div class="col-lg-4 col-sm-6">
                    <img src={ch2} alt="" />
              </div>
              <div class="col-lg-4 col-sm-6">
                    <img src={ch3} alt="" />
              </div>
              <div class="col-lg-4 col-sm-6">
                    <img src={ch4} alt="" />
              </div>
              <div class="col-lg-4 col-sm-6">
                    <img src={ch5} alt="" />
              </div>

            
              
          </div>
</div>

<br />


















        <div className="page-content pb-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mb-2 mb-lg-0">
                <h2 className="title mb-1">Contact Information</h2>{/* End .title mb-2 */}
                <p className="mb-3">Welcome to Pak Dream Transport.  Pakistan's Best Car Rental Service.</p>
                <div className="row">
                  <div className="col-sm-7">
                    <div className="contact-info">
                      <h3>Location</h3>
                      <ul className="contact-list">
                        <li>
                          <i className="icon-map-marker" />
                          Pak Dream Rent a Car, Plot # 01 Showroom number3, 7 block, opposite Sindh Green restaurant, behind Saima Presidency, Gulistan-e-Johar, Karachi, 75400                        </li>
                        <li>
                          <i className="icon-phone" />
                          Yasir Tanoli <a href="tel:+92 333 2221927">+92 333 2221927</a>
                        </li>
                       
                        <li>
                          <i className="icon-envelope" />
                          <a href="mailto:info@pakdreamrentacar.com"><span className="__cf_email__" data-cfemail="">info@pakdreamrentacar.com</span></a>
                        </li>
                      </ul>{/* End .contact-list */}
                    </div>{/* End .contact-info */}
                  </div>{/* End .col-sm-7 */}
                  <div className="col-sm-5">
                    <div className="contact-info">
                      <h3>The Office</h3>
                      <ul className="contact-list">
                        <li>
                          <i className="icon-clock-o" />
                          <span className="text-dark">Mon-Sat</span> <br />09:00 PM - 11:00 PM
                        </li>
                        <li>
                          <i className="icon-clock-o" />
                          <span className="text-dark">Customer support timing </span> <br />24/7
                        </li>
                        
                      </ul>{/* End .contact-list */}
                    </div>{/* End .contact-info */}
                  </div>{/* End .col-sm-5 */}
                </div>{/* End .row */}
              </div>{/* End .col-lg-6 */}
              <div className="col-lg-6">
                <h2 className="title mb-1">Drop Your Query
</h2>{/* End .title mb-2 */}
                <p className="mb-2">Use the form below to get in touch with the sales team</p>
                <form  onSubmit={(e)=>Submitdata(e)}>
                  <div className="row"> 
                    <div className="col-sm-6">
                      <label htmlFor="cname" >First Name</label>
                      <input type="text" required className="form-control" id="cname" placeholder="Name *"  value={name} onChange={(e)=>setname(e.target.value)}/>
                    </div>{/* End .col-sm-6 */}
                    <div className="col-sm-6">
                      <label htmlFor="cname" >Last Name</label>
                      <input type="text" required className="form-control" id="cname" placeholder="Name *"  value={lname} onChange={(e)=>setlname(e.target.value)}/>
                    </div>{/* End .col-sm-6 */}
                  </div>{/* End .row */}
                  <div className="row">
                    <div className="col-sm-6">
                      <label htmlFor="cphone" >Phone</label>
                      <input type="tel"  required className="form-control" id="cphone" placeholder="Phone"  value={phone} onChange={(e)=>setphone(e.target.value)}/>
                    </div>{/* End .col-sm-6 */}
                    <div className="col-sm-6">
                      <label htmlFor="cemail" >Email</label>
                      <input type="email" required className="form-control" id="cemail" placeholder="Email *" value={email} onChange={(e)=>setemail(e.target.value)}  />
                    </div>{/* End .col-sm-6 */}
                </div>{/* End .col-sm-6 */}
                  <div className="row">
                    <div className="col-sm-6">
                      <label htmlFor="cphone" >No of Cars *:</label>
                      <input type="number" required className="form-control" id="cphone" placeholder="No of Cars"  value={car} onChange={(e)=>setcar(e.target.value)}/>
                    </div>{/* End .col-sm-6 */}
                    <div className="col-sm-6">
                      <label htmlFor="cemail" >No of Days *:
</label>
                      <input type="number" className="form-control" id="cemail" placeholder="No of Days *: *" value={day1} onChange={(e)=>setday1(e.target.value)}  />
                    </div>{/* End .col-sm-6 */}
                </div>{/* End .col-sm-6 */}
                  <div className="row">
                    <div className="col-sm-6">
                      <label htmlFor="cphone" >Upload Cnic</label>
                      <input type="file"  required className="form-control" id="cphone" placeholder="No of Cars"   onChange={(e)=>setProduct_Image_Upload(e.target.files[0])} />
                    </div>{/* End .col-sm-6 */}
                    <div className="col-sm-6">
                      <label htmlFor="cemail" >Purpose of Enquir requiredy *:

</label>
                    <select class="form-control"  required="required" aria-required="true"  value={drop} onChange={(e)=>setdrop(e.target.value)} >
                      <option value="Corporate Sale">Corporate Sale</option>
                      <option value="General Enquiries">General Enquiries</option>
                    </select>

                    </div>{/* End .col-sm-6 */}
                </div>{/* End .col-sm-6 */}
                <div className="col-sm-6">

                  <label htmlFor="cmessage" >Message</label>
                  <textarea className=" requiredform-control" cols={30} rows={4} id="cmessage" required placeholder="Message *" defaultValue={""}  value={Message}  onChange={(e)=>setMessage(e.target.value)} />
                  </div>
                  <button type="submit" className="btn btn-outline-primary-2 btn-minwidth-sm">
                    <span>SUBMIT</span>
                    <i className="icon-long-arrow-right" />
                  </button>
                </form>{/* End .contact-form */}
              </div>{/* End .col-lg-6 */}
            </div>{/* End .row */}
            <hr className="mt-4 mb-5" />
            
          </div>{/* End .container */}
          {/* <div id="map" /> */}
          {/* End #map */}
        </div>{/* End .page-content */}
      </main>{/* End .main */}





              
            </div>
            </>
           
        )
    
}


export default Contact;