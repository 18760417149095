import React, { useState , useEffect } from 'react';
import {Link ,useHistory} from "react-router-dom"


// import FacebookIcon from '@mui/icons-material/Facebook';
  const Blogs =(props)=> {
    const [AllData , setAllData] = useState([])
    const [AllData1 , setAllData1] = useState([])
    useEffect(() =>{
        let Categoriesname = props.match.params.rentacarDetail ;

    fetch("https://pak-dream-admin-backs.vercel.app/AllTCreatBLog",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res3=>res3.json())
        .then(res4=>{
            
            res4.map((res1,y)=>{
                if(res1.main.toLowerCase().trim().split(/[\s,\t,\n]+/).join('-') === Categoriesname){
                    setAllData1([res1])
                }
                // to={"/"+res.Product_Name_Full}     
            })
           
            if(AllData1[0] && !AllData1[0].main){
                props.history.push("/Blogs")
            }

        })
      },[])

  //  history = useHistory();
  
        return (
            <>
    

<div class="container">
<div class="row">
  {/* <div class="leftcolumn"> */}
    {AllData1.map((res,i)=>{
        return(
            <div class="col-12 col-lg-12 col-sm-12 card1">
            <h2>{res.main}</h2>
            {/* <h5>Title description, Dec 7, 2017</h5> */}
            <div class="fakeimg">
                <img src={res.Image} alt="" style={{maxHeight:"400px"}}/>
            </div>
            {/* <p>Some text..</p> */}
                    <p style={{maxWidth : "100%",overflow:"scroll"}}> {res.text &&res.text ?<div dangerouslySetInnerHTML={{__html: res.text}} />:""}</p> 
                  

            </div>

        )
    })

    }
  
  </div>

</div>


            </>
        )
    
}

export default Blogs;