import React, { useState , useEffect } from 'react';
import { Link } from 'react-router-dom';
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import swal from 'sweetalert';

import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ReceiptIcon from '@material-ui/icons/Receipt';
import LocalMallIcon from '@material-ui/icons/LocalMall';



import pay from '../assets/images/pay.png';



import icon1 from '../assets/images/icon1 1.png';
import icon2 from '../assets/images/icon2 1.png';
import icon3 from '../assets/images/icon3 1.png';
import icon4 from '../assets/images/icon4 1.png';import {Helmet} from "react-helmet";








const SubCategoriesProduct = (props)=> {
    const [CategoriesUrl , setCategoriesUrl] = useState("")
    const [Cities , setCities] = useState(true)
    const [Product , setProduct] = useState([])
    const [Product1 , setProduct1] = useState([])
    const [Product2 , setProduct2] = useState([])
    const [Categories , setCategories] = useState([])
    const [fal , setFal] = useState(false)
    const [fal1 , setFal1] = useState(false)
    const [CategoriesName , setCategoriesname] = useState({})
    const [SubCategories , setSubCategories] = useState([])
    const [SubClassCategories , setSubClassCategories] = useState([])
    const [subCate , setsubCate] = useState([])
    const [allproduct , setallproduct] = useState([])
    const [Subproduct , setSubproduct] = useState([])
    const [Subproduct1 , setSubproduct1] = useState([])
    const [SortedData , setSortedData] = useState([])
    const [SortedName , setSortedName] = useState([])
    const [SliderPhoto , setSliderPhoto] = useState([])
    const [DataPart2 , setDataPart2] = useState([])
    const [MainCatogories , setMainCatogories] = useState([])
    const [dispaluy53 , setdispaluy53] = useState(true)
    const [Cate1 , setCate1] = useState([])
    const [Cate2 , setCate2] = useState([])

    // categoriesName subcategories

    useEffect(() =>{

        window.scrollTo(0, 0)
        // console.log( props.match.params);
        let Categoriesname = props.match.params ;
        setCategoriesname(Categoriesname)

        // const Inter = setInterval(()=>{
            //  if (!JSON.parse(localStorage.getItem("CateProduct")) ){
            //  var data1 = JSON.parse(localStorage.getItem("Cate")) 

        // var Category =  props.match.url.split("/")
        // setCategoriesUrl(data1)
        // var Category1 =  CategoriesUrl.split("-").join(" ")
        fetch("https://pak-dream-back.vercel.app/AllProduct",{
                    method: "GET",
                    headers :  {
                    "Content-Type" : "application/json" , 
                } ,
                })
                .then(res7=>res7.json())
                .then(res8=>{
                    setallproduct(res8)
                    const NonActive = res8.filter((res9,i)=>{
                        // console.log(res9.status , res9.Product_Catagories , "Cars"  , res9.Product_Sub_Catagories , Categoriesname.subCate );
                        if(res9.status && res9.Product_Catagories === "Cars" && res9.vehicles === "Coasters" ){
                            return res9 
                        }
                    })

                    setSubproduct(NonActive)
                    console.log(res8)
                })

        
       

                // fetch("https://pak-dream-back.vercel.app/AllProduct",{
                //     method: "GET",
                //     headers :  {
                //     "Content-Type" : "application/json" , 
                // } ,
                // })
                // .then(res7=>res7.json())
                // .then(res8=>{
                //     setallproduct(res8)
                //     const NonActive = res8.filter((res9,i)=>{
                //         console.log(res9.status , res9.Product_Catagories , "Cars"  , res9.Product_Sub_Catagories , Categoriesname.subCate );
                //         if(res9.status && res9.Product_Catagories === "Cars"  ){
                //             return res9 
                //         }
                //     })

                //     setSubproduct(NonActive)
                //     console.log(NonActive)
                // })

        

      



},[])


    const setSortPrice1 = (e)=>{
      setdispaluy53(!dispaluy53)
      if(Subproduct.length !== 0){

        SortedName.push(e)
        console.log(e,Subproduct);
        if (e === "Low"){
            const Pro6 = Subproduct.sort(function (a, b) {
                    return parseFloat(a.Product_Price) - parseFloat(b.Product_Price)  
                  });
                  console.log(Pro6);
                  let reversed_array = [];
                  Pro6.forEach((element) => {
                    reversed_array.unshift(element);
                });
                  console.log(Pro6,reversed_array)
                  setSubproduct(reversed_array)
                  setSubproduct(Pro6)
                  setdispaluy53(!dispaluy53)
                  setdispaluy53(!dispaluy53)
    
        }
        if (e === "High"){
            const Pro6 = Subproduct.sort((a, b)=> {
                    return parseFloat(b.Product_Price) - parseFloat(a.Product_Price)  
                    
                });
              //   let reversed_array = [];
              //   Pro6.forEach((element) => {
              //     reversed_array.unshift(element);
              // });
              //   console.log(Pro6,reversed_array)
                setSubproduct(Pro6)
                setdispaluy53(!dispaluy53)
                setdispaluy53(!dispaluy53)

        }
        if (e === "A"){
            const Pro6 = Subproduct.sort((a, b)=> {
                var nameA = a.Product_Name.toUpperCase(); 
                var nameB = b.Product_Name.toUpperCase(); 
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
    
                return 0;
                });
                console.log(Pro6);
                setSubproduct(Pro6)
                
              }
              
              setSubproduct1([])
              setdispaluy53(!dispaluy53)

            }
            else{
        
                SortedName.push(e)
                console.log(e);
                if (e === "Low"){
                    const Pro6 = Subproduct.sort((a, b)=> {
                            return parseFloat(b.Product_Price) - parseFloat(a.Product_Price)  
                            
                        });
                        setSubproduct1(Pro6)
            
                }
                if (e === "High"){
                    const Pro6 = Subproduct.sort((a, b)=> {
                            return parseFloat(b.Product_Price) - parseFloat(a.Product_Price)  
                            
                        });
                        setSubproduct1(Pro6.reverse())
            
                }
                if (e === "A"){
                    const Pro6 = Subproduct.sort((a, b)=> {
                        var nameA = a.Product_Name.toUpperCase(); 
                        var nameB = b.Product_Name.toUpperCase(); 
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
            
                        return 0;
                        });
                        console.log(Pro6);
                        setSubproduct1(Pro6)
                        
                      }
                      
                      
                      setSubproduct([])
                    }
                    setdispaluy53(!dispaluy53)

                  }
  

  
const addtocartproduct = (productDetail) =>{
  swal("Update!", "Your Item Add in Cart!", "success");

      
  // document.getElementById("myDi").style.visibility = "visible"
  // setTimeout(()=>{
  // document.getElementById("myDi").style.visibility = "hidden"

  // },1000)
  console.log(productDetail)
  if (productDetail[0].Product_Price_Discounted === null){
  const data =  {...productDetail[0] ,
      Percentage : 0,
      DeliveryStatus : "Pending",
      Pieces : 1 ,
      Total_Product_Price  : productDetail[0].Product_Price * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id ){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
  return {...item,
      Pieces : 1 + item.Pieces,
      Total_Product_Price  : (productDetail[0].Product_Price * 1 )+ item.Total_Product_Price}
  }
  else{
  console.log("double not match");
  return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }

  }
  else if (productDetail[0].Product_Price_Discounted){
  const data =  {...productDetail[0] ,
      Percentage : 0,
      DeliveryStatus : "Pending",
      Pieces : 1 ,
      Total_Product_Price  : productDetail[0].Product_Price_Discounted * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id ){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
  return {...item,
      Pieces : 1 + item.Pieces,
      Total_Product_Price  : (productDetail[0].Product_Price_Discounted * 1 )+ item.Total_Product_Price}
  }
  else{
  console.log("double not match");
  return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }

  }
  else if (productDetail[0].Size_Discounted[0]){
      
          const data =  {...productDetail[0] ,
              Percentage : 0,
              DeliveryStatus : "Pending",
              Pieces : 1 ,
              Size : productDetail[0].Size[0],
              Product_Price_Discounted : productDetail[0].Size_Discounted[0],
              Total_Product_Price  : productDetail[0].Size_Discounted[0] * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
      return {...item,
          Pieces : 1 + item.Pieces,
          Total_Product_Price  : (productDetail[0].Size_Discounted[0] * 1 )+ item.Total_Product_Price}
  }
  else{
      console.log("double not match");
      return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }

  }
  else{




          const data =  {...productDetail[0] ,
              Percentage : 0,
              DeliveryStatus : "Pending",
              Pieces : 1 ,
              Size : productDetail[0].Size[0],
              Product_Price_Discounted : productDetail[0].Price[0],
              Total_Product_Price  : productDetail[0].Price[0] * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
      return {...item,
          Pieces : 1 + item.Pieces,
          Total_Product_Price  : (productDetail[0].Price[0] * 1 )+ item.Total_Product_Price}
  }
  else{
      console.log("double not match");
      return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }


  }


      

}


const addtocartproduct8 = (productDetail) =>{
    swal("Update!", "Item Save!", "success");
  
        
    // document.getElementById("myDi").style.visibility = "visible"
    // setTimeout(()=>{
    // document.getElementById("myDi").style.visibility = "hidden"
  
    // },1000)
    console.log(productDetail)
    if (productDetail[0].Product_Price_Discounted === null){
    const data =  {...productDetail[0] ,
        Percentage : 0,
        DeliveryStatus : "Pending",
        Pieces : 1 ,
        Total_Product_Price  : productDetail[0].Product_Price * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id ){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
    return {...item,
        Pieces : 1 + item.Pieces,
        Total_Product_Price  : (productDetail[0].Product_Price * 1 )+ item.Total_Product_Price}
    }
    else{
    console.log("double not match");
    return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
    }
    else if (productDetail[0].Product_Price_Discounted){
    const data =  {...productDetail[0] ,
        Percentage : 0,
        DeliveryStatus : "Pending",
        Pieces : 1 ,
        Total_Product_Price  : productDetail[0].Product_Price_Discounted * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id ){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
    return {...item,
        Pieces : 1 + item.Pieces,
        Total_Product_Price  : (productDetail[0].Product_Price_Discounted * 1 )+ item.Total_Product_Price}
    }
    else{
    console.log("double not match");
    return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
    }
    else if (productDetail[0].Size_Discounted[0]){
        
            const data =  {...productDetail[0] ,
                Percentage : 0,
                DeliveryStatus : "Pending",
                Pieces : 1 ,
                Size : productDetail[0].Size[0],
                Product_Price_Discounted : productDetail[0].Size_Discounted[0],
                Total_Product_Price  : productDetail[0].Size_Discounted[0] * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
        return {...item,
            Pieces : 1 + item.Pieces,
            Total_Product_Price  : (productDetail[0].Size_Discounted[0] * 1 )+ item.Total_Product_Price}
    }
    else{
        console.log("double not match");
        return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
    }
    else{
  
  
  
  
            const data =  {...productDetail[0] ,
                Percentage : 0,
                DeliveryStatus : "Pending",
                Pieces : 1 ,
                Size : productDetail[0].Size[0],
                Product_Price_Discounted : productDetail[0].Price[0],
                Total_Product_Price  : productDetail[0].Price[0] * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
        return {...item,
            Pieces : 1 + item.Pieces,
            Total_Product_Price  : (productDetail[0].Price[0] * 1 )+ item.Total_Product_Price}
    }
    else{
        console.log("double not match");
        return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
  
    }
  
  
        
  
  }
  
        return (
       




<>
           <Helmet>
                <link rel="canonical" href="https://pakdreamrentacar.com/coasters-cars" />
            </Helmet>
          <main className="main">
          <div className="text-center">
            <div className="container">
              <h1 className="page-title" style={{fontSize:"27px",fontWeight:"bold", margin:"0px 10px",padding:0,textTransform:"uppercase"}}> Vans and Coasters on Rent
              <span></span></h1>
              <p>
              Planning a trip with family, friends, or colleagues? Look no further than Pak Dream Rent A Car for reliable and spacious van and coaster rentals.
              </p>
            </div>{/* End .container */}
          </div>{/* End .page-header */}
<hr  style={{margin:"0px"}}/>
          <div className="page-content">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="toolbox">
                    <div className="toolbox-left" style={{width:"100%"}}>
                      <div className="toolbox-info" style={{width:"100%"}}>
                     <div className='dskjffkj' style={{width:"100%",display:"flex",color : "black"}}>
                       <div className='sfsdf' style={{display:"flex",flexDirection :"column",width:"140px",marginRight : "100px"}}>
                         <span style={{color : "black",fontSize:"17px"}}>Price </span>
                         <span style={{marginRight : "20px",width:"140px",color : "black",fontSize:"15px"}} > Rs : 4500 to 40000 </span>
                         <div style={{display:"flex"}}>

                         <input className='gklfgkl' type="range" name="" id="" value={0} min="0" max="9500" style={{color:"blue",color : "black",fontSize:"17px",padding:"0px",width:"60px"}}/>
                         <input className='gklfgkl' type="range" name="" id="" value={19500} min="9500" max="40000" style={{color:"blue",color : "black",fontSize:"17px",padding:"0px",width:"60px"}}/>
                         </div>
                        </div>
                        <div>
                          <span style={{color : "black",fontSize:"18px"}}>{Subproduct.length} <b>Cars</b>  founds</span>
                        </div>
                        </div> 
                        
                      </div>{/* End .toolbox-info */}
                    </div>{/* End .toolbox-left */}
                    <div className="toolbox-right" style={{width:"100%",justifyContent:"right",alignItems:"flex-end"}}>
                      <div className="toolbox-sort">
                        <label htmlFor="sortby">Sort by:</label>
                        <div className="select-custom">
                          <select name="sortby" id="sortby" className="form-control">
                            <option value="popularity" selected="selected">Most Popular</option>
                            <option value="Low" onClick={()=>setSortPrice1("Low")}>Low → High</option>
                          <option value="High" onClick={()=>setSortPrice1("High")}>High → Low</option>
                          <option value="A" onClick={()=>setSortPrice1("A")}>A → Z</option>
                            <option value="rating">Most Rated</option>
                            <option value="date">Date</option>
                          </select>
                        </div>
                      </div>{/* End .toolbox-sort */}
                      
                    </div>{/* End .toolbox-right */}
                  </div>{/* End .toolbox */}
                  <div style={{display:"flex",justifyContent:"space-evenly",color:"black",fontSize:"19px",fontWeight:"500"}}>

{/* <input className='gklfgkl' type="radio" name="" id="" value={0} min="0" max="9500" style={{color:"blue",color : "black",fontSize:"17px",padding:"0px",width:"60px"}}/>
<input className='gklfgkl' type="radio" name="" id="" value={19500} min="9500" max="20000" style={{color:"blue",color : "black",fontSize:"17px",padding:"0px",width:"60px"}}/> */}
<span>
 <input className='gklfgkl' type="radio" value={Cities} name="Cities1"  onClick={()=>setCities(true)}  style={{accentColor:"firebrick", backgroundColor:"#EFE5CE",border: "10px solid #EFE5CE",marginRight:"9px"}}/> In City
</span>
<span>
 <input className='gklfgkl' type="radio" value={Cities} name="Cities1"  onClick={()=>setCities(false)}   style={{accentColor:"firebrick", backgroundColor:"#EFE5CE",border: "10px solid #EFE5CE",marginRight:"9px"}}/> Out City

</span>
<span>
 <input className='gklfgkl' type="radio" value={Cities} name="Cities1"  onClick={()=>setCities(true)}   style={{accentColor:"firebrick", backgroundColor:"#EFE5CE",border: "10px solid #EFE5CE",marginRight:"9px"}}/> Short Rental

</span>

</div>
                  <div className="products mb-3">
                    <div className="row justify-content-center">
                    {Cities ? 
                    Subproduct.map((res,i)=>{
                        return(
      <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                              <div className="product product-7 text-center" style={{height : "auto",
    border: "2px solid rgb(149, 6, 13)",
    borderRadius: "20px",
    padding: "10px 0px",
    margin: "11px"}}>
                                <figure className="product-media">
                                  <Link  to={"/"+res.Product_Name_Full}>
                                    <img src={res.Product_Image_Upload} alt="Product image" className="product-image" />
                                  </Link>
                                  <div className="product-action-vertical">
                                    <a className="btn-product-icon btn-wishlist btn-expandable"  onClick={()=>addtocartproduct8([res])}><span>add to wishlist</span></a>
                                    <Link to={"/"+res.Product_Name_Full} className="btn-product-icon btn-quickview" title="Quick view"><span>Quick view</span></Link>
                                  </div>{/* End .product-action-vertical */}
                                </figure>{/* End .product-media */}
                                <div className="product-body">
                                  <div className="product-cat">
                                  </div>{/* End .product-cat */}
                                  <h3 className="product-title"   style={{maxWidth:"100%",maxHeight : "59px",overflow : "hidden",textAlign:"left"}}><Link to={"/"+res.Product_Name_Full}>{res.Product_Name}</Link></h3>{/* End .product-title */}


                            <div style={{display :"flex",flexDirection : "row",justifyContent :"space-between"}}>
                            <div>
                                <div style={{display:"flex",margin:"0px 0px 15px 0px"}}>
                                <img src={icon1} style={{height:"23px",width:"20px",color: "#95060D",fontSize : "15",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.person && res.person } Seats</p>
                            </div>
                                <div style={{display:"flex",}}>
                                <img src={icon2} style={{height:"23px",width:"20px",color: "#95060D",fontSize : "15",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>Automatic</p>
                            </div>
                            </div>
                            <div>
                               
                                <div style={{display:"flex",flexDirection:"column"}}>
                                  <div>

                                {res.Product_Price_Discounted ? 
                  <div style={{display:"flex"}}>
                  <del><span className="product-price" style={{fontSize:"13px",fontWeight:"bold",color:"black"}}>Rs {res.Product_Price} <br /></span></del>
                    <span className="product-price" style={{fontSize:"19px",fontWeight:"bold",color:"rgb(149, 6, 13)",padding:"0px 20px"}}>Rs {res.Product_Price_Discounted} <br /> / Day</span> 
                  </div>
                  :
                  <span className="product-price" style={{fontSize:"19px",fontWeight:"bold",color:"rgb(149, 6, 13)",padding:"0px 20px"}}>                  Rs {(res.Product_Price)} <br />
             / Day
                  </span>            
                                    }
                                  </div>
                              <div>
                              <button class="animated-button8" style={{marginBottom:"0px"}}>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  With Driver
</button>
                              </div>

                             
                            </div>
                           
                            </div>
                            </div>
                            <div style={{display :"flex",flexDirection : "row",justifyContent :"space-between"}}>
                            <div>
                                <div style={{display:"flex",margin:"0px 0px 15px 0px"}}>
                                <img src={icon3} style={{height:"16px",width:"20px",color: "#95060D",fontSize : "15",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black",textAlign:"left"}}> ( 10hrs/day )
                                {/* <p style={{fontSize:11,fontWeight :"600",color:"black",textAlign:"left"}}>With Driver (10hrs/day) */}
                                </p>
                            </div>
                                <div style={{display:"flex",}}>
                                <img src={icon4} style={{height:"19px",width:"20px",color: "#95060D",fontSize : "15",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>Overtime: PKR {res.Overtime}/hr</p>
                            </div>
                            </div>
                            <div>
                                <div style={{display:"flex",marginTop:"40px"}}>
                                <div style={{display:"flex",marginRight: "28px"}}>
                                <ReceiptIcon style={{height:"19px",width:"20px",color: "rgb(205 112 112)",fontSize : "18",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>Without Tax</p>
                            </div>
                                {/* <button class="animated-button8">
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  With Driver
</button> */}
                                {/* {res.Product_Price_Discounted ? 
                  <><span className="product-price" style={{fontSize:"14px",fontWeight:"bold",color:"black"}}>Rs {res.Product_Price_Discounted} <br /> / Day</span></>
                  :
                  <span  style={{fontSize:"14px",fontWeight:"bold",color:"black"}}>
                  Rs {(res.Product_Price)} <br />
             / Day
                  </span>            
                                    } */}
                            </div>
                            </div>
                            </div>
                        







                                  {/* <hr style={{margin:"0px"}}/>           */}
                                  {/* { res && res.luggage &&  res.luggage > 0 ?
                            <div style={{display :"flex",flexDirection : "row",justifyContent :"space-between"}}>
                            <div>
                                <div style={{display:"flex",}}>
                                <DirectionsCarIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.doors && res.doors} Doors</p>
                            </div>
                                <div style={{display:"flex",}}>
                                <MeetingRoomIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.petrol && res.petrol}</p>
                            </div>
                            </div>
                            <div>
                                <div style={{display:"flex",}}>
                                <WhatshotIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.cc && res.cc} cc</p>
                            </div>
                                <div style={{display:"flex",}}>
                                <LocalMallIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.luggage && res.luggage} Lugg</p>
                            </div> 
                            </div>
                            <div>
                                <div style={{display:"flex",}}>
                                <ReceiptIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.ac && res.ac === "yes" ? "Ac" : "No AC"}</p>
                            </div>
                            <div style={{display:"flex",}}>
                                <SupervisorAccountIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.person && res.person} </p>
                            </div>
                            </div>
                            </div>
                            :""           
                      } */}
                      {/* <hr  style={{margin:"0px"}}/>
                    <div style={{display:"flex",marginTop:"10px"}}>
                         <h3 className="product-title"   style={{maxWidth:"100%",maxHeight : "59px",overflow : "hidden",textAlign:"left"}}>Earn upto 5% Off  </h3>
                         
                        </div>  */}
                      {/* <hr  style={{margin:"0px"}}/>               */}
                                  <div className="product-action position-relative visible" style={{display:"flex",justifyContent:"space-between",marginTop:"10px"}}>
                                    {/* {res.Product_Price_Discounted ? 
                    <><span className="product-price" style={{fontSize:"14px",fontWeight:"bold",color:"black"}}>Rs {res.Product_Price_Discounted} <br /> / Day</span></>
                    :
                    <span  style={{fontSize:"14px",fontWeight:"bold",color:"black"}}>
                    Rs {(res.Product_Price)} <br />
                / Day
                    </span>            
                                        }  */}
                                    <span className="shadow-none" style={{borderRadius:"15px",maxWidth:"150px",color:"white",background :"rgb(87 1 6)",padding :"10px 15px",color  :"white",fontSize:"12px",fontWeight:"bold"}} ><a href="tel:+923332221927" style={{color  :"white"}}>Call Now</a> </span>
                                    <span className="shadow-none" style={{borderRadius:"15px",maxWidth:"150px",color:"white",background :"rgb(87 1 6)",padding :"10px 15px",color  :"white",fontSize:"12px",fontWeight:"bold"}} onClick={()=>addtocartproduct([res])}>Book Now</span>
                                </div>     
                                </div>
                              </div>
                            </div>
       )
    })
    :
                    Subproduct.map((res,i)=>{
                        return(
      <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                              <div className="product product-7 text-center" style={{height : "auto",
    border: "2px solid rgb(149, 6, 13)",
    borderRadius: "20px",
    padding: "10px 0px",
    margin: "11px"}}>
                                <figure className="product-media">
                                  <Link  to={"/"+res.Product_Name_Full}>
                                    <img src={res.Product_Image_Upload} alt="Product image" className="product-image" />
                                  </Link>
                                  <div className="product-action-vertical">
                                    <a className="btn-product-icon btn-wishlist btn-expandable"  onClick={()=>addtocartproduct8([res])}><span>add to wishlist</span></a>
                                    <Link to={"/"+res.Product_Name_Full} className="btn-product-icon btn-quickview" title="Quick view"><span>Quick view</span></Link>
                                  </div>{/* End .product-action-vertical */}
                                </figure>{/* End .product-media */}
                                <div className="product-body">
                                  <div className="product-cat">
                                  </div>{/* End .product-cat */}
                                  <h3 className="product-title"   style={{maxWidth:"100%",maxHeight : "59px",overflow : "hidden",textAlign:"left"}}><Link to={"/"+res.Product_Name_Full}>{res.Product_Name}</Link></h3>{/* End .product-title */}


                            <div style={{display :"flex",flexDirection : "row",justifyContent :"space-between"}}>
                            <div>
                                <div style={{display:"flex",margin:"0px 0px 15px 0px"}}>
                                <img src={icon1} style={{height:"23px",width:"20px",color: "#95060D",fontSize : "15",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.person && res.person } Seats</p>
                            </div>
                                <div style={{display:"flex",}}>
                                <img src={icon2} style={{height:"23px",width:"20px",color: "#95060D",fontSize : "15",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>Automatic</p>
                            </div>
                            </div>
                            <div>
                               
                                <div style={{display:"flex",flexDirection:"column"}}>
                                  <div>

                 
                  <span className="product-price" style={{fontSize:"19px",fontWeight:"bold",color:"rgb(149, 6, 13)",padding:"0px 20px"}}>                  Rs {(res.OutCity)} <br />
             / Day
                  </span>            
                                  
                                  </div>
                              <div>
                              <button class="animated-button8" style={{marginBottom:"0px"}}>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  With Driver
</button>
                              </div>

                             
                            </div>
                           
                            </div>
                            </div>
                            <div style={{display :"flex",flexDirection : "row",justifyContent :"space-between"}}>
                            <div>
                                <div style={{display:"flex",margin:"0px 0px 15px 0px"}}>
                                <img src={icon3} style={{height:"16px",width:"20px",color: "#95060D",fontSize : "15",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black",textAlign:"left"}}> ( 24hrs/day )
                                {/* <p style={{fontSize:11,fontWeight :"600",color:"black",textAlign:"left"}}>With Driver (10hrs/day) */}
                                </p>
                            </div>
                                <div style={{display:"flex",}}>
                                <ReceiptIcon style={{height:"19px",width:"20px",color: "rgb(205 112 112)",fontSize : "15",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>Without Tax</p>
                            </div>
                            </div>
                            <div>
                                <div style={{display:"flex",marginTop:"20px"}}>
                                {/* <button class="animated-button8">
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  With Driver
</button> */}
                                {/* {res.Product_Price_Discounted ? 
                  <><span className="product-price" style={{fontSize:"14px",fontWeight:"bold",color:"black"}}>Rs {res.Product_Price_Discounted} <br /> / Day</span></>
                  :
                  <span  style={{fontSize:"14px",fontWeight:"bold",color:"black"}}>
                  Rs {(res.Product_Price)} <br />
             / Day
                  </span>            
                                    } */}
                            </div>
                            </div>
                            </div>
                        







                                  {/* <hr style={{margin:"0px"}}/>           */}
                                  {/* { res && res.luggage &&  res.luggage > 0 ?
                            <div style={{display :"flex",flexDirection : "row",justifyContent :"space-between"}}>
                            <div>
                                <div style={{display:"flex",}}>
                                <DirectionsCarIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.doors && res.doors} Doors</p>
                            </div>
                                <div style={{display:"flex",}}>
                                <MeetingRoomIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.petrol && res.petrol}</p>
                            </div>
                            </div>
                            <div>
                                <div style={{display:"flex",}}>
                                <WhatshotIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.cc && res.cc} cc</p>
                            </div>
                                <div style={{display:"flex",}}>
                                <LocalMallIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.luggage && res.luggage} Lugg</p>
                            </div> 
                            </div>
                            <div>
                                <div style={{display:"flex",}}>
                                <ReceiptIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.ac && res.ac === "yes" ? "Ac" : "No AC"}</p>
                            </div>
                            <div style={{display:"flex",}}>
                                <SupervisorAccountIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.person && res.person} </p>
                            </div>
                            </div>
                            </div>
                            :""           
                      } */}
                      {/* <hr  style={{margin:"0px"}}/>
                    <div style={{display:"flex",marginTop:"10px"}}>
                         <h3 className="product-title"   style={{maxWidth:"100%",maxHeight : "59px",overflow : "hidden",textAlign:"left"}}>Earn upto 5% Off  </h3>
                         
                        </div>  */}
                      {/* <hr  style={{margin:"0px"}}/>               */}
                                  <div className="product-action position-relative visible" style={{display:"flex",justifyContent:"space-between",marginTop:"10px"}}>
                                    {/* {res.Product_Price_Discounted ? 
                    <><span className="product-price" style={{fontSize:"14px",fontWeight:"bold",color:"black"}}>Rs {res.Product_Price_Discounted} <br /> / Day</span></>
                    :
                    <span  style={{fontSize:"14px",fontWeight:"bold",color:"black"}}>
                    Rs {(res.Product_Price)} <br />
                / Day
                    </span>            
                                        }  */}
                                    <span className="shadow-none" style={{borderRadius:"15px",maxWidth:"150px",color:"white",background :"rgb(87 1 6)",padding :"10px 15px",color  :"white",fontSize:"12px",fontWeight:"bold"}} ><a href="tel:+923332221927" style={{color  :"white"}}>Call Now</a> </span>
                                    <span className="shadow-none" style={{borderRadius:"15px",maxWidth:"150px",color:"white",background :"rgb(87 1 6)",padding :"10px 15px",color  :"white",fontSize:"12px",fontWeight:"bold"}} onClick={()=>addtocartproduct([res])}>Book Now</span>
                                </div>     
                                </div>
                              </div>
                            </div>
       )
    })
}
      
<div className='container' style={{margin : "30px 10px"}}>
            <div className='row'  >
            <div className="col-10 col-lg-10  col-sm-12" style={{margin  :"auto"}}>
            <section>
    {/* <h2>Best Rent a Car Services in Karachi</h2> */}

    <h2>Vans and Coasters on Rent</h2>
<h3>Spacious and Comfortable Travel for Groups with Pak Dream's Van and Coaster Rentals</h3>
<p style={{color:"black", fontWeight:"400"}}>
    If you’re planning a trip with family, friends, or colleagues, look no further than Pak Dream Rent A Car for reliable and affordable van and coaster rentals. Whether it's a family vacation, a group outing, or a corporate event, we offer a wide range of well-maintained vehicles to ensure your journey is comfortable and hassle-free.
</p>

<h4>Our Van and Coaster Rental Options</h4>
<h5>12-Seater Vans (Toyota Hiace)</h5>
<p style={{color:"black", fontWeight:"400"}}>
    Our 12-seater Toyota Hiace vans are perfect for small groups or families looking for a comfortable way to travel together. With ample seating space and room for luggage, these vans combine comfort and practicality. Equipped with modern features, these vehicles provide a smooth ride and are ideal for both short trips and longer journeys.
</p>

<h5>22-Seater Coaster</h5>
<p style={{color:"black", fontWeight:"400"}}>
    For larger groups, extended families, or corporate outings, our 22-seater coasters offer the perfect solution. Spacious and well-equipped with air conditioning, generous legroom, and entertainment systems, these coasters ensure your group can travel in style. Whether it's a weekend getaway or a long-distance journey, you can rely on our coasters to provide a comfortable, enjoyable ride for everyone.
</p>

<h4>Why Choose Pak Dream for Your Van and Coaster Rental?</h4>
<ul style={{color:"black", fontWeight:"400"}}>
    <li><strong>Affordable Rental Rates:</strong> We offer competitive pricing to suit various budgets, ensuring you get the best value for your money.</li>
    <li><strong>Flexible Rental Periods:</strong> Whether you need a van or coaster for a day, a weekend, or an extended period, we offer flexible rental options that fit your schedule.</li>
    <li><strong>Easy Booking:</strong> Our simple and user-friendly online booking system lets you reserve your vehicle in just a few clicks. You can also contact our customer support team for assistance.</li>
    <li><strong>Well-Maintained Vehicles:</strong> We take great care in maintaining our vans and coasters to the highest standards. Each vehicle undergoes regular inspections to ensure safety, reliability, and comfort.</li>
    <li><strong>24/7 Roadside Assistance:</strong> In case of an emergency or breakdown, our team is available 24/7 to offer immediate assistance, ensuring you’re never stranded.</li>
    <li><strong>Customer Satisfaction:</strong> At Pak Dream, we prioritize your satisfaction. Our goal is to exceed your expectations and make your rental experience smooth and enjoyable from start to finish.</li>
</ul>

<h4>Book Your Van or Coaster Today!</h4>
<p style={{color:"black", fontWeight:"400"}}>
    Don’t miss out on the convenience and comfort of our van and coaster rentals. Whether you're planning a group trip, family vacation, or corporate event, we have the perfect vehicle for you. Book now through our website or contact our friendly customer support team for more information and personalized assistance.
</p>

<h4>Why Rent Vans and Coasters with Pak Dream?</h4>
<p style={{color:"black", fontWeight:"400"}}>
    Pak Dream Rent A Car is widely recognized as one of the best car rental services in Pakistan, serving major cities like Karachi, Lahore, and Islamabad. With years of experience, we are committed to making your travel plans as seamless and enjoyable as possible. Our dedicated team is available to assist you 24/7, ensuring your journey is stress-free.
</p>

<h4>Here’s Why You Should Choose Pak Dream Over Other Rental Services:</h4>
<ul style={{color:"black", fontWeight:"400"}}>
    <li><strong>No Hidden Charges:</strong> What you see online is what you pay—insurance is included in the price, with only VAT and fuel costs added.</li>
    <li><strong>Flexible Pricing Plans:</strong> We offer customizable pricing packages that balance time and kilometers to suit your travel needs. You can adjust the packages as necessary.</li>
    <li><strong>24/7 Assistance:</strong> Our support team is always on hand to help. If there’s an issue, we’ll provide a replacement vehicle as quickly as possible.</li>
</ul>

<h4>Book Your Van or Coaster with Pak Dream Rent A Car Today!</h4>
<p style={{color:"black", fontWeight:"400"}}>
    Ready for your next trip with family or friends? Book a van or coaster with Pak Dream Rent A Car today and enjoy a comfortable, reliable, and cost-effective transportation solution. Explore our website or get in touch with us for any questions!
</p>
<div class="faq-content">
    <div class="faq-question">
        <input id="q50" type="checkbox" class="panel"/>
        <div class="plus">+</div>
        <label for="q50" class="panel-title">How can I rent a van or coaster from Pak Dream Rent A Car?</label>
        <div class="panel-content">
            <p>Renting a van or coaster with Pak Dream Rent A Car is simple! You can book your vehicle directly through our website by selecting your preferred van category, dates, and pickup location. Alternatively, you can contact our customer support team, who will be happy to assist you throughout the booking process and answer any questions.</p>
        </div>
    </div>

    <div class="faq-question">
        <input id="q51" type="checkbox" class="panel"/>
        <div class="plus">+</div>
        <label for="q51" class="panel-title">What types of vans and coasters are available for rental with a driver?</label>
        <div class="panel-content">
            <p>Our fleet includes a variety of spacious vehicles available for rent with a driver:</p>
            <ul>
                <li>12-Seater Vans (e.g., Toyota Hiace)</li>
                <li>22-Seater Coasters (e.g., Toyota Coaster, Toyota Grand Cabin)</li>
            </ul>
            <p>These vehicles are ideal for group travel, family trips, corporate events, and more.</p>
        </div>
    </div>

    <div class="faq-question">
        <input id="q52" type="checkbox" class="panel"/>
        <div class="plus">+</div>
        <label for="q52" class="panel-title">How far in advance do I need to book a van or coaster rental with a driver?</label>
        <div class="panel-content">
            <p>We recommend booking your van or coaster at least a few days in advance, particularly during peak travel seasons or for special events. However, if availability allows, you can also book a van or coaster as little as one hour before your desired time for locations within the city.</p>
        </div>
    </div>

    <div class="faq-question">
        <input id="q53" type="checkbox" class="panel"/>
        <div class="plus">+</div>
        <label for="q53" class="panel-title">Can the vans and coasters be booked without a driver?</label>
        <div class="panel-content">
            <p>Currently, all van and coaster rentals from Pak Dream Rent A Car come with a driver. Self-drive options are not available for these vehicles at this time.</p>
        </div>
    </div>

    <div class="faq-question">
        <input id="q54" type="checkbox" class="panel"/>
        <div class="plus">+</div>
        <label for="q54" class="panel-title">Can the vans and coasters be booked for out-of-city travels?</label>
        <div class="panel-content">
            <p>Yes, our vans and coasters can be rented for out-of-city travel with a driver. Whether you're going on a long road trip or traveling to another city, we’ve got you covered.</p>
        </div>
    </div>

    <div class="faq-question">
        <input id="q55" type="checkbox" class="panel"/>
        <div class="plus">+</div>
        <label for="q55" class="panel-title">Are there any mileage limitations on van and coaster rentals?</label>
        <div class="panel-content">
            <p>No, there are no mileage restrictions on our van and coaster rentals. These vehicles are perfect for long-distance travel and can be used for journeys across the country.</p>
        </div>
    </div>

    <div class="faq-question">
        <input id="q56" type="checkbox" class="panel"/>
        <div class="plus">+</div>
        <label for="q56" class="panel-title">Can I modify or cancel my van reservation?</label>
        <div class="panel-content">
            <p>Yes, you can modify or cancel your van or coaster reservation, in accordance with our cancellation policy. We understand that plans may change, so we provide flexibility within certain terms. For more details on modifications and cancellations, please refer to our terms and conditions or contact our customer support team for assistance.</p>
        </div>
    </div>
</div>
</section>


    </div>
            </div>
            </div>
         

                      
                    </div>{/* End .row */}
                  </div>{/* End .products */}
                  <nav aria-label="Page navigation">
                    <ul className="pagination justify-content-center">
                      <li className="page-item disabled">
                        <a className="page-link page-link-prev" href="#" aria-label="Previous" tabIndex={-1} aria-disabled="true">
                          <span aria-hidden="true"><i className="icon-long-arrow-left" /></span>Prev
                        </a>
                      </li>
                      <li className="page-item active" aria-current="page"><a className="page-link" href="#">1</a></li>
                      {/* <li className="page-item"><a className="page-link" href="#">2</a></li>
                      <li className="page-item"><a className="page-link" href="#">3</a></li>
                      <li className="page-item-total">of 6</li> */}
                      <li className="page-item">
                        <a className="page-link page-link-next" href="#" aria-label="Next">
                          Next <span aria-hidden="true"><i className="icon-long-arrow-right" /></span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>{/* End .col-lg-9 */}
                
              </div>{/* End .row */}
            </div>{/* End .container */}
          </div>{/* End .page-content */}
        </main>{/* End .main */}




</>
        )
    }



export default SubCategoriesProduct;