import React, { useState , useEffect } from 'react';
import { Link } from 'react-router-dom';
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
// import Loader from "react-loader-spinner";
import swal from 'sweetalert';

import Toyo from '../assets/images/Toyota-Corolla_20230829_201645_0000-2-1024x767.png';
import Toyo2 from '../assets/images/Honda-BRV_20230829_200536_0000-1024x767.png';
import Toyo3 from '../assets/images/Saloon-Coaster_20230829_201016_0000-1024x767.png';
import Toyo4 from '../assets/images/Toyota-Hiace_20230829_200852_0000-1024x767.png';
// import Toyo51 from '../assets/images/bannerFortuner.jpeg';
// import ch34 from '../assets/images/Unt.png';
import ch344 from '../assets/images/man-with-car.png';
import ch3444 from '../assets/images/support.png';
import ch34444 from '../assets/images/car-insurance.png';
import ch344444 from '../assets/images/dollar-bill.png';
import ch3444444 from '../assets/images/calendar.png';
import ch34444444 from '../assets/images/parking-area.png';import {Helmet} from "react-helmet";



const SubCategoriesProduct = (props)=> {
    const [CategoriesUrl , setCategoriesUrl] = useState("")
    const [Product , setProduct] = useState([])
    const [Product1 , setProduct1] = useState([])
    const [Product2 , setProduct2] = useState([])
    const [Categories , setCategories] = useState([])
    const [fal , setFal] = useState(false)
    const [fal1 , setFal1] = useState(false)
    const [CategoriesName , setCategoriesname] = useState({})
    const [subCate , setsubCate] = useState([])
    const [allproduct , setallproduct] = useState([])
    const [Subproduct , setSubproduct] = useState([])
    const [Subproduct1 , setSubproduct1] = useState([])
    const [SubCategories , setSubCategories] = useState([])
    const [SubClassCategories , setSubClassCategories] = useState([])
    const [SortedData , setSortedData] = useState([])
    const [SortedName , setSortedName] = useState([])
    const [SliderPhoto , setSliderPhoto] = useState([])
    const [DataPart2 , setDataPart2] = useState([])
    const [MainCatogories , setMainCatogories] = useState([])
    const [dispaluy53 , setdispaluy53] = useState(true)
    const [Cate1 , setCate1] = useState([])
    const [Cate2 , setCate2] = useState([])
    const [couponShow , setcouponShow] = useState(true)


    const [CarBookPrice , setCarBookPrice] = useState([])

    const [pickup1 , setpickup1] = useState("")
    const [drop1 , setdrop1] = useState("")
    const [pickup2 , setpickup2] = useState("")
    const [drop2 , setdrop2] = useState("")
    const [pickup3 , setpickup3] = useState("")
    const [drop3 , setdrop3] = useState("")
    const [pickup4 , setpickup4] = useState("")
    const [drop4 , setdrop4] = useState("")
    const [pickup , setpickup] = useState("")
    const [drop , setdrop] = useState("")


    // categoriesName subcategories

    useEffect(() =>{

        window.scrollTo(0, 0)
        // console.log( props.match.params);
        let Categoriesname = props.match.params ;
        setCategoriesname(Categoriesname)

        // const Inter = setInterval(()=>{
            //  if (!JSON.parse(localStorage.getItem("CateProduct")) ){
            //  var data1 = JSON.parse(localStorage.getItem("Cate")) 

        // var Category =  props.match.url.split("/")
        // setCategoriesUrl(data1)
        // var Category1 =  CategoriesUrl.split("-").join(" ")
        fetch("https://pak-dream-back.vercel.app/all-CarBookPrice",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res2=>res2.json())
        .then(res3=>{
            console.log(res3);
            setCarBookPrice(res3)
        
        })
       
        fetch("https://pak-dream-back.vercel.app/AllCategories",{
          method: "GET",
           headers :  {
           "Content-Type" : "application/json" , 
       } ,
      })
      .then(res2=>res2.json())
      .then(res3=>{
        fetch("https://pak-dream-back.vercel.app/AllProduct",{
          method: "GET",
          headers :  {
          "Content-Type" : "application/json" , 
      } ,
      })
      .then(res7=>res7.json())
      .then(res8=>{
          setallproduct(res8)
          let unique_values = res3
          .map((item) => item.CategoriesName)
          .filter(
              (value, index, current_value) => current_value.indexOf(value) === index
          );
          console.log(unique_values)
          let h = []
          unique_values.map((res5,y)=>{
            if(res5 === Categoriesname.categoriesName){
              h.push(res5)
            }
          })
          console.log(h)
          let pro = []
          //  unique_values.map((res9,i)=>{
          res8.map((res19,ii)=>{
              // console.log(res9.status , res9.Product_Catagories , Categoriesname.categoriesName  , res9.Product_Sub_Catagories , Categoriesname.subCate );
              if(res19.status && res19.Product_Sub_Catagories  === h[0] ){
                // console.log(res19,ii)
                pro.push(res19)
              }
          // })
          })

          setSubproduct(pro)
          // console.log(NonActive)
      })
      })
        fetch("https://pak-dream-back.vercel.app/AllCategories",{
          method: "GET",
           headers :  {
           "Content-Type" : "application/json" , 
       } ,
      })
      .then(res2=>res2.json())
      .then(res3=>{
          // console.log(res3);
          // const Data1 = res3.filter((res4,i)=>{
          //     if (res4.MainCategories === props.history.location.pathname.split("/")[2])
          //     return res4
          // })
          // console.log(Data1,res3)
          setSubCategories(res3)
          // setSubCategoriesname(res3)
          fetch("https://pak-dream-back.vercel.app/MainCatogories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res12=>res12.json())
        .then(res13=>{
          let a = []
          // console.log(res3)
          for (let i = 0; i < res13.length; i++) {
          for (let j = 0; j < res3.length; j++) {
            // console.log(res3[j].MainCategories , res13[i].MainCategories)
            if(res3[j].MainCategories === res13[i].MainCategories){
              // a.push("1")
// console.log(i,j)
              a[i] = "1"
              break
            }
            else{
              a[i] = "0"
              // a.pusH(("0"))
            }
            
          }
          }
          // console.log(a)
          setCate1(a)
        })
      })


      fetch("https://pak-dream-back.vercel.app/CourseAllSubCategories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res2=>res2.json())
        .then(res3=>{
            let data = res3.sort((a, b) => {
                return a.sort - b.sort;
            });
            // console.log(data);
            setSubClassCategories(data)


            fetch("https://pak-dream-back.vercel.app/AllCategories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res12=>res12.json())
        .then(res13=>{
          let a = []
          console.log(res3,res13)
          for (let i = 0; i < res13.length; i++) {
          for (let j = 0; j < res3.length; j++) {
            console.log(res3[j].MainCategories , res13[i].MainCategories , res3[j].CategoriesName , res13[i].CategoriesName )
            if(res3[j].MainCategories === res13[i].MainCategories && res3[j].CategoriesName === res13[i].CategoriesName ){
              // a.push("1")
console.log(i,j)
              a[i] = "1"
              break
            }
            else{
              a[i] = "0"
              // a.pusH(("0"))
            }
            
          }
          }
          console.log(a)
          setCate2(a)
        })
        })
        fetch("https://pak-dream-back.vercel.app/slider-photo",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res=>res.json())
        .then(res1=>{
            // console.log(res1[0]);
            setSliderPhoto(res1)
        
        })


        
                    fetch("https://pak-dream-back.vercel.app/TagsSort1111",{
                    method: "GET",
                        headers :  {
                        "Content-Type" : "application/json" , 
                    } ,
                })
                .then(res9=>res9.json())
                .then(res8=>{
                    const dta = res8.filter((res7,i)=>{
                        if(res7.MainCategories ===Categoriesname.categoriesName  && res7.New_Sub_Categories === Categoriesname.subcategories) {
                            console.log(res7);
                            return res7
                        }
                    })
                    // console.log(dta);
                    setSortedData(dta)
                })
                fetch("https://pak-dream-back.vercel.app/MainCatogories",{
                  method: "GET",
                   headers :  {
                   "Content-Type" : "application/json" , 
               } ,
              })
              .then(res2=>res2.json())
              .then(res3=>{
                console.log(res3);
                // setMainCatogories(res3)
                
    
                let dat = []
                res3.map((res55,c)=>{
                    dat.push(res55)
                })
                // console.log(SubClassCategories,asse)
                console.log(dat)
                let data = dat.sort((a, b) => {
                    return a.sort - b.sort;
                });
                setMainCatogories(data)
                
              })
                    fetch("https://pak-dream-back.vercel.app/AllCategories",{
                    method: "GET",
                        headers :  {
                        "Content-Type" : "application/json" , 
                    } ,
                })
                .then(res4=>res4.json())
                .then(res5=>{
                    let data = res5.filter((res6,i)=>{
                        if (res6.MainCategories === Categoriesname.categoriesName) return res6
                    })
                    setsubCate(data)
                    // console.log(res5,data);
                })

                fetch("https://pak-dream-back.vercel.app/CourseAllSubCategories",{
                  method: "GET",
                   headers :  {
                   "Content-Type" : "application/json" , 
               } ,
              })
              .then(res2=>res2.json())
              .then(res3=>{
                  let data = res3.sort((a, b) => {
                      return a.sort - b.sort;
                  });
                  // console.log(data);
                  setSubClassCategories(data)
              })

            

        

      




},[])


const savethedetailproduct = (data) =>{
    localStorage.setItem("Data" , JSON.stringify(data) )
    console.log(data);
 }
// useEffect(() => {
  
//     return () => {
//         clearInterval()
//     }
//   }, [])



const SortData1 = (a ,b) =>{
            setFal1(false)

          const Pro6 = Product.sort((a, b)=> {
                return parseFloat(a.Product_Price) - parseFloat(b.Product_Price)  
                
            });
            setProduct1(Pro6)
            setFal(true)
            console.log(Product1);



            
}
const SortData2 = (a ,b) =>{
    setFal(false)
          const Pro6 = Product.sort((a, b)=> {
            var nameA = a.Product_Name.toUpperCase(); 
            var nameB = b.Product_Name.toUpperCase(); 
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            return 0;
            });
            setProduct2(Pro6)
            setFal1(true)
            console.log(Product2);



            
}


const ViewAtDetails = ()=>{
    // console.log("fddddddddddddddddd")
   let data1 =  document.getElementById("view-at-hover")
   data1.setAttribute("class" , "hover-at-view")
}

const NoViewAtDetails = ()=>{
    // console.log("fddddddddddddddddd")
   let data1 =  document.getElementById("view-at-hover")
   data1.removeAttribute("class")
}

// const setSortPrice = (e)=>{
//     SortedName.push(e)
//     console.log(e);
//     if (e === "Low"){
//         const Pro6 = Subproduct.sort((a, b)=> {
//                 return parseFloat(a.Product_Price) - parseFloat(b.Product_Price)  
                
//             });
//             setSubproduct(Pro6)

//     }
//     if (e === "A"){
//         const Pro6 = Subproduct.sort((a, b)=> {
//             var nameA = a.Product_Name.toUpperCase(); 
//             var nameB = b.Product_Name.toUpperCase(); 
//             if (nameA < nameB) {
//                 return -1;
//             }
//             if (nameA > nameB) {
//                 return 1;
//             }

//             return 0;
//             });
//             console.log(Pro6);
//             setSubproduct(Pro6)

//     }
//     if (fal)
//     setFal(true)
//     else
//     setFal(false)
// }




const sortedAlgo = (e)=>{
    let d = false
    let da = 0
    SortedName.map((res9,a)=>{
        if(res9 === e){
            d = true
            da = a
        }
    })
    if(d){
        SortedName.splice(da,1)
    }
    else{
        SortedName.push(e)
    }
    fetch("https://pak-dream-back.vercel.app/AllProduct",{
                    method: "GET",
                    headers :  {
                    "Content-Type" : "application/json" , 
                } ,
                })
                .then(res7=>res7.json())
                .then(res8=>{
                    setallproduct(res8)
                    const NonActive = res8.filter((res9,i)=>{
                        // console.log(res9.status , res9.Product_Catagories , CategoriesName.categoriesName  ,res9.Product_Sub_Catagories , Categoriesname.subcategories);
                        if(res9.status && res9.Product_Catagories === CategoriesName.categoriesName){
                            return res9 
                        }
                    })
                
    if(SortedName.length > 0){
        let Coa = []
        SortedName.map((res22,x)=>{
      console.log(res22,SortedName)
//    console.log(Subproduct)
   const Sort = NonActive.filter((res,i)=>{
        res.Product_Name.split(" ").map((res1,i)=>{
            // console.log(res1)
            if (res1 === res22){
                Coa.push(res)
            }
        })
        res.Product_Title.split(" ").map((res1,i)=>{
            // console.log(res1)
            if (res1 === res22){
                Coa.push(res)
            }
        })
        
            res.tags.map((res1,i)=>{
                if (res1 === res22){
                    // console.log("true")
                    Coa.push(res)
                }
            })
        // }
   })  
    })
     let uniq = [...new Set(Coa)];

   console.log(uniq);
   setSubproduct(uniq)
}
else{
        setSubproduct(NonActive)
    }
   
  })
}














// const addtocartproduct = (...data111) =>{
//     //    localStorage.setItem("Data" , JSON.stringify(data) )
//        console.log(data111);


       




  
//                 document.getElementById("myDi").style.visibility = "visible"
//                 setTimeout(()=>{
//                 document.getElementById("myDi").style.visibility = "hidden"

//                 },1000)
//                 if (data111[0].Product_Price_Discounted === null){
//                   const data =  {...data111[0] ,
//                       Percentage : 0,
//                       DeliveryStatus : "Pending",
//                       Pieces :1 ,
//                       Total_Product_Price  : data111[0].Product_Price *1 }
//                   var data1 = JSON.parse(localStorage.getItem("Cart")) 
//                   if (data1){
//                   var data3 = data1.map((item) => {
//                       if(item._id === data._id ){
//                           console.log("double");
//                   ;                   localStorage.setItem("double",JSON.stringify(true))
//                   return {...item,
//                       Pieces :1 + item.Pieces,
//                       Total_Product_Price  : (data111[0].Product_Price *1 )+ item.Total_Product_Price}
//                   }
//                   else{
//                   console.log("double not match");
//                   return item
//                   }

//                   })
//                   var data5 =  JSON.parse(localStorage.getItem("double")) 
//                   console.log(DataPart2.length, data3.length,data5);
//                   var data10 =  JSON.parse(localStorage.getItem("Cart")) 

//                   if(data10.length=== data3.length && data5){
//                   console.log("double remove");
//                   localStorage.removeItem("double")
//                   localStorage.setItem("Cart" , JSON.stringify(data3) )

//                   }
//                   else{
//                   console.log("Differet");
//                   var data2 = [...data1 , data]

//                   localStorage.setItem("Cart" , JSON.stringify(data2) )
//                   }
//                   }
//                   else{
//                   console.log("1");
//                   localStorage.setItem("Cart" , JSON.stringify([data]) )

//                   }

//                   }
//                   else if (data111[0].Product_Price_Discounted){
//                   const data =  {...data111[0] ,
//                       Percentage : 0,
//                       DeliveryStatus : "Pending",
//                       Pieces :1 ,
//                       Total_Product_Price  : data111[0].Product_Price_Discounted *1 }
//                   var data1 = JSON.parse(localStorage.getItem("Cart")) 
//                   if (data1){
//                   var data3 = data1.map((item) => {
//                       if(item._id === data._id ){
//                           console.log("double");
//                   ;                   localStorage.setItem("double",JSON.stringify(true))
//                   return {...item,
//                       Pieces :1 + item.Pieces,
//                       Total_Product_Price  : (data111[0].Product_Price_Discounted *1 )+ item.Total_Product_Price}
//                   }
//                   else{
//                   console.log("double not match");
//                   return item
//                   }

//                   })
//                   var data5 =  JSON.parse(localStorage.getItem("double")) 
//                   console.log(DataPart2.length, data3.length,data5);
//                   var data10 =  JSON.parse(localStorage.getItem("Cart")) 

//                   if(data10.length=== data3.length && data5){
//                   console.log("double remove");
//                   localStorage.removeItem("double")
//                   localStorage.setItem("Cart" , JSON.stringify(data3) )

//                   }
//                   else{
//                   console.log("Differet");
//                   var data2 = [...data1 , data]

//                   localStorage.setItem("Cart" , JSON.stringify(data2) )
//                   }
//                   }
//                   else{
//                   console.log("1");
//                   localStorage.setItem("Cart" , JSON.stringify([data]) )

//                   }

//                   }
//                   else{

//                   }
                  
//                   }
                  











const setpickup11 = (e)=>{
    setpickup1(e)
    if(couponShow)
    setcouponShow(false)
  else setcouponShow(true)
    if(drop1){
      let a = false
      for (let i = 0; i < CarBookPrice.length; i++) {

        if(e === CarBookPrice[i].Pickup && drop1 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Corolla"){
          a = true
          if(CarBookPrice[i].Price){
            document.getElementById("car1").innerHTML ="Rs" + CarBookPrice[i].Price
          }
          else{
            document.getElementById("car1").innerHTML ="No Any Package"
          }
        }
        
      }
      if(!a)  document.getElementById("car1").innerHTML ="No Any Package"
    }

  }
  const setpickup21 = (e)=>{
    setpickup2(e)
    if(couponShow)
    setcouponShow(false)
  else setcouponShow(true)
    if(drop2){
      let a = false

      for (let i = 0; i < CarBookPrice.length; i++) {

        if(e === CarBookPrice[i].Pickup && drop2 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Honda BRV"){
          a=  true

          if(CarBookPrice[i].Price){
            document.getElementById("car2").innerHTML ="Rs" + CarBookPrice[i].Price
          }
          else{
            document.getElementById("car2").innerHTML ="No Any Package"
          }
        }
        
        
      }
      if(!a)  document.getElementById("car1").innerHTML ="No Any Package"
    }

  }
  const setpickup31 = (e)=>{
    setpickup3(e)
    if(couponShow)
    setcouponShow(false)
  else setcouponShow(true)
    if(drop3){
      let a = false

      for (let i = 0; i < CarBookPrice.length; i++) {

        if(e === CarBookPrice[i].Pickup && drop3 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Grand Cabin"){
          a=  true

          if(CarBookPrice[i].Price){
            document.getElementById("car3").innerHTML ="Rs" + CarBookPrice[i].Price
          }
          else{
            document.getElementById("car3").innerHTML ="No Any Package"
          }
        }
        
        
      }
      if(!a)  document.getElementById("car1").innerHTML ="No Any Package"
    }

  }
  const setpickup41 = (e)=>{
    setpickup4(e)
    if(couponShow)
    setcouponShow(false)
  else setcouponShow(true)
    if(drop1){
      let a = false

      for (let i = 0; i < CarBookPrice.length; i++) {

        if(e === CarBookPrice[i].Pickup && drop4 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Saloon Coaster"){
          a=  true

          if(CarBookPrice[i].Price){
            document.getElementById("car4").innerHTML ="Rs" + CarBookPrice[i].Price
          }
          else{
            document.getElementById("car4").innerHTML ="No Any Package"
          }
        }
        
        
      }
      if(!a)  document.getElementById("car1").innerHTML ="No Any Package"
    }

  }
  const setdrop11 = (e)=>{
    setdrop1(e)
    if(couponShow)
    setcouponShow(false)
  else setcouponShow(true)
  if(pickup1){
    let a = false
    for (let i = 0; i < CarBookPrice.length; i++) {
      if(pickup1 === CarBookPrice[i].Pickup && e === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Corolla"){
        a = true  
        if(CarBookPrice[i].Price> 1){
            console.log("gdfg",CarBookPrice[i].Price)
            document.getElementById("car1").innerHTML ="Rs" + CarBookPrice[i].Price
          }
          else{
          console.log("gdfg",CarBookPrice[i].Price)
          document.getElementById("car1").innerHTML ="No Any Package"
        }
      }
      
      }
      if(!a)  document.getElementById("car1").innerHTML ="No Any Package"

    }
    console.log("gdfg")
  }
  const setdrop21 = (e)=>{
    setdrop2(e)
    if(couponShow)
    setcouponShow(false)
  else setcouponShow(true)
    if(pickup2){
      let a = false

      for (let i = 0; i < CarBookPrice.length; i++) {
        if(pickup2 === CarBookPrice[i].Pickup && e === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Honda BRV"){
          a=  true

          if(CarBookPrice[i].Price){
          document.getElementById("car2").innerHTML ="Rs" + CarBookPrice[i].Price
        }
        else{
          document.getElementById("car2").innerHTML ="No Any Package"
        }
      }
      
    }
    if(!a)  document.getElementById("car1").innerHTML ="No Any Package"
    }
  }
  const setdrop31 = (e)=>{
    setdrop3(e)
    if(couponShow)
    setcouponShow(false)
  else setcouponShow(true)
    if(pickup3){
      let a = false

      for (let i = 0; i < CarBookPrice.length; i++) {
        if(pickup3 === CarBookPrice[i].Pickup && e === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Grand Cabin"){
          a=  true

          if(CarBookPrice[i].Price){
          document.getElementById("car3").innerHTML ="Rs" + CarBookPrice[i].Price
        }
        else{
          document.getElementById("car3").innerHTML ="No Any Package"
        }
      }
      
      }
      if(!a)  document.getElementById("car1").innerHTML ="No Any Package"

    }
  }
  const setdrop41 = (e)=>{
    setdrop4(e)
    if(couponShow)
    setcouponShow(false)
  else setcouponShow(true)
    if(pickup4){
      let a = false

      for (let i = 0; i < CarBookPrice.length; i++) {
        if(pickup4 === CarBookPrice[i].Pickup && e === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Saloon Coaster"){
        a=  true
          if(CarBookPrice[i].Price){
          document.getElementById("car4").innerHTML ="Rs" + CarBookPrice[i].Price
        }
        else{
          document.getElementById("car4").innerHTML ="No Any Package"
        }
      }
      
      }
      if(!a)  document.getElementById("car1").innerHTML ="No Any Package"

    }
  }



  const setCarPick1 = (e)=>{
    if(pickup1 && drop1){
      for (let i = 0; i < CarBookPrice.length; i++) {
        if(pickup1 === CarBookPrice[i].Pickup && drop1 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Corolla"){
      
          if(CarBookPrice[i].Price){
            localStorage.setItem("CarBookPric", JSON.stringify({
              Car : "Toyota Corolla", 
              Price :CarBookPrice[i].Price, 
              Pickup :pickup1,
              Drop : drop1
            }))
            swal("Booking Added in your Cart")
            props.history.push("/checkout2")
        }
        else{
          swal("No Package Avaiable")
        }
       
      }
      
      }

    }
    else{
      swal("Select PickUp & Drop Off City")
    }
  }
  const setCarPick2 = (e)=>{
    if(pickup2 && drop2){
      for (let i = 0; i < CarBookPrice.length; i++) {
        if(pickup2 === CarBookPrice[i].Pickup && drop2 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Honda BRV"){
      
          if(CarBookPrice[i].Price){
            localStorage.setItem("CarBookPric", JSON.stringify({
              Car : "THonda BRV", 
              Price :CarBookPrice[i].Price, 
              Pickup :pickup2,
              Drop : drop2
            }))
            swal("Booking Added in your Cart")
            props.history.push("/checkout2")
        }
        else{
          swal("No Package Avaiable")
        }
       
      }
      
      }

    }
    else{
      swal("Select PickUp & Drop Off City")
    }
  }
  const setCarPick3 = (e)=>{
    if(pickup3 && drop3){
      for (let i = 0; i < CarBookPrice.length; i++) {
        if(pickup3 === CarBookPrice[i].Pickup && drop3 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Grand Cabin"){
      
          if(CarBookPrice[i].Price){
            localStorage.setItem("CarBookPric", JSON.stringify({
              Car : "Grand Cabin", 
              Price :CarBookPrice[i].Price, 
              Pickup :pickup3,
              Drop : drop3
            }))
            swal("Booking Added in your Cart")
            props.history.push("/checkout2")
        }
        else{
          swal("No Package Avaiable")
        }
       
      }
      
      }

    }
    else{
      swal("Select PickUp & Drop Off City")
    }
  }
  const setCarPick4 = (e)=>{
    if(pickup4 && drop4){
      for (let i = 0; i < CarBookPrice.length; i++) {
        if(pickup4 === CarBookPrice[i].Pickup && drop4 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Saloon Coaster"){
      
          if(CarBookPrice[i].Price){
            localStorage.setItem("CarBookPric", JSON.stringify({
              Car : "Saloon Coaster", 
              Price :CarBookPrice[i].Price, 
              Pickup :pickup4,
              Drop : drop4
            }))
            swal("Booking Added in your Cart")
            props.history.push("/checkout2")
        }
        else{
          swal("No Package Avaiable")
        }
       
      }
      
      }

    }
    else{
      swal("Select PickUp & Drop Off City")
    }
  }
        return (









<>
           <Helmet>
                <link rel="canonical" href="https://pakdreamrentacar.com/one-way-cars" />
            </Helmet>
          <main className="main">
          <div className="page-header text-center" style={{backgroundImage: 'url("assets/images/page-header-bg.jpg")'}}>
            <div className="container">
              <h1 className="page-title">{CategoriesName.categoriesName}<span>One-Way Drop Service Across Pakistan</span></h1>
            </div>{/* End .container */}
          </div>{/* End .page-header */}
          <nav aria-label="breadcrumb" className="breadcrumb-nav mb-2">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="">Home</a></li>
                <li className="breadcrumb-item"><a href="#">One Way Car Rental</a></li>
                {/* <li className="breadcrumb-item active" aria-current="page">Highly Recommend</li> */}
              </ol>
            </div>{/* End .container */}
          </nav>{/* End .breadcrumb-nav */}
         
             

             

<div className='container' style={{background :  "white"}}>
<h3 className="title text-center font-weight-bold mt-3"> Our Transport all over Pakistan <br />
                <span style={{color : "#95060D"}}>
                 One Way Drop Packages
                  </span> 
</h3>
<div class="line"></div>
             <p class="title mb-2 text-center" style={{color : "black", fontSize : "15px",fontWeight : "500"}}><b> Pak Dream Rent A Car is one of
the Best leading Car rental services in Pakistan.
Since starting our service of rent a car in Karachi back in the year 2015, we've enabled people to travel in a high-quality vehicle safely and accessibly. We've expanded our operations considerably in the past 9+ years and offer car renting services in many locations all over Pakistan. Choose a car from our wide range of vehicles on your next trip. Just let us know when and where!
<br />
( Note : Car Fuel and Tools Included  Without Tax)

</b> 

</p>
          <div class="row justify-content-center">
              <div class="col-lg-3 col-sm-6">
                  <div class="icon-box text-center cfg" style={{padding  :"0px 13px" ,height : "500px",borderRadius : "20px",color : "white", background : "#95060D" }} >
                          {/* <i class="icon-local_car_wash"></i> */}
                          <span class="icon-box-icon cfg" style={{padding  :"0px" , }}>
 
                          <img src={Toyo} alt="" style={{height: "168px",width: "262px"}}/>
                          </span>

                      <div class="icon-box-content" style={{}}>
                          <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}> Toyota Corolla


</h3>
<br />
                  <div class="form-group" style={{width: "190px", margin : "auto" }}>
										<span class="form-label">PICK-UP CITY</span>
                    
                    <select class="form-control"  required="required" aria-required="true" style={{height : '40px'}}  value={pickup1} onChange={(e)=>setpickup11(e.target.value)} >
                    <option value="">Choose an option</option>
                    <option value="islamabad">Islamabad</option>
                    <option value="karachi">Karachi</option>
                    <option value="lahore">Lahore</option>
                    <option value="multan">Multan</option>
                    </select>
                     
                    <span class="select-arrow"></span>

										{/* <input type="text" placeholder="City or airport"/> */}
									</div>
                  <br />
                  <div class="form-group" style={{width: "190px", margin : "auto" }}>
										<span class="form-label">Dropoff Location</span>
                    <select class="form-control"  required="required" aria-required="true" style={{height : '40px'}}  value={drop1} onChange={(e)=>setdrop11(e.target.value)} >
                   <option value="">Choose an option</option>
                           <option value="Thano Bula Khan">Thano Bula Khan</option>
    <option value="Hyderabad">Hyderabad</option>
    <option value="Jamshoro">Jamshoro</option>
    <option value="Hala">Hala</option>
    <option value="Sakrand">Sakrand</option>
    <option value="Sehwan">Sehwan</option>
    <option value="Moro">Moro</option>
    <option value="Lahore">Lahore</option>
    <option value="Multan">Multan</option>
    <option value="Karachi">Karachi</option>
    <option value="Gwadar">Gwadar</option>
    <option value="Islamabad">Islamabad</option>
    <option value="Peshawar">Peshawar</option>
    <option value="Sargodha">Sargodha</option>
    <option value="Sialkot">Sialkot</option>
    <option value="Nawabshah">Nawabshah</option>
    <option value="Bahawalpur">Bahawalpur</option>
    <option value="Larkana">Larkana</option>
    <option value="Muzaffarabad">Muzaffarabad</option>
    <option value="Quetta">Quetta</option>
    <option value="Dadu">Dadu</option>
    <option value="Kandiaro">Kandiaro</option>
    <option value="Setharja">Setharja</option>
    <option value="Gambat">Gambat</option>
    <option value="Khairpur">Khairpur</option>
    <option value="Sukkur">Sukkur</option>
    <option value="Pano Aqil">Pano Aqil</option>
    <option value="Ghotki">Ghotki</option>
    <option value="Mirpur Mathelo">Mirpur Mathelo</option>
    <option value="Sadiqabad">Sadiqabad</option>
    <option value="Rahim Yar Khan">Rahim Yar Khan</option>
    <option value="Zahir Pir">Zahir Pir</option>
    <option value="Khanpur">Khanpur</option>
    <option value="Uch">Uch</option>
    <option value="Ahmadpur East">Ahmadpur East</option>
    <option value="Bahawalpur">Bahawalpur</option>
    <option value="Kahror Pakka">Kahror Pakka</option>
    <option value="Dunyapur">Dunyapur</option>
    <option value="Khanewal">Khanewal</option>
    <option value="Mian Channun">Mian Channun</option>
    <option value="Kamalia">Kamalia</option>
    <option value="Gojra">Gojra</option>
    <option value="Faisalabad">Faisalabad</option>
    <option value="Chak Jhumra">Chak Jhumra</option>
    <option value="Pindi Bhattian">Pindi Bhattian</option>
    <option value="Rabwah">Rabwah</option>
    <option value="Bhalwal">Bhalwal</option>
    <option value="Khewra">Khewra</option>
    <option value="Chakwal">Chakwal</option>
       <option value="Abbottabad">Abbottabad</option> 
      <option value="Gilgit">Gilgit</option>
    <option value="Attack">Attack</option>
    <option value="Kohat">Kohat</option>

    <option value="Mansehra">Mansehra</option>
    <option value="Haripur">Haripur</option>
    <option value="Badgram">Badgram</option>
 
                   </select>
                    
                    <span class="select-arrow"></span>

										{/* <input type="text" placeholder="City or airport"/> */}
									</div>
                          <br />
                          <p className="cgg1" id="car1">Select City
</p>
<div class="col-12 col-lg-12 col-xl-12" style={{margin : "auto"}}>
		                	<div class="btn-wrap">
		                		{/* <span>Round Style</span> */}
		                		<a class="btn btn-round" style={{background : "white",color  : "black"}} onClick={()=>setCarPick1()}><i class="icon-long-arrow-right"></i><span>Book Now</span></a>
		                	</div>
		                </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-3 col-sm-6">
                  <div class="icon-box text-center cfg" style={{padding  :"0px 13px" ,height : "500px",borderRadius : "20px",color : "white", background : "#95060D" }} >
                          {/* <i class="icon-local_car_wash"></i> */}
                          <span class="icon-box-icon cfg" style={{padding  :"0px" , }}>
 
                          <img src={Toyo2} alt="" style={{height: "168px",width: "262px"}}/>
                          </span>

                      <div class="icon-box-content" style={{}}>
                          <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}> Honda BRV



</h3>
<br />
<div class="form-group" style={{width: "190px", margin : "auto" }}>
										<span class="form-label">PICK-UP CITY</span>
                    
                    <select class="form-control"  required="required" aria-required="true" style={{height : '40px'}}  value={pickup2} onChange={(e)=>setpickup21(e.target.value)} >
                    <option value="">Choose an option</option>
                    <option value="islamabad">Islamabad</option>
                    <option value="karachi">Karachi</option>
                    <option value="lahore">Lahore</option>
                    <option value="multan">Multan</option>
                    </select>
                     
                    <span class="select-arrow"></span>

										{/* <input type="text" placeholder="City or airport"/> */}
									</div>
                  <br />
                  <div class="form-group" style={{width: "190px", margin : "auto" }}>
										<span class="form-label">Dropoff Location</span>
                    <select class="form-control"  required="required" aria-required="true" style={{height : '40px'}}  value={drop2} onChange={(e)=>setdrop21(e.target.value)} >
                   <option value="">Choose an option</option>
                           <option value="Thano Bula Khan">Thano Bula Khan</option>
    <option value="Hyderabad">Hyderabad</option>
    <option value="Jamshoro">Jamshoro</option>
    <option value="Hala">Hala</option>
    <option value="Sakrand">Sakrand</option>
    <option value="Sehwan">Sehwan</option>
    <option value="Moro">Moro</option>
    <option value="Lahore">Lahore</option>
    <option value="Multan">Multan</option>
    <option value="Karachi">Karachi</option>
    <option value="Gwadar">Gwadar</option>
    <option value="Islamabad">Islamabad</option>
    <option value="Peshawar">Peshawar</option>
    <option value="Sargodha">Sargodha</option>
    <option value="Sialkot">Sialkot</option>
    <option value="Nawabshah">Nawabshah</option>
    <option value="Bahawalpur">Bahawalpur</option>
    <option value="Larkana">Larkana</option>
    <option value="Muzaffarabad">Muzaffarabad</option>
    <option value="Quetta">Quetta</option>
    <option value="Dadu">Dadu</option>
    <option value="Kandiaro">Kandiaro</option>
    <option value="Setharja">Setharja</option>
    <option value="Gambat">Gambat</option>
    <option value="Khairpur">Khairpur</option>
    <option value="Sukkur">Sukkur</option>
    <option value="Pano Aqil">Pano Aqil</option>
    <option value="Ghotki">Ghotki</option>
    <option value="Mirpur Mathelo">Mirpur Mathelo</option>
    <option value="Sadiqabad">Sadiqabad</option>
    <option value="Rahim Yar Khan">Rahim Yar Khan</option>
    <option value="Zahir Pir">Zahir Pir</option>
    <option value="Khanpur">Khanpur</option>
    <option value="Uch">Uch</option>
    <option value="Ahmadpur East">Ahmadpur East</option>
    <option value="Bahawalpur">Bahawalpur</option>
    <option value="Kahror Pakka">Kahror Pakka</option>
    <option value="Dunyapur">Dunyapur</option>
    <option value="Khanewal">Khanewal</option>
    <option value="Mian Channun">Mian Channun</option>
    <option value="Kamalia">Kamalia</option>
    <option value="Gojra">Gojra</option>
    <option value="Faisalabad">Faisalabad</option>
    <option value="Chak Jhumra">Chak Jhumra</option>
    <option value="Pindi Bhattian">Pindi Bhattian</option>
    <option value="Rabwah">Rabwah</option>
    <option value="Bhalwal">Bhalwal</option>
    <option value="Khewra">Khewra</option>
    <option value="Chakwal">Chakwal</option>
       <option value="Abbottabad">Abbottabad</option> 
      <option value="Gilgit">Gilgit</option>
    <option value="Attack">Attack</option>
    <option value="Kohat">Kohat</option>

    <option value="Mansehra">Mansehra</option>
    <option value="Haripur">Haripur</option>
    <option value="Badgram">Badgram</option>
 
                   </select>
                    
                    <span class="select-arrow"></span>

										{/* <input type="text" placeholder="City or airport"/> */}
									</div>
                          <br />
                          <p className="cgg1" id="car2">Select City
</p>
<div class="col-12 col-lg-12 col-xl-12" style={{margin : "auto"}}>
		                	<div class="btn-wrap">
		                		{/* <span>Round Style</span> */}
		                		<a  onClick={()=>setCarPick2()} class="btn btn-round" style={{background : "white",color  : "black"}}><i class="icon-long-arrow-right"></i><span>Book Now</span></a>
		                	</div>
		                </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-3 col-sm-6">
                  <div class="icon-box text-center cfg" style={{padding  :"0px 13px" ,height : "500px",borderRadius : "20px",color : "white", background : "#95060D" }} >
                          {/* <i class="icon-local_car_wash"></i> */}
                          <span class="icon-box-icon cfg" style={{padding  :"0px" , }}>
 
                          <img src={Toyo3} alt="" style={{height: "168px",width: "262px"}}/>
                          </span>

                      <div class="icon-box-content" style={{}}>
                          <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}> Grand Cabin



</h3>
<br />
                  <div class="form-group" style={{width: "190px", margin : "auto" }}>
										<span class="form-label">PICK-UP CITY</span>
                    
                    <select class="form-control"  required="required" aria-required="true" style={{height : '40px'}}  value={pickup3} onChange={(e)=>setpickup31(e.target.value)} >
                    <option value="">Choose an option</option>
                    <option value="islamabad">Islamabad</option>
                    <option value="karachi">Karachi</option>
                    <option value="lahore">Lahore</option>
                    <option value="multan">Multan</option>
                    </select>
                     
                    <span class="select-arrow"></span>

										{/* <input type="text" placeholder="City or airport"/> */}
									</div>
                  <br />
                  <div class="form-group" style={{width: "190px", margin : "auto" }}>
										<span class="form-label">Dropoff Location</span>
                    <select class="form-control"  required="required" aria-required="true" style={{height : '40px'}}  value={drop3} onChange={(e)=>setdrop31(e.target.value)} >
                   <option value="">Choose an option</option>
                           <option value="Thano Bula Khan">Thano Bula Khan</option>
    <option value="Hyderabad">Hyderabad</option>
    <option value="Jamshoro">Jamshoro</option>
    <option value="Hala">Hala</option>
    <option value="Sakrand">Sakrand</option>
    <option value="Sehwan">Sehwan</option>
    <option value="Moro">Moro</option>
    <option value="Lahore">Lahore</option>
    <option value="Multan">Multan</option>
    <option value="Karachi">Karachi</option>
    <option value="Gwadar">Gwadar</option>
    <option value="Islamabad">Islamabad</option>
    <option value="Peshawar">Peshawar</option>
    <option value="Sargodha">Sargodha</option>
    <option value="Sialkot">Sialkot</option>
    <option value="Nawabshah">Nawabshah</option>
    <option value="Bahawalpur">Bahawalpur</option>
    <option value="Larkana">Larkana</option>
    <option value="Muzaffarabad">Muzaffarabad</option>
    <option value="Quetta">Quetta</option>
    <option value="Dadu">Dadu</option>
    <option value="Kandiaro">Kandiaro</option>
    <option value="Setharja">Setharja</option>
    <option value="Gambat">Gambat</option>
    <option value="Khairpur">Khairpur</option>
    <option value="Sukkur">Sukkur</option>
    <option value="Pano Aqil">Pano Aqil</option>
    <option value="Ghotki">Ghotki</option>
    <option value="Mirpur Mathelo">Mirpur Mathelo</option>
    <option value="Sadiqabad">Sadiqabad</option>
    <option value="Rahim Yar Khan">Rahim Yar Khan</option>
    <option value="Zahir Pir">Zahir Pir</option>
    <option value="Khanpur">Khanpur</option>
    <option value="Uch">Uch</option>
    <option value="Ahmadpur East">Ahmadpur East</option>
    <option value="Bahawalpur">Bahawalpur</option>
    <option value="Kahror Pakka">Kahror Pakka</option>
    <option value="Dunyapur">Dunyapur</option>
    <option value="Khanewal">Khanewal</option>
    <option value="Mian Channun">Mian Channun</option>
    <option value="Kamalia">Kamalia</option>
    <option value="Gojra">Gojra</option>
    <option value="Faisalabad">Faisalabad</option>
    <option value="Chak Jhumra">Chak Jhumra</option>
    <option value="Pindi Bhattian">Pindi Bhattian</option>
    <option value="Rabwah">Rabwah</option>
    <option value="Bhalwal">Bhalwal</option>
    <option value="Khewra">Khewra</option>
    <option value="Chakwal">Chakwal</option>
       <option value="Abbottabad">Abbottabad</option> 
      <option value="Gilgit">Gilgit</option>
    <option value="Attack">Attack</option>
    <option value="Kohat">Kohat</option>

    <option value="Mansehra">Mansehra</option>
    <option value="Haripur">Haripur</option>
    <option value="Badgram">Badgram</option>
 
                   </select>
                    
                    <span class="select-arrow"></span>

										{/* <input type="text" placeholder="City or airport"/> */}
									</div>
                          <br />
                          <p className="cgg1" id="car3">Select City
</p>
<div class="col-12 col-lg-12 col-xl-12" style={{margin : "auto"}}>
		                	<div class="btn-wrap">
		                		{/* <span>Round Style</span> */}
		                		<a  onClick={()=>setCarPick3()} class="btn btn-round" style={{background : "white",color  : "black"}}><i class="icon-long-arrow-right"></i><span>Book Now</span></a>
		                	</div>
		                </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-3 col-sm-6">
                  <div class="icon-box text-center cfg" style={{padding  :"0px 13px" ,height : "500px",borderRadius : "20px",color : "white", background : "#95060D" }} >
                          {/* <i class="icon-local_car_wash"></i> */}
                          <span class="icon-box-icon cfg" style={{padding  :"0px" , }}>
 
                          <img src={Toyo4} alt="" style={{height: "168px",width: "262px"}}/>
                          </span>

                      <div class="icon-box-content" style={{}}>
                          <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}> Saloon Coaster



</h3>
<br />
<div class="form-group" style={{width: "190px", margin : "auto" }}>
										<span class="form-label">PICK-UP CITY</span>
                    
                    <select class="form-control"  required="required" aria-required="true" style={{height : '40px'}}  value={pickup4} onChange={(e)=>setpickup41(e.target.value)} >
                    <option value="">Choose an option</option>
                    <option value="islamabad">Islamabad</option>
                    <option value="karachi">Karachi</option>
                    <option value="lahore">Lahore</option>
                    <option value="multan">Multan</option>
                    </select>
                     
                    <span class="select-arrow"></span>

										{/* <input type="text" placeholder="City or airport"/> */}
									</div>
                  <br />
                  <div class="form-group" style={{width: "190px", margin : "auto" }}>
										<span class="form-label">Dropoff Location</span>
                    <select class="form-control"  required="required" aria-required="true" style={{height : '40px'}}  value={drop4} onChange={(e)=>setdrop41(e.target.value)} >
                   <option value="">Choose an option</option>
                           <option value="Thano Bula Khan">Thano Bula Khan</option>
    <option value="Hyderabad">Hyderabad</option>
    <option value="Jamshoro">Jamshoro</option>
    <option value="Hala">Hala</option>
    <option value="Sakrand">Sakrand</option>
    <option value="Sehwan">Sehwan</option>
    <option value="Moro">Moro</option>
    <option value="Lahore">Lahore</option>
    <option value="Multan">Multan</option>
    <option value="Karachi">Karachi</option>
    <option value="Gwadar">Gwadar</option>
    <option value="Islamabad">Islamabad</option>
    <option value="Peshawar">Peshawar</option>
    <option value="Sargodha">Sargodha</option>
    <option value="Sialkot">Sialkot</option>
    <option value="Nawabshah">Nawabshah</option>
    <option value="Bahawalpur">Bahawalpur</option>
    <option value="Larkana">Larkana</option>
    <option value="Muzaffarabad">Muzaffarabad</option>
    <option value="Quetta">Quetta</option>
    <option value="Dadu">Dadu</option>
    <option value="Kandiaro">Kandiaro</option>
    <option value="Setharja">Setharja</option>
    <option value="Gambat">Gambat</option>
    <option value="Khairpur">Khairpur</option>
    <option value="Sukkur">Sukkur</option>
    <option value="Pano Aqil">Pano Aqil</option>
    <option value="Ghotki">Ghotki</option>
    <option value="Mirpur Mathelo">Mirpur Mathelo</option>
    <option value="Sadiqabad">Sadiqabad</option>
    <option value="Rahim Yar Khan">Rahim Yar Khan</option>
    <option value="Zahir Pir">Zahir Pir</option>
    <option value="Khanpur">Khanpur</option>
    <option value="Uch">Uch</option>
    <option value="Ahmadpur East">Ahmadpur East</option>
    <option value="Bahawalpur">Bahawalpur</option>
    <option value="Kahror Pakka">Kahror Pakka</option>
    <option value="Dunyapur">Dunyapur</option>
    <option value="Khanewal">Khanewal</option>
    <option value="Mian Channun">Mian Channun</option>
    <option value="Kamalia">Kamalia</option>
    <option value="Gojra">Gojra</option>
    <option value="Faisalabad">Faisalabad</option>
    <option value="Chak Jhumra">Chak Jhumra</option>
    <option value="Pindi Bhattian">Pindi Bhattian</option>
    <option value="Rabwah">Rabwah</option>
    <option value="Bhalwal">Bhalwal</option>
    <option value="Khewra">Khewra</option>
    <option value="Chakwal">Chakwal</option>
       <option value="Abbottabad">Abbottabad</option> 
      <option value="Gilgit">Gilgit</option>
    <option value="Attack">Attack</option>
    <option value="Kohat">Kohat</option>

    <option value="Mansehra">Mansehra</option>
    <option value="Haripur">Haripur</option>
    <option value="Badgram">Badgram</option>
 
                   </select>
                    
                    <span class="select-arrow"></span>

										{/* <input type="text" placeholder="City or airport"/> */}
									</div>
                          <br />
                          <p className="cgg1" id="car4">Select City
</p>
<div class="col-12 col-lg-12 col-xl-12" style={{margin : "auto"}}>
		                	<div class="btn-wrap">
		                		{/* <span>Round Style</span> */}
		                		<a  onClick={()=>setCarPick4()} class="btn btn-round" style={{background : "white",color  : "black"}}><i class="icon-long-arrow-right"></i><span>Book Now</span></a>
		                	</div>
		                </div>
                      </div>
                  </div>
              </div>

              
          </div>
</div>

<br />
<br />


<div className='container' style={{margin : "30px 10px"}}>
            <div className='row'  >
            <div className="col-10 col-lg-10  col-sm-12" style={{margin  :"auto"}}>


<h2>One-Way Drop Service</h2>
<p style={{color:"black", fontWeight:"400"}}>
    Travel in style, comfort, and safety with Pak Dream Rent A Car. Our one-way drop service is designed to provide you with a seamless, luxurious travel experience between major cities in Pakistan. Whether you're traveling from Karachi to Lahore, Islamabad to Multan, or Lahore to Peshawar, our luxury vehicles like Mercedes Benz, Audi A6, Toyota Land Cruiser, and Honda BRV will make your journey memorable and hassle-free.
</p>

<p style={{color:"black", fontWeight:"400"}}>
    We understand the importance of comfort on long journeys, which is why we offer premium cars, SUVs, and vans with professional drivers. Choose from a range of top-of-the-line vehicles such as the Toyota Prado, Toyota Fortuner, or Mercedes Benz E-Class for an unmatched travel experience.
</p>

<h4>Popular One-Way Drop Routes</h4>
<h5>From Karachi:</h5>
<ul style={{color:"black", fontWeight:"400"}}>
    <li>
        <strong>Karachi to Lahore</strong>
        Travel from Karachi to Lahore in luxury with our Mercedes Benz or Audi A6 for a refined and stylish journey. If you prefer more space, we also offer Toyota Land Cruiser and Toyota Prado SUVs, perfect for a family trip or corporate travel.
    </li>
    <li>
        <strong>Karachi to Multan</strong>
        Journey from Karachi to Multan in ultimate comfort with a Toyota Revo or Honda BRV. These vehicles offer ample space and are perfect for long-distance travel, making sure you travel in style.
    </li>
    <li>
        <strong>Karachi to Sukkur</strong>
        Whether you're traveling alone or with a group, choose from luxury sedans or SUVs like the Toyota Prado or Honda Accord for a smooth ride to Sukkur.
    </li>
    <li>
        <strong>Karachi to Islamabad</strong>
        Enjoy a comfortable one-way drop from Karachi to Islamabad in a Toyota Fortuner or Mercedes Benz E-Class. These vehicles offer a blend of luxury and space, ensuring your journey is both comfortable and efficient.
    </li>
    <li>
        <strong>Karachi to Peshawar</strong>
        Need to travel from Karachi to Peshawar? We offer a Toyota Land Cruiser or Audi A6 for a luxurious, safe, and fast journey. Our professional drivers ensure that your trip is smooth and on time.
    </li>
    <li>
        <strong>Karachi to Larkana</strong>
        Opt for a luxury SUV such as the Toyota Prado or Toyota Fortuner for your journey from Karachi to Larkana. Our vehicles are equipped with all modern amenities, ensuring your comfort throughout the trip.
    </li>
    <li>
        <strong>Karachi to Hyderabad</strong>
        For a convenient and luxurious ride from Karachi to Hyderabad, choose from a Mercedes Benz, Toyota Revo, or Honda BRV. Enjoy a smooth, enjoyable ride to your destination.
    </li>
    <li>
        <strong>Karachi to Rawalpindi</strong>
        A Toyota Land Cruiser or Mercedes Benz S-Class will ensure a comfortable journey from Karachi to Rawalpindi, making sure your trip is both luxurious and seamless.
    </li>
</ul>

<h2>One-Way Drop Service</h2>
<h4>From Lahore:</h4>
<ul style={{color:"black", fontWeight:"400"}}>
    <li>
        <strong>Lahore to Karachi</strong>
        Travel in comfort from Lahore to Karachi with a luxury sedan like the Mercedes Benz E-Class, or choose an SUV like the Toyota Prado or Toyota Land Cruiser for added space and luxury. Whatever your choice, our vehicles ensure a relaxing, stress-free journey.
    </li>
    <li>
        <strong>Lahore to Islamabad</strong>
        A one-way drop from Lahore to Islamabad becomes an exceptional experience in luxury cars like Audi A6 or Toyota Land Cruiser. We offer both luxury sedans and SUVs, so you can pick the best vehicle based on your needs.
    </li>
    <li>
        <strong>Lahore to Multan</strong>
        Travel from Lahore to Multan in the comfort of a Honda BRV or Toyota Revo, which offers plenty of room for passengers and luggage, making it the perfect choice for a long-distance journey.
    </li>
    <li>
        <strong>Lahore to Peshawar</strong>
        Whether you're traveling for business or leisure, choose a Toyota Prado or Toyota Fortuner for your one-way drop service from Lahore to Peshawar. These SUVs offer ample space and comfort for a long-distance trip.
    </li>
    <li>
        <strong>Lahore to Sukkur</strong>
        Opt for luxury sedans like the Mercedes Benz or Audi A6 to travel from Lahore to Sukkur. For a more spacious ride, consider a Toyota Land Cruiser or Toyota Revo for a safe and comfortable journey.
    </li>
    <li>
        <strong>Lahore to Rawalpindi</strong>
        Enjoy a stress-free and luxurious ride from Lahore to Rawalpindi in a Toyota Fortuner, Audi A6, or Mercedes Benz S-Class. Our professional drivers ensure your trip is both fast and safe.
    </li>
    <li>
        <strong>Lahore to Hyderabad</strong>
        From Lahore to Hyderabad, choose a luxury SUV like the Toyota Prado or Toyota Land Cruiser for a comfortable, smooth ride. Our luxury vehicles are equipped with all the modern amenities to make your journey enjoyable.
    </li>
    <li>
        <strong>Lahore to Larkana</strong>
        Our one-way drop service from Lahore to Larkana includes luxury sedans like the Mercedes Benz and Audi A6, as well as SUVs like the Toyota Prado and Honda BRV, ensuring you travel in comfort and style.
    </li>
</ul>



<h2>One-Way Drop Service</h2>
<h4>From Multan:</h4>
<ul style={{color:"black", fontWeight:"400"}}>
    <li>
        <strong>Multan to Karachi</strong>
        Experience luxury travel from Multan to Karachi in our Toyota Land Cruiser, Mercedes Benz, or Audi A6. Whether you're traveling for business or leisure, we ensure your trip is comfortable and stress-free.
    </li>
    <li>
        <strong>Multan to Lahore</strong>
        Whether you prefer the comfort of a Toyota Revo or the luxury of an Audi A6, our one-way drop service from Multan to Lahore offers a variety of vehicles to suit your needs.
    </li>
    <li>
        <strong>Multan to Islamabad</strong>
        Opt for a luxury sedan like the Mercedes Benz S-Class or a spacious Toyota Prado for your journey from Multan to Islamabad. We ensure comfort, safety, and luxury all the way.
    </li>
    <li>
        <strong>Multan to Peshawar</strong>
        Make your journey from Multan to Peshawar more comfortable with our luxury SUVs like the Toyota Fortuner or Toyota Land Cruiser. These vehicles are equipped with all modern amenities to make your long trip enjoyable.
    </li>
    <li>
        <strong>Multan to Sukkur</strong>
        A Toyota Prado or Honda BRV is the perfect choice for your one-way drop service from Multan to Sukkur. Enjoy a smooth, stress-free ride with our experienced drivers.
    </li>
    <li>
        <strong>Multan to Rawalpindi</strong>
        Travel in comfort and luxury from Multan to Rawalpindi with a Mercedes Benz or Toyota Land Cruiser. These vehicles provide ample space and luxury, ensuring that your journey is both pleasant and timely.
    </li>
</ul>


<h2>Why Choose Pak Dream Rent A Car for One-Way Drop Services?</h2>
<ul style={{color:"black", fontWeight:"400"}}>
    <li>
        <strong>Wide Range of Luxury Vehicles:</strong> 
        Choose from a range of luxury sedans, SUVs, and vans including Mercedes Benz, Audi A6, Toyota Land Cruiser, Toyota Prado, Honda BRV, and more.
    </li>
    <li>
        <strong>Professional Chauffeurs:</strong> 
        Our experienced, professional chauffeurs ensure a smooth and safe journey, whether you're traveling across the country or just between cities.
    </li>
    <li>
        <strong>Competitive Rates:</strong> 
        We offer affordable and flexible pricing options with no hidden charges. Insurance, VAT, and fuel charges are included, so you can rest assured you're getting the best deal.
    </li>
    <li>
        <strong>24/7 Availability:</strong> 
        Our one-way drop services are available 24/7 across major cities in Pakistan. Whether you need a ride during the day or at night, we are here to help.
    </li>
    <li>
        <strong>Easy Booking Process:</strong> 
        Book your one-way drop service online or through our customer service team. We make booking fast, easy, and convenient.
    </li>
</ul>

<h2>Book Your One-Way Drop Today!</h2>
<p style={{color:"black", fontWeight:"400"}}>
    Experience the convenience and luxury of our one-way drop services. Whether you're traveling from Karachi to Lahore, Islamabad to Multan, or any other route, Pak Dream Rent A Car ensures a luxurious, comfortable, and safe journey. Book now and enjoy a seamless travel experience!
</p>


<h2>One-Way Drop Service</h2>
<h4>From Islamabad:</h4>
<ul style={{color:"black", fontWeight:"400"}}>
    <li>
        <strong>Islamabad to Karachi</strong>
        Enjoy a luxurious ride from Islamabad to Karachi in our Mercedes Benz, Toyota Land Cruiser, or Audi A6. Our luxury sedans and SUVs offer a perfect blend of comfort, space, and style for long-distance travel.
    </li>
    <li>
        <strong>Islamabad to Lahore</strong>
        For your one-way drop from Islamabad to Lahore, travel in style with a Mercedes Benz, Toyota Prado, or Toyota Fortuner. These vehicles offer ample space and a smooth ride, making it perfect for business or leisure travel.
    </li>
    <li>
        <strong>Islamabad to Multan</strong>
        Take a comfortable ride from Islamabad to Multan in a luxury sedan like the Audi A6, or opt for a spacious Toyota Revo or Honda BRV for added space and comfort. Perfect for both solo travelers and families, these vehicles ensure a pleasant journey.
    </li>
    <li>
        <strong>Islamabad to Peshawar</strong>
        Travel from Islamabad to Peshawar in style with Toyota Prado or Toyota Land Cruiser. These luxury SUVs are perfect for both long trips and the need for ample space. Whether you're traveling for business or pleasure, you'll enjoy a smooth ride every time.
    </li>
    <li>
        <strong>Islamabad to Sukkur</strong>
        For a smooth, comfortable journey from Islamabad to Sukkur, we offer luxury sedans like Audi A6, or Toyota Fortuner SUVs. These vehicles offer the perfect combination of comfort and luxury, ensuring a premium travel experience.
    </li>
    <li>
        <strong>Islamabad to Rawalpindi</strong>
        A short trip from Islamabad to Rawalpindi is made even more comfortable in our Mercedes Benz S-Class or Toyota Land Cruiser. Whether you're traveling for business or leisure, our luxury cars and SUVs offer both comfort and convenience.
    </li>
    <li>
        <strong>Islamabad to Hyderabad</strong>
        For a longer journey from Islamabad to Hyderabad, enjoy a luxurious ride in a Mercedes Benz, Toyota Prado, or Audi A6. Our SUVs and sedans are equipped with all modern amenities to make your journey as comfortable as possible.
    </li>
    <li>
        <strong>Islamabad to Larkana</strong>
        Travel in comfort and luxury from Islamabad to Larkana with a Toyota Land Cruiser, Honda BRV, or Toyota Prado. These vehicles are designed to ensure comfort, safety, and a pleasant experience on long-distance routes.
    </li>
</ul>


<h2>Why Choose Pak Dream Rent A Car for One-Way Drop Services From Islamabad?</h2>
<ul style={{color:"black", fontWeight:"400"}}>
    <li>
        <strong>Wide Range of Luxury Vehicles:</strong> 
        We offer Mercedes Benz, Audi A6, Toyota Land Cruiser, Toyota Prado, Honda BRV, Toyota Revo, Toyota Fortuner, and more. Whether you're looking for a luxury sedan or a spacious SUV, we have the perfect vehicle for your needs.
    </li>
    <li>
        <strong>Experienced Professional Chauffeurs:</strong> 
        All our drivers are highly trained and experienced in long-distance travel. They are knowledgeable about routes and provide exceptional service to ensure a smooth journey.
    </li>
    <li>
        <strong>Flexible and Competitive Rates:</strong> 
        At Pak Dream Rent A Car, we offer affordable and flexible pricing options. Enjoy a transparent booking experience with no hidden charges—the price you see online is the price you pay (inclusive of VAT and insurance).
    </li>
    <li>
        <strong>24/7 Availability:</strong> 
        We provide one-way drop services from Islamabad to major cities 24 hours a day, 7 days a week. No matter when you need to travel, we're always here to serve you.
    </li>
    <li>
        <strong>Comfort and Convenience:</strong> 
        Our vehicles are equipped with modern amenities, ensuring that your journey is as comfortable and relaxing as possible. From luxury seats to advanced climate control, every detail is designed with your comfort in mind.
    </li>
    <li>
        <strong>On-Time Service:</strong> 
        With Pak Dream Rent A Car, punctuality is guaranteed. Whether you're headed to Lahore, Karachi, or any other city, we ensure timely pickups and drop-offs, so you don’t have to worry about delays.
    </li>
</ul>
<div class="faq-content">
    <div class="faq-question">
        <input id="q63" type="checkbox" class="panel"/>
        <div class="plus">+</div>
        <label for="q63" class="panel-title">Can I book a one-way drop service from Islamabad to Lahore, Karachi, or other cities?</label>
        <div class="panel-content">
            <p>Yes, we offer one-way drop services from Islamabad to a wide range of destinations, including Karachi, Lahore, Multan, Peshawar, Hyderabad, Sukkur, Rawalpindi, and more. You can choose from a range of luxury vehicles like Mercedes Benz, Audi A6, Toyota Prado, and Toyota Land Cruiser.</p>
        </div>
    </div>

    <div class="faq-question">
        <input id="q64" type="checkbox" class="panel"/>
        <div class="plus">+</div>
        <label for="q64" class="panel-title">Are your vehicles available for both one-way and round-trip rentals?</label>
        <div class="panel-content">
            <p>We specialize in one-way drop services, but if you need a round-trip, we can arrange that too. Just let us know your requirements, and we will provide a customized quote to fit your travel plans.</p>
        </div>
    </div>

    <div class="faq-question">
        <input id="q65" type="checkbox" class="panel"/>
        <div class="plus">+</div>
        <label for="q65" class="panel-title">What types of vehicles are available for the one-way drop service from Islamabad?</label>
        <div class="panel-content">
            <p>We offer a variety of luxury vehicles and SUVs for your one-way drop service, including:</p>
            <ul>
                <li>Mercedes Benz (Luxury Sedan)</li>
                <li>Audi A6 (Luxury Sedan)</li>
                <li>Toyota Prado (SUV)</li>
                <li>Toyota Land Cruiser (SUV)</li>
                <li>Toyota Fortuner (SUV)</li>
                <li>Honda BRV (SUV)</li>
                <li>Toyota Revo (Pickup/SUV)</li>
            </ul>
            <p>You can select the vehicle that best fits your comfort and space needs.</p>
        </div>
    </div>

    <div class="faq-question">
        <input id="q66" type="checkbox" class="panel"/>
        <div class="plus">+</div>
        <label for="q66" class="panel-title">Do I need to hire a driver for the one-way drop service?</label>
        <div class="panel-content">
            <p>Yes, all of our one-way drop services come with a professional, licensed driver. Our experienced chauffeurs are well-versed in the routes from Islamabad to Karachi, Lahore, Multan, and other cities, ensuring a safe and comfortable journey.</p>
        </div>
    </div>

    <div class="faq-question">
        <input id="q67" type="checkbox" class="panel"/>
        <div class="plus">+</div>
        <label for="q67" class="panel-title">Can I book the one-way drop service for long-distance travel, such as Islamabad to Karachi?</label>
        <div class="panel-content">
            <p>Absolutely! Our luxury cars like the Mercedes Benz, Audi A6, and Toyota Land Cruiser are perfect for long-distance travel. We provide comfortable and luxurious rides for trips such as Islamabad to Karachi, Islamabad to Lahore, Islamabad to Peshawar, and more.</p>
        </div>
    </div>

    <div class="faq-question">
        <input id="q68" type="checkbox" class="panel"/>
        <div class="plus">+</div>
        <label for="q68" class="panel-title">How far in advance should I book the one-way drop service?</label>
        <div class="panel-content">
            <p>For best availability, it’s recommended to book your one-way drop service at least 48 hours in advance, especially for popular routes like Islamabad to Karachi or Islamabad to Lahore. However, if the vehicle is available, we can accommodate bookings with as little as 1-2 hours’ notice.</p>
        </div>
    </div>

    <div class="faq-question">
        <input id="q69" type="checkbox" class="panel"/>
        <div class="plus">+</div>
        <label for="q69" class="panel-title">What is the pricing for the one-way drop service from Islamabad to other cities?</label>
        < div class="panel-content">
            <p>The price for a one-way drop service depends on the vehicle you choose, the distance, and any additional services required. You can contact us directly or use our online booking system to get an instant quote. Our prices are competitive, and we offer transparent pricing with no hidden charges.</p>
        </div>
    </div>

    <div class="faq-question">
        <input id="q70" type="checkbox" class="panel"/>
        <div class="plus">+</div>
        <label for="q70" class="panel-title">Are there any additional charges, like for fuel or tolls, on top of the rental fee?</label>
        <div class="panel-content">
            <p>The rental fee includes everything except fuel and toll charges, which are calculated based on the distance traveled. These additional charges are clearly mentioned during the booking process, so there are no surprises.</p>
        </div>
    </div>

    <div class="faq-question">
        <input id="q71" type="checkbox" class="panel"/>
        <div class="plus">+</div>
        <label for="q71" class="panel-title">Can I rent a luxury car for one-way drop services with a specific vehicle?</label>
        <div class="panel-content">
            <p>Yes, you can choose your preferred vehicle for the one-way drop service, whether it's a Mercedes Benz, Audi A6, Toyota Prado, or another luxury car. Simply specify your preference when making a reservation, and we will accommodate it as per availability.</p>
        </div>
    </div>

    <div class="faq-question">
        <input id="q72" type="checkbox" class="panel"/>
        <div class="plus">+</div>
        <label for="q72" class="panel-title">What cities are covered for one-way drop services from Islamabad?</label>
        <div class="panel-content">
            <p>We provide one-way drop services from Islamabad to the following cities:</p>
            <ul>
                <li>Islamabad to Lahore</li>
                <li>Islamabad to Karachi</li>
                <li>Islamabad to Multan</li>
                <li>Islamabad to Peshawar</li>
                <li>Islamabad to Sukkur</li>
                <li>Islamabad to Rawalpindi</li>
                <li>Islamabad to Hyderabad</li>
                <li>Islamabad to Larkana</li>
            </ul>
            <p>If your destination is not listed, please contact us, and we can arrange services for other cities as well.</p>
        </div>
    </div>

    <div class="faq-question">
        <input id="q73" type="checkbox" class="panel"/>
        <div class="plus">+</div>
        <label for="q73" class="panel-title">Are there any mileage restrictions for one-way drop services?</label>
        <div class="panel-content">
            <p>There are no mileage restrictions on our one-way drop services. You can travel as far as needed, and we will ensure a comfortable, seamless experience throughout the journey.</p>
        </div>
    </div>

    <div class="faq-question">
        <input id="q74" type="checkbox" class="panel"/>
        <div class="plus">+</div>
        <label for="q74" class="panel-title">What is the minimum booking duration for a one-way drop service?</label>
        <div class="panel-content">
            <p>The minimum booking duration for a one-way drop service is typically 10 hours. However, this can vary depending on the route, vehicle type, and other factors. For specific routes like Islamabad to Karachi or Islamabad to Lahore, we suggest confirming the duration with us when booking.</p>
        </div>
    </div>

    <div class="faq-question">
        <input id="q75" type="checkbox" class="panel"/>
        <div class="plus">+</div>
        <label for="q75" class="panel-title">Is there an option for extra services like refreshments, Wi-Fi, or entertainment during the ride?</label>
        <div class="panel-content">
            <p>Yes! We offer customized services to enhance your experience, including:</p>
            <ul>
                <li>Complimentary bottled water</li>
                <li>Wi-Fi availability (on request)</li>
                <li>Entertainment systems in select vehicles</li>
                <li>Snacks and refreshments (upon request)</li>
            </ul>
            <p>These additional services can be added to your booking to make your travel experience even more enjoyable.</p>
        </div> </div>

    <div class="faq-question">
        <input id="q76" type="checkbox" class="panel"/>
        <div class="plus">+</div>
        <label for="q76" class="panel-title">Can I modify or cancel my booking for the one-way drop service?</label>
        <div class="panel-content">
            <p>Yes, you can modify or cancel your booking with us, subject to our cancellation policy. We understand that plans may change, so we offer flexibility. Please refer to our terms and conditions or contact our customer service team for more details.</p>
        </div>
    </div>

    <div class="faq-question">
        <input id="q77" type="checkbox" class="panel"/>
        <div class="plus">+</div>
        <label for="q77" class="panel-title">How can I book the one-way drop service from Islamabad?</label>
        <div class="panel-content">
            <p>You can easily book your one-way drop service through our:</p>
            <ul>
                <li>Website: Use our online booking system to select your route, vehicle, and travel dates.</li>
                <li>Customer Support: Call or email our team to help you with booking and provide assistance in choosing the right vehicle.</li>
            </ul>
        </div>
    </div>
</div>

</div>
            </div>
            </div>











        </main>{/* End .main */}


</>

        )
    }



export default SubCategoriesProduct;