import React, { useState , useEffect } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import ch6 from '../asset1/images/contact-header-bg.jpg';
import ch1 from '../assets/images/pd1-724x1024.jpg';
import ch2 from '../assets/images/pd2-724x1024.jpg';
import ch3 from '../assets/images/pd3-724x1024.jpg';
import ch4 from '../assets/images/pd4-724x1024.jpg';
import ch5 from '../assets/images/pd5-724x1024.jpg';

import {Helmet} from "react-helmet";





const Contact = (props)=>  {
    const [name, setname] = useState("") 
    const [lname, setlname] = useState("") 
    const [email, setemail] = useState("") 
    const [subject, setsubject] = useState("") 
    const [Message, setMessage] = useState("") 
    const [phone, setphone] = useState("") 
    const [drop, setdrop] = useState("") 
    const [car, setcar] = useState("") 
    const [day1, setday1] = useState("")
    const [Product_Image_Upload , setProduct_Image_Upload] = useState("")
 
    useEffect(() =>{
        window.scrollTo(0, 0)

    },[])

    const Submitdata = (e)=>{
        e.preventDefault()

        // const data = new FormData()
        // data.append("file", Product_Image_Upload)
        // data.append("upload_preset", "commerce")
        // data.append("cloud_name", "freedeveloperss123")
        // fetch("https://api.cloudinary.com/v1_1/freedeveloperss123/image/upload",{ 
        //     method : "POST",
        //     body : data , 
        // })
        // .then(res=>res.json())
        // .then((res2)=>{
        //   console.log(res2)
          // this.setState({ url : res2.url})
          // setUrl(res2.url)
          // if(res2.url !== ""){
          //                     console.log(res2)
        fetch("https://pak-dream-back.vercel.app/Corporate-Enquiries",{
            method: 'POST' , 
            headers :  {
              "Content-Type" : "application/json" , 
            } , 
            body : JSON.stringify({
              name  ,
              lname  ,
              email  ,
              phone  ,
              car  ,
              Enquiry : drop,
              day1  ,
              setProduct_Image_Upload : "No"  ,
              // setProduct_Image_Upload : res2.url  ,
              Message  ,
            })
          })
          .then((res)=>res.json())
          .then((res2)  =>{
              console.log(res2)
              if (res2){
                swal("Data Send ! Wait For Our Team Contact You "  )
                setname ("") 
                setlname("") 
                    setemail("") 
                    setsubject("") 
                    setMessage("") 
                // localStorage.setItem("User" , JSON.stringify("Yes") )
                    props.history.push("/")
              }
              else if (res2.Error){
                swal(res2.Error )
              }
              else{
                swal("Plz Try Again !"  )
              }
            // console.log(res2)
          })
          .catch((err)=>console.log(err))
        // }
        // else{
        //     swal("Upload Again")                  
        // }
        // })
        
    }



        return (
            <> 

            <div>
                 <Helmet>
                <link rel="canonical" href="https://pakdreamrentacar.com/Corporate-Equiries" />
            </Helmet>
          <main className="main">
        <nav aria-label="breadcrumb" className="breadcrumb-nav border-0 mb-0">
          <div className="container">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="">Home</a></li>
              {/* <li className="breadcrumb-item"><a href="#">Pages</a></li> */}
              <li className="breadcrumb-item active" aria-current="page">Corporate Enquiries
</li>
            </ol>
          </div>{/* End .container */}
        </nav>{/* End .breadcrumb-nav */}
        <div className="container">
          <div className="page-header page-header-big text-center" style={{backgroundImage:  'url('+ch6+')'}}>
            <h1 className="page-title text-white">Corporate Enquiries
<span className="text-white">Please fill out the form below and our representatives will be in touch with you shortly.

</span></h1>
          </div>{/* End .page-header */}
        </div>{/* End .container */}





{/* 


        <div className='container' style={{}}>
          
          <div class="row justify-content-center">
              <div class="col-lg-4 col-sm-6">
                    <img src={ch1} alt="" />
              </div>
              <div class="col-lg-4 col-sm-6">
                    <img src={ch2} alt="" />
              </div>
              <div class="col-lg-4 col-sm-6">
                    <img src={ch3} alt="" />
              </div>
              <div class="col-lg-4 col-sm-6">
                    <img src={ch4} alt="" />
              </div>
              <div class="col-lg-4 col-sm-6">
                    <img src={ch5} alt="" />
              </div>

            
              
          </div>
</div>

<br /> */}


















        <div className="page-content pb-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mb-2 mb-lg-0">
                <h2 className="title mb-1">Contact Information</h2>{/* End .title mb-2 */}
                <p className="mb-3">Welcome to PakDreamRentACar.</p>
                <div className="row">
                  <div className="col-sm-7">
                    <div className="contact-info">
                      <h3>The Office</h3>
                      <ul className="contact-list">
                        <li>
                          <i className="icon-map-marker" />
                          Pak Dream Rent a Car, pak dream transport, Plot # 01 Showroom number3, 7 block, opposite Sindh Green restaurant, behind Saima Presidency, Gulistan-e-Johar, Karachi, 75400
                        </li>
                       
                        <li>
                          <i className="icon-phone" />
                           <a href="tel:+923398000011" className="text-primary">+92 339 8000011</a>
                        </li>
                        <li>
                          <i className="icon-phone" />
                           <a href="tel:02134024445" className="text-primary">021 340 24 445</a>
                        </li>
                          <li>
                            <i className="icon-phone" />
                              <a href="tel:+923332221927" className="text-primary">+92 333 2221927</a>
                          </li>
                          <li>
                            <i className="icon-phone" />
                           <a  href="tel:+923398000011" className="text-primary">+92 339 8000011</a>
                          </li>
                        <li>
                          <i className="icon-envelope" />
                          <a href=""><span className="__cf_email__" data-cfemail="">info@pakdreamrentacar.com</span></a>
                        </li>
                      </ul>{/* End .contact-list */}
                    </div>{/* End .contact-info */}
                  </div>{/* End .col-sm-7 */}
                  <div className="col-sm-5">
                    <div className="contact-info">
                      <h3>The Office</h3>
                      <ul className="contact-list">
                        <li>
                          <i className="icon-clock-o" />
                          <span className="text-dark">Mon-Sat</span> <br />24 Hours
                        </li>
                        <li>
                          <i className="icon-clock-o" />
                          <span className="text-dark">Customer support timing </span> <br />24 Hours
                        </li>
                        
                      </ul>{/* End .contact-list */}
                    </div>{/* End .contact-info */}
                  </div>{/* End .col-sm-5 */}
                </div>{/* End .row */}
              </div>{/* End .col-lg-6 */}
              <div className="col-lg-6">
                <h2 className="title mb-1">Drop Your Query
</h2>{/* End .title mb-2 */}
                <p className="mb-2">Use the form below to get in touch with the sales team</p>
                <form  onSubmit={(e)=>Submitdata(e)}>
                  <div className="row"> 
                    <div className="col-sm-6">
                      <label htmlFor="cname" >First Name</label>
                      <input type="text" required className="form-control" id="cname" placeholder="Name *"  value={name} onChange={(e)=>setname(e.target.value)}/>
                    </div>{/* End .col-sm-6 */}
                    <div className="col-sm-6">
                      <label htmlFor="cname" >Last Name</label>
                      <input type="text" required className="form-control" id="cname" placeholder="Name *"  value={lname} onChange={(e)=>setlname(e.target.value)}/>
                    </div>{/* End .col-sm-6 */}
                  </div>{/* End .row */}
                  <div className="row">
                    <div className="col-sm-6">
                      <label htmlFor="cphone" >Phone</label>
                      <input type="tel"  required className="form-control" id="cphone" placeholder="Phone"  value={phone} onChange={(e)=>setphone(e.target.value)}/>
                    </div>{/* End .col-sm-6 */}
                    <div className="col-sm-6">
                      <label htmlFor="cemail" >Email</label>
                      <input type="email" required className="form-control" id="cemail" placeholder="Email *" value={email} onChange={(e)=>setemail(e.target.value)}  />
                    </div>{/* End .col-sm-6 */}
                </div>{/* End .col-sm-6 */}
                  <div className="row">
                    <div className="col-sm-6">
                      <label htmlFor="cphone" >No of Cars *:</label>
                      <input type="number" required className="form-control" id="cphone" placeholder="No of Cars"  value={car} onChange={(e)=>setcar(e.target.value)}/>
                    </div>{/* End .col-sm-6 */}
                    <div className="col-sm-6">
                      <label htmlFor="cemail" >No of Days *:
</label>
                      <input type="number" className="form-control" id="cemail" placeholder="No of Days *: *" value={day1} onChange={(e)=>setday1(e.target.value)}  />
                    </div>{/* End .col-sm-6 */}
                </div>{/* End .col-sm-6 */}
                  <div className="row">
                    {/* <div className="col-sm-6">
                      <label htmlFor="cphone" >Upload Cnic</label>
                      <input type="file"  required className="form-control" id="cphone" placeholder="No of Cars"   onChange={(e)=>setProduct_Image_Upload(e.target.files[0])} />
                    </div> */}
                    <div className="col-sm-12">
                      <label htmlFor="cemail" >Purpose of Enquir requiredy *:

</label>
                    <select class="form-control"  required="required" aria-required="true"  value={drop} onChange={(e)=>setdrop(e.target.value)} >
                      <option value="Corporate Sale">Corporate Sale</option>
                      <option value="General Enquiries">General Enquiries</option>
                    </select>

                    </div>{/* End .col-sm-6 */}
                </div>{/* End .col-sm-6 */}
                <div className="col-sm-12">

                  <label htmlFor="cmessage" >Message</label>
                  <textarea className="form-control" required cols={30} rows={4} id="cmessage" placeholder="Message *" defaultValue={""}  value={Message}  onChange={(e)=>setMessage(e.target.value)} />
                  </div>
                  <button type="submit" className="btn btn-outline-primary-2 btn-minwidth-sm">
                    <span>SUBMIT</span>
                    <i className="icon-long-arrow-right" />
                  </button>
                </form>{/* End .contact-form */}
              </div>{/* End .col-lg-6 */}
            </div>{/* End .row */}
            <hr className="mt-4 mb-5" />
            
          </div>{/* End .container */}
          {/* <div id="map" /> */}
          {/* End #map */}
        </div>{/* End .page-content */}
      </main>{/* End .main */}





      
      <div className='container' style={{margin : "30px 10px"}}>
            <div className='row'  >
            <div className="col-10 col-lg-10  col-sm-12" style={{margin  :"auto"}}>

    <header>
        <h2>Corporate Enquiries - Premium Vehicle Rentals for Businesses</h2>
    </header>

    <section id="introduction">
        <h3>Welcome to Pak Dream Rent a Car</h3>
        <p>At Pak Dream Rent a Car, we understand the importance of professionalism, reliability, and comfort when it comes to corporate travel. Whether you’re organizing a business trip, planning a corporate event, or need transportation for staff, clients, or VIPs, we provide customized transportation solutions to meet your company’s needs.</p>
        <p>We offer a wide range of vehicles designed for businesses, including luxury sedans, SUVs, vans, and armored vehicles, available for all types of corporate events, meetings, and travel across Pakistan. From Karachi to Lahore, Islamabad, Multan, and other major cities, we ensure that your corporate transportation is always smooth, punctual, and professional.</p>
    </section>

    <section id="why-choose-us">
        <h3>Why Choose Pak Dream Rent a Car for Corporate Travel?</h3>
        <p>Our corporate vehicle rental service is built on providing exceptional service with a focus on quality, timeliness, and comfort. Here’s why corporate clients trust us:</p>
        <ul>
            <li><strong>Professional Chauffeurs:</strong> Our drivers are highly trained, experienced, and adhere to corporate etiquette. They ensure that your executives, staff, or clients are treated with respect and comfort throughout the journey.</li>
            <li><strong>Punctuality:</strong> Time is valuable in business. We guarantee timely pickups and arrivals so that your team or clients are always on schedule.</li>
            <li><strong>Customized Packages:</strong> We offer flexible rental options, whether you need a luxury sedan for VIP transport, an SUV for larger groups, or vans and coasters for corporate events.</li>
            <li><strong>Seamless Booking:</strong> Booking corporate vehicles is easy. Simply use our online booking system or reach out to our customer service team for personalized assistance.</li>
            <li><strong>Corporate Accounts:</strong> For businesses that need regular transportation, we offer dedicated corporate accounts to streamline billing and bookings, providing you with priority service.</li>
        </ul>
    </section>

    <section id="fleet">
        <h3>Our Corporate Fleet</h3>
        <p>We offer a diverse fleet of vehicles that cater to various corporate needs, including executive travel, client transfers, business meetings, and corporate events. Our vehicles include:</p>
        
        <h4>Luxury Sedans:</h4>
        <ul>
            <li>Mercedes Benz</li>
            <li>Audi A6</li>
            <li>BMW 5 Series</li>
        </ul>
        <p>These vehicles are perfect for transporting executives, VIPs, or clients to meetings, airports, or events in style and comfort.</p>

        <h4>SUVs:</h4>
        <ul>
            <li>Toyota Prado</li>
            <li>Toyota Land Cruiser</li>
            <li>Honda CR-V</li>
        </ul>
        <p>Spacious and comfortable, our SUVs are ideal for executive travel, team trips, or long-distance business travel.</p>

        <h4>Vans and Coasters:</h4>
        <ul>
            <li>Toyota Hiace 12-seater Vans</li>
            <li>Toyota Coaster 22-seater</li>
        </ul>
        <p>These vehicles are perfect for large groups, corporate outings, conferences, or events. They offer ample space for both passengers and luggage.</p>

        <h4>Armored Vehicles:</h4>
        <p>For high-security needs, we offer bulletproof vehicles to ensure the safety of high-profile executives during travel.</p>
    </section>


    <section id="event-transportation">
        <h3>Corporate Event Transportation</h3>
        <p>Our services extend to corporate events, providing reliable transportation for conferences, seminars, and business retreats:</p>
        <ul>
            <li><strong>Conference & Seminar Transportation:</strong> We can transport speakers, delegates, and attendees to and from conference venues, ensuring they arrive in comfort.</li>
            <li><strong>Business Retreats & Team Outings:</strong> Whether you're organizing a team-building retreat or need transport for staff events, we provide vans, SUVs, and coasters for smooth group travel.</li>
            <li><strong>VIP & Executive Services:</strong> We specialize in providing top-tier luxury car rentals with professional chauffeurs for high-profile business executives and clients.</li>
        </ul>
    </section>

    <section id="airport-transfers">
        <h3>Corporate Airport Transfers</h3>
        <p>We specialize in corporate airport transfers, ensuring that your executives, clients, or staff are picked up or dropped off in style. Our services include:</p>
        <ul>
            <li>Pick-ups and drop-offs at major airports, including Karachi Jinnah International, Lahore Allama Iqbal International, and Islamabad International.</li>
            <li>Luxury transport to and from hotels, business meetings, or corporate events.</li>
            <li>24/7 availability for flexible and convenient travel schedules.</li>
        </ul>
    </section>

    <section id="corporate-accounts">
        <h3>Corporate Accounts and Billing</h3>
        <p>For businesses with regular transportation needs, we offer the following services:</p>
        <ul>
            <li><strong>Corporate Accounts:</strong> Register for a corporate account to manage bookings, payments, and invoices in one place.</li>
            <li><strong>Flexible Payment Options:</strong> Choose from monthly or quarterly billing cycles for seamless expense management.</li>
            <li><strong>Priority Service:</strong> As a corporate account holder, you’ll enjoy priority booking and guaranteed vehicle availability during peak times.</li>
            <li><strong>Custom Packages:</strong> We can create tailored rental packages to suit your company’s specific travel needs, ensuring both cost-efficiency and flexibility.</li>
        </ul>
    </section>

    <section id="booking">
        <h3>How to Book Corporate Vehicle Rentals</h3>
        <p>Booking your corporate vehicle with Pak Dream Rent a Car is straightforward:</p>
        <ul>
            <li><strong>Online Booking:</strong> Visit our website to quickly reserve a vehicle based on your travel needs, dates, and locations.</li>
            <li><strong>Corporate Account:</strong> If you’re a frequent corporate client, register for an account to streamline future bookings and manage payments.</li>
            <li><strong>Contact Our Corporate Team:</strong> For large group bookings or special requests, our corporate team is available to assist you. Get in touch with us to discuss your requirements, and we will provide a custom quote.</li>
        </ul>
    </section>

    <section id="why-choose-us">
        <h3>Why Choose Pak Dream Rent a Car?</h3>
        <ul>
            <li><strong>Expertise & Experience:</strong> With years of experience in corporate vehicle rentals across Pakistan, we understand the unique needs of businesses and strive to exceed expectations.</li>
            <li><strong>Reliable & Professional Service:</strong> From punctuality to superior customer service, we prioritize your satisfaction and work hard to ensure a seamless travel experience.</li>
            <li><strong>Fleet Diversity:</strong> We offer a wide range of high-end vehicles, from luxury sedans to SUVs, vans, and armored vehicles, giving you flexibility and convenience for all corporate needs.</li>
        </ul>
    </section>

    <section id="contact">
        <h3>Contact Us for Corporate Enquiries</h3>
        <p>For more information on corporate vehicle rentals or to book your next corporate trip, please contact us:</p>
        <p><strong>Phone:</strong> 03332221927</p>
        <p><strong>Email:</strong> <a href="mailto:info@pakdreamrentacar.com">info@pakdreamrentacar.com</a></p>
        <p>We’re available 24/7 to help you with all your corporate transportation needs.</p>
    </section>


    <header>
        <h1>Pak Dream Rent a Car</h1>
        <h2>Frequently Asked Questions (FAQs)</h2>
    </header>

    <section id="faqs">
        <h3>1. What types of vehicles are available for corporate rentals?</h3>
        <p>We offer a wide range of vehicles to suit various corporate needs, including:</p>
        <ul>
            <li><strong>Luxury Sedans:</strong> (e.g., Mercedes Benz, Audi A6, BMW 5 Series) for executive travel and client transfers.</li>
            <li><strong>SUVs:</strong> (e.g., Toyota Prado, Toyota Land Cruiser, Honda CR-V) for team trips or business meetings.</li>
            <li><strong>Vans and Coasters:</strong> (e.g., Toyota Hiace 12-seater, Toyota Coaster 22-seater) for corporate events and large group transportation.</li>
            <li><strong>Armored Vehicles:</strong> for high-security corporate travel.</li>
        </ul>

        <h3>2. Can I book vehicles for corporate events or conferences?</h3>
        <p>Yes, we specialize in providing transportation for corporate events, conferences, seminars, and team-building retreats. We can provide luxury sedans for executives, vans or coasters for large groups, and SUVs for business meetings. Our fleet can accommodate both small and large groups with comfort and style.</p>

        <h3>3. Can I book a vehicle without a driver?</h3>
        <p>Currently, Pak Dream Rent a Car only offers vehicles with a driver for corporate rentals. All our vehicles come with professional, experienced chauffeurs to ensure a smooth and hassle-free journey for you and your team.</p>

        <h3>4. How far in advance should I book a corporate vehicle?</h3>
        <p>It’s recommended to book your corporate vehicle at least a few days in advance, especially during peak business seasons or for special events. However, if availability permits, vehicles can be booked on short notice, even an hour before your desired time, for local travel within the city.</p>

        <h3>5. Can I book a vehicle for out-of-city corporate travel?</h3>
        <p>Yes, our vehicles are available for both local and out-of-city travel across Pakistan. Whether you’re traveling from Karachi to Lahore, Islamabad to Rawalpindi, or to other cities, we offer flexible options for long-distance corporate travel.</p>

        <h3>6. Do you offer corporate accounts for businesses?</h3>
        <p>Yes, we provide corporate accounts to businesses that require regular transportation. With a corporate account, you can enjoy:</p>
        <ul>
            <li>Priority bookings and guaranteed vehicle availability.</li>
            <li>Flexible billing options, including monthly or quarterly payments.</li>
            <li>A streamlined booking process for all future rentals.</li>
            <li>Tailored transportation packages that suit your company’s travel needs.</li>
        </ul>

        <h3>7. Can I modify or cancel my booking?</h3>
        <p>Yes, you can modify or cancel your corporate booking subject to our cancellation policy. We understand that business plans can change, and we offer flexibility within certain guidelines. Please refer to our terms and conditions or contact our customer support team for more details.</p>

        <h3>8. How do I book a corporate vehicle?</h3>
        <p>Booking a corporate vehicle is easy and straightforward:</p>
        <ul>
            <li><strong>Online Booking:</strong> Visit our website to browse available vehicles and book instantly.</li>
            <li><strong>Corporate Account:</strong> For frequent bookings, register for a corporate account to streamline the process and manage billing.</li>
            <li><strong>Direct Contact:</strong> For large group bookings or special requests, contact our corporate team by phone or email, and we’ll provide a customized quote.</li>
        </ul>

        <h3>9. Do you offer airport transfers for corporate clients?</h3>
        <p>Yes, we provide airport transfers for corporate clients, ensuring smooth arrivals and departures for executives, staff, and clients. We can arrange for transportation from all major airports, including Karachi Jinnah International, Lahore Allama Iqbal International, and Islamabad International. We’ll handle the pickup and drop-off so you can focus on your business.</p>

        <h3>10. Are your vehicles insured?</h3>
        <p>Yes, all of our vehicles come with full insurance coverage. Our pricing includes the cost of insurance, so you won’t have to worry about additional fees. You will only need to cover fuel charges and VAT.</p>

        <h3>11. Are there any additional charges for corporate bookings?</h3>
        <p>We strive to provide transparent pricing. The rates you see online are the rates you will be charged, with the exception of fuel charges, VAT, and any overtime charges (if applicable). Additional services like extra waiting time or extended rental periods may incur extra fees.</p>

        <h3>12. What qualifications do your chauffeurs have?</h3>
        <p>Our chauffeurs are licensed professionals with extensive experience in driving corporate clients. They are trained to follow corporate etiquette and are knowledgeable about the best routes and city navigation. Our drivers are dedicated to providing a high level of service, ensuring a smooth and comfortable journey for your team or clients.</p>

        <h3>13. What cities do you serve for corporate travel?</h3>
        <p>We serve all major cities across Pakistan, including:</p>
        <ul>
            <li>Karachi</li>
            <li>Lahore</li>
            <li>Islamabad</li>
            <li>Rawalpindi</li>
            <li>Multan</li>
            <li>Peshawar</li>
            <li>Faisalabad</li>
            <li>Sakhar</li>
            <li>Larkana</li>
            <li>Nawabshah</li>
            <li>Quetta</li>
            <li>Hyderabad</li>
        </ul>
        <p>We also provide intercity travel between these cities, ensuring your corporate needs are met wherever you need to go.</p>

        <h3>14. What is the minimum duration for renting a vehicle?</h3>
        <p>For corporate rentals, the minimum rental duration is typically 4-8 hours, depending on the vehicle and service. However, we offer flexible rental options, including daily or multi-day rentals, to accommodate your travel schedule.</p>
    </section>


              
            </div>
            </div>
            </div>
            </div>
            </>
           
        )
    
}


export default Contact;