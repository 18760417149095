import React, { useState , useEffect } from 'react';
import { Link } from 'react-router-dom';
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import swal from 'sweetalert';

import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ReceiptIcon from '@material-ui/icons/Receipt';
import LocalMallIcon from '@material-ui/icons/LocalMall';



import pay from '../assets/images/pay.png';




import icon1 from '../assets/images/icon1 1.png';
import icon2 from '../assets/images/icon2 1.png';
import icon3 from '../assets/images/icon3 1.png';
import icon4 from '../assets/images/icon4 1.png';





import {Helmet} from "react-helmet";




const SubCategoriesProduct = (props)=> {
    const [CategoriesUrl , setCategoriesUrl] = useState("")
    const [Product , setProduct] = useState([])
    const [Product1 , setProduct1] = useState([])
    const [Product2 , setProduct2] = useState([])
    const [Categories , setCategories] = useState([])
    const [fal , setFal] = useState(false)
    const [fal1 , setFal1] = useState(false)
    const [CategoriesName , setCategoriesname] = useState({})
    const [SubCategories , setSubCategories] = useState([])
    const [SubClassCategories , setSubClassCategories] = useState([])
    const [subCate , setsubCate] = useState([])
    const [allproduct , setallproduct] = useState([])
    const [Subproduct , setSubproduct] = useState([])
    const [Subproduct1 , setSubproduct1] = useState([])
    const [SortedData , setSortedData] = useState([])
    const [SortedName , setSortedName] = useState([])
    const [SliderPhoto , setSliderPhoto] = useState([])
    const [DataPart2 , setDataPart2] = useState([])
    const [MainCatogories , setMainCatogories] = useState([])
    const [dispaluy53 , setdispaluy53] = useState(true)
    const [Cate1 , setCate1] = useState([])
    const [Cate2 , setCate2] = useState([])
    const [Cities , setCities] = useState(true)

    // categoriesName subcategories

    useEffect(() =>{

        window.scrollTo(0, 0)
        // console.log( props.match.params);
        let Categoriesname = props.match.params ;
        setCategoriesname(Categoriesname)

        // const Inter = setInterval(()=>{
            //  if (!JSON.parse(localStorage.getItem("CateProduct")) ){
            //  var data1 = JSON.parse(localStorage.getItem("Cate")) 

        // var Category =  props.match.url.split("/")
        // setCategoriesUrl(data1)
        // var Category1 =  CategoriesUrl.split("-").join(" ")
        fetch("https://pak-dream-back.vercel.app/AllProduct",{
                    method: "GET",
                    headers :  {
                    "Content-Type" : "application/json" , 
                } ,
                })
                .then(res7=>res7.json())
                .then(res8=>{
                    setallproduct(res8)
                    const NonActive = res8.filter((res9,i)=>{
                        // console.log(res9.status , res9.Product_Catagories , "Cars"  , res9.Product_Sub_Catagories , Categoriesname.subCate );
                        if(res9.status && res9.Product_Catagories === "Cars" && res9.vehicles === "Budget" ){
                            return res9 
                        }
                    })

                    setSubproduct(NonActive)
                    console.log(res8)
                })

        
       

                // fetch("https://pak-dream-back.vercel.app/AllProduct",{
                //     method: "GET",
                //     headers :  {
                //     "Content-Type" : "application/json" , 
                // } ,
                // })
                // .then(res7=>res7.json())
                // .then(res8=>{
                //     setallproduct(res8)
                //     const NonActive = res8.filter((res9,i)=>{
                //         console.log(res9.status , res9.Product_Catagories , "Cars"  , res9.Product_Sub_Catagories , Categoriesname.subCate );
                //         if(res9.status && res9.Product_Catagories === "Cars"  ){
                //             return res9 
                //         }
                //     })

                //     setSubproduct(NonActive)
                //     console.log(NonActive)
                // })

        

      



},[])


    const setSortPrice1 = (e)=>{
      setdispaluy53(!dispaluy53)
      if(Subproduct.length !== 0){

        SortedName.push(e)
        console.log(e,Subproduct);
        if (e === "Low"){
            const Pro6 = Subproduct.sort(function (a, b) {
                    return parseFloat(a.Product_Price) - parseFloat(b.Product_Price)  
                  });
                  console.log(Pro6);
                  let reversed_array = [];
                  Pro6.forEach((element) => {
                    reversed_array.unshift(element);
                });
                  console.log(Pro6,reversed_array)
                  setSubproduct(reversed_array)
                  setSubproduct(Pro6)
                  setdispaluy53(!dispaluy53)
                  setdispaluy53(!dispaluy53)
    
        }
        if (e === "High"){
            const Pro6 = Subproduct.sort((a, b)=> {
                    return parseFloat(b.Product_Price) - parseFloat(a.Product_Price)  
                    
                });
              //   let reversed_array = [];
              //   Pro6.forEach((element) => {
              //     reversed_array.unshift(element);
              // });
              //   console.log(Pro6,reversed_array)
                setSubproduct(Pro6)
                setdispaluy53(!dispaluy53)
                setdispaluy53(!dispaluy53)

        }
        if (e === "A"){
            const Pro6 = Subproduct.sort((a, b)=> {
                var nameA = a.Product_Name.toUpperCase(); 
                var nameB = b.Product_Name.toUpperCase(); 
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
    
                return 0;
                });
                console.log(Pro6);
                setSubproduct(Pro6)
                
              }
              
              setSubproduct1([])
              setdispaluy53(!dispaluy53)

            }
            else{
        
                SortedName.push(e)
                console.log(e);
                if (e === "Low"){
                    const Pro6 = Subproduct.sort((a, b)=> {
                            return parseFloat(b.Product_Price) - parseFloat(a.Product_Price)  
                            
                        });
                        setSubproduct1(Pro6)
            
                }
                if (e === "High"){
                    const Pro6 = Subproduct.sort((a, b)=> {
                            return parseFloat(b.Product_Price) - parseFloat(a.Product_Price)  
                            
                        });
                        setSubproduct1(Pro6.reverse())
            
                }
                if (e === "A"){
                    const Pro6 = Subproduct.sort((a, b)=> {
                        var nameA = a.Product_Name.toUpperCase(); 
                        var nameB = b.Product_Name.toUpperCase(); 
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
            
                        return 0;
                        });
                        console.log(Pro6);
                        setSubproduct1(Pro6)
                        
                      }
                      
                      
                      setSubproduct([])
                    }
                    setdispaluy53(!dispaluy53)

                  }
  

  
const addtocartproduct = (productDetail) =>{
  swal("Update!", "Your Item Add in Cart!", "success");

      
  // document.getElementById("myDi").style.visibility = "visible"
  // setTimeout(()=>{
  // document.getElementById("myDi").style.visibility = "hidden"

  // },1000)
  console.log(productDetail)
  if (productDetail[0].Product_Price_Discounted === null){
  const data =  {...productDetail[0] ,
      Percentage : 0,
      DeliveryStatus : "Pending",
      Pieces : 1 ,
      Total_Product_Price  : productDetail[0].Product_Price * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id ){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
  return {...item,
      Pieces : 1 + item.Pieces,
      Total_Product_Price  : (productDetail[0].Product_Price * 1 )+ item.Total_Product_Price}
  }
  else{
  console.log("double not match");
  return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }

  }
  else if (productDetail[0].Product_Price_Discounted){
  const data =  {...productDetail[0] ,
      Percentage : 0,
      DeliveryStatus : "Pending",
      Pieces : 1 ,
      Total_Product_Price  : productDetail[0].Product_Price_Discounted * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id ){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
  return {...item,
      Pieces : 1 + item.Pieces,
      Total_Product_Price  : (productDetail[0].Product_Price_Discounted * 1 )+ item.Total_Product_Price}
  }
  else{
  console.log("double not match");
  return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }

  }
  else if (productDetail[0].Size_Discounted[0]){
      
          const data =  {...productDetail[0] ,
              Percentage : 0,
              DeliveryStatus : "Pending",
              Pieces : 1 ,
              Size : productDetail[0].Size[0],
              Product_Price_Discounted : productDetail[0].Size_Discounted[0],
              Total_Product_Price  : productDetail[0].Size_Discounted[0] * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
      return {...item,
          Pieces : 1 + item.Pieces,
          Total_Product_Price  : (productDetail[0].Size_Discounted[0] * 1 )+ item.Total_Product_Price}
  }
  else{
      console.log("double not match");
      return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }

  }
  else{




          const data =  {...productDetail[0] ,
              Percentage : 0,
              DeliveryStatus : "Pending",
              Pieces : 1 ,
              Size : productDetail[0].Size[0],
              Product_Price_Discounted : productDetail[0].Price[0],
              Total_Product_Price  : productDetail[0].Price[0] * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
      return {...item,
          Pieces : 1 + item.Pieces,
          Total_Product_Price  : (productDetail[0].Price[0] * 1 )+ item.Total_Product_Price}
  }
  else{
      console.log("double not match");
      return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }


  }


      

}


const addtocartproduct8 = (productDetail) =>{
    swal("Update!", "Item Save!", "success");
  
        
    // document.getElementById("myDi").style.visibility = "visible"
    // setTimeout(()=>{
    // document.getElementById("myDi").style.visibility = "hidden"
  
    // },1000)
    console.log(productDetail)
    if (productDetail[0].Product_Price_Discounted === null){
    const data =  {...productDetail[0] ,
        Percentage : 0,
        DeliveryStatus : "Pending",
        Pieces : 1 ,
        Total_Product_Price  : productDetail[0].Product_Price * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id ){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
    return {...item,
        Pieces : 1 + item.Pieces,
        Total_Product_Price  : (productDetail[0].Product_Price * 1 )+ item.Total_Product_Price}
    }
    else{
    console.log("double not match");
    return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
    }
    else if (productDetail[0].Product_Price_Discounted){
    const data =  {...productDetail[0] ,
        Percentage : 0,
        DeliveryStatus : "Pending",
        Pieces : 1 ,
        Total_Product_Price  : productDetail[0].Product_Price_Discounted * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id ){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
    return {...item,
        Pieces : 1 + item.Pieces,
        Total_Product_Price  : (productDetail[0].Product_Price_Discounted * 1 )+ item.Total_Product_Price}
    }
    else{
    console.log("double not match");
    return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
    }
    else if (productDetail[0].Size_Discounted[0]){
        
            const data =  {...productDetail[0] ,
                Percentage : 0,
                DeliveryStatus : "Pending",
                Pieces : 1 ,
                Size : productDetail[0].Size[0],
                Product_Price_Discounted : productDetail[0].Size_Discounted[0],
                Total_Product_Price  : productDetail[0].Size_Discounted[0] * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
        return {...item,
            Pieces : 1 + item.Pieces,
            Total_Product_Price  : (productDetail[0].Size_Discounted[0] * 1 )+ item.Total_Product_Price}
    }
    else{
        console.log("double not match");
        return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
    }
    else{
  
  
  
  
            const data =  {...productDetail[0] ,
                Percentage : 0,
                DeliveryStatus : "Pending",
                Pieces : 1 ,
                Size : productDetail[0].Size[0],
                Product_Price_Discounted : productDetail[0].Price[0],
                Total_Product_Price  : productDetail[0].Price[0] * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
        return {...item,
            Pieces : 1 + item.Pieces,
            Total_Product_Price  : (productDetail[0].Price[0] * 1 )+ item.Total_Product_Price}
    }
    else{
        console.log("double not match");
        return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
  
    }
  
  
        
  
  }
  
        return (
       




<>
           <Helmet>
                <link rel="canonical" href="https://pakdreamrentacar.com/budget-cars" />
            </Helmet>
          <main className="main">
          <div className="text-center">
            <div className="container">
              <h1 className="page-title" style={{fontSize:"27px",fontWeight:"bold", margin:"0px 10px",padding:0,textTransform:"uppercase"}}>Budget Cars on Rent
              <span></span></h1>
              <p>
              Pak Dream Rent a Car offers a wide variety of budget-friendly rental options designed to meet the diverse needs of our customers. Whether you're traveling for work, running daily errands, or planning a short family trip, our affordable budget car rentals ensure a comfortable and economical ride.

              </p>
            </div>{/* End .container */}
          </div>{/* End .page-header */}
<hr  style={{margin:"0px"}}/>
          <div className="page-content">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="toolbox">
                    <div className="toolbox-left" style={{width:"100%"}}>
                      <div className="toolbox-info" style={{width:"100%"}}>
                     <div className='dskjffkj' style={{width:"100%",display:"flex",color : "black"}}>
                       <div className='sfsdf' style={{display:"flex",flexDirection :"column",width:"140px",marginRight : "100px"}}>
                         <span style={{color : "black",fontSize:"17px"}}>Price </span>
                         <span style={{marginRight : "20px",width:"140px",color : "black",fontSize:"15px"}} > Rs : 4500 to 20000 </span>
                         <div style={{display:"flex"}}>

                         <input className='gklfgkl' type="range" name="" id="" value={0} min="0" max="9500" style={{color:"blue",color : "black",fontSize:"17px",padding:"0px",width:"60px"}}/>
                         <input className='gklfgkl' type="range" name="" id="" value={19500} min="9500" max="20000" style={{color:"blue",color : "black",fontSize:"17px",padding:"0px",width:"60px"}}/>
                         </div>
                        </div>
                        <div>
                          <span style={{color : "black",fontSize:"18px"}}>{Subproduct.length} <b>Cars</b>  founds</span>
                        </div>
                        </div> 
                        
                      </div>{/* End .toolbox-info */}
                    </div>{/* End .toolbox-left */}
                    <div className="toolbox-right" style={{width:"100%",justifyContent:"right",alignItems:"flex-end"}}>
                      <div className="toolbox-sort">
                        <label htmlFor="sortby">Sort by:</label>
                        <div className="select-custom">
                          <select name="sortby" id="sortby" className="form-control">
                            <option value="popularity" selected="selected">Most Popular</option>
                            <option value="Low" onClick={()=>setSortPrice1("Low")}>Low → High</option>
                          <option value="High" onClick={()=>setSortPrice1("High")}>High → Low</option>
                          <option value="A" onClick={()=>setSortPrice1("A")}>A → Z</option>
                            <option value="rating">Most Rated</option>
                            <option value="date">Date</option>
                          </select>
                        </div>
                      </div>{/* End .toolbox-sort */}
                      
                    </div>{/* End .toolbox-right */}
                  </div>{/* End .toolbox */}
                  <div style={{display:"flex",justifyContent:"space-evenly",color:"black",fontSize:"19px",fontWeight:"500"}}>

{/* <input className='gklfgkl' type="radio" name="" id="" value={0} min="0" max="9500" style={{color:"blue",color : "black",fontSize:"17px",padding:"0px",width:"60px"}}/>
<input className='gklfgkl' type="radio" name="" id="" value={19500} min="9500" max="20000" style={{color:"blue",color : "black",fontSize:"17px",padding:"0px",width:"60px"}}/> */}
<span>
 <input className='gklfgkl' type="radio" value={Cities} name="Cities1"  onClick={()=>setCities(true)}  style={{accentColor:"firebrick", backgroundColor:"#EFE5CE",border: "10px solid #EFE5CE",marginRight:"9px"}}/> In City
</span>
<span>
 <input className='gklfgkl' type="radio" value={Cities} name="Cities1"  onClick={()=>setCities(false)}   style={{accentColor:"firebrick", backgroundColor:"#EFE5CE",border: "10px solid #EFE5CE",marginRight:"9px"}}/> Out City

</span>
<span>
 <input className='gklfgkl' type="radio" value={Cities} name="Cities1"  onClick={()=>setCities(true)}   style={{accentColor:"firebrick", backgroundColor:"#EFE5CE",border: "10px solid #EFE5CE",marginRight:"9px"}}/> Short Rental

</span>

</div>
                  <div className="products mb-3">
                    <div className="row justify-content-center">
                    {Cities ? 
                    Subproduct.map((res,i)=>{
                        return(
      <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                              <div className="product product-7 text-center" style={{height : "auto",
    border: "2px solid rgb(149, 6, 13)",
    borderRadius: "20px",
    padding: "10px 0px",
    margin: "11px"}}>
                                <figure className="product-media">
                                  <Link  to={"/"+res.Product_Name_Full}>
                                    <img src={res.Product_Image_Upload} alt="Product image" className="product-image" />
                                  </Link>
                                  <div className="product-action-vertical">
                                    <a className="btn-product-icon btn-wishlist btn-expandable"  onClick={()=>addtocartproduct8([res])}><span>add to wishlist</span></a>
                                    <Link to={"/"+res.Product_Name_Full} className="btn-product-icon btn-quickview" title="Quick view"><span>Quick view</span></Link>
                                  </div>{/* End .product-action-vertical */}
                                </figure>{/* End .product-media */}
                                <div className="product-body">
                                  <div className="product-cat">
                                  </div>{/* End .product-cat */}
                                  <h3 className="product-title"   style={{maxWidth:"100%",maxHeight : "59px",overflow : "hidden",textAlign:"left"}}><Link to={"/"+res.Product_Name_Full}>{res.Product_Name}</Link></h3>{/* End .product-title */}


                            <div style={{display :"flex",flexDirection : "row",justifyContent :"space-between"}}>
                            <div>
                                <div style={{display:"flex",margin:"0px 0px 15px 0px"}}>
                                <img src={icon1} style={{height:"23px",width:"20px",color: "#95060D",fontSize : "15",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.person && res.person } Seats</p>
                            </div>
                                <div style={{display:"flex",}}>
                                <img src={icon2} style={{height:"23px",width:"20px",color: "#95060D",fontSize : "15",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>Automatic</p>
                            </div>
                            </div>
                            <div>
                               
                                <div style={{display:"flex",flexDirection:"column"}}>
                                  <div>

                                {res.Product_Price_Discounted ? 
                  <div style={{display:"flex"}}>
                  <del><span className="product-price" style={{fontSize:"13px",fontWeight:"bold",color:"black"}}>Rs {res.Product_Price} <br /></span></del>
                    <span className="product-price" style={{fontSize:"19px",fontWeight:"bold",color:"rgb(149, 6, 13)",padding:"0px 20px"}}>Rs {res.Product_Price_Discounted} <br /> / Day</span> 
                  </div>
                  :
                  <span className="product-price" style={{fontSize:"19px",fontWeight:"bold",color:"rgb(149, 6, 13)",padding:"0px 20px"}}>                  Rs {(res.Product_Price)} <br />
             / Day
                  </span>            
                                    }
                                  </div>
                              <div>
                              <button class="animated-button8" style={{marginBottom:"0px"}}>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  With Driver
</button>
                              </div>

                             
                            </div>
                           
                            </div>
                            </div>
                            <div style={{display :"flex",flexDirection : "row",justifyContent :"space-between"}}>
                            <div>
                                <div style={{display:"flex",margin:"0px 0px 15px 0px"}}>
                                <img src={icon3} style={{height:"16px",width:"20px",color: "#95060D",fontSize : "15",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black",textAlign:"left"}}> ( 10hrs/day )
                                {/* <p style={{fontSize:11,fontWeight :"600",color:"black",textAlign:"left"}}>With Driver (10hrs/day) */}
                                </p>
                            </div>
                                <div style={{display:"flex",}}>
                                <img src={icon4} style={{height:"19px",width:"20px",color: "#95060D",fontSize : "15",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>Overtime: PKR {res.Overtime}/hr</p>
                            </div>
                            </div>
                            <div>
                                <div style={{display:"flex",marginTop:"40px"}}>
                                <div style={{display:"flex",marginRight: "28px"}}>
                                <ReceiptIcon style={{height:"19px",width:"20px",color: "rgb(205 112 112)",fontSize : "18",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>Without Tax</p>
                            </div>
                                {/* <button class="animated-button8">
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  With Driver
</button> */}
                                {/* {res.Product_Price_Discounted ? 
                  <><span className="product-price" style={{fontSize:"14px",fontWeight:"bold",color:"black"}}>Rs {res.Product_Price_Discounted} <br /> / Day</span></>
                  :
                  <span  style={{fontSize:"14px",fontWeight:"bold",color:"black"}}>
                  Rs {(res.Product_Price)} <br />
             / Day
                  </span>            
                                    } */}
                            </div>
                            </div>
                            </div>
                        







                                  {/* <hr style={{margin:"0px"}}/>           */}
                                  {/* { res && res.luggage &&  res.luggage > 0 ?
                            <div style={{display :"flex",flexDirection : "row",justifyContent :"space-between"}}>
                            <div>
                                <div style={{display:"flex",}}>
                                <DirectionsCarIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.doors && res.doors} Doors</p>
                            </div>
                                <div style={{display:"flex",}}>
                                <MeetingRoomIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.petrol && res.petrol}</p>
                            </div>
                            </div>
                            <div>
                                <div style={{display:"flex",}}>
                                <WhatshotIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.cc && res.cc} cc</p>
                            </div>
                                <div style={{display:"flex",}}>
                                <LocalMallIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.luggage && res.luggage} Lugg</p>
                            </div> 
                            </div>
                            <div>
                                <div style={{display:"flex",}}>
                                <ReceiptIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.ac && res.ac === "yes" ? "Ac" : "No AC"}</p>
                            </div>
                            <div style={{display:"flex",}}>
                                <SupervisorAccountIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.person && res.person} </p>
                            </div>
                            </div>
                            </div>
                            :""           
                      } */}
                      {/* <hr  style={{margin:"0px"}}/>
                    <div style={{display:"flex",marginTop:"10px"}}>
                         <h3 className="product-title"   style={{maxWidth:"100%",maxHeight : "59px",overflow : "hidden",textAlign:"left"}}>Earn upto 5% Off  </h3>
                         
                        </div>  */}
                      {/* <hr  style={{margin:"0px"}}/>               */}
                                  <div className="product-action position-relative visible" style={{display:"flex",justifyContent:"space-between",marginTop:"10px"}}>
                                    {/* {res.Product_Price_Discounted ? 
                    <><span className="product-price" style={{fontSize:"14px",fontWeight:"bold",color:"black"}}>Rs {res.Product_Price_Discounted} <br /> / Day</span></>
                    :
                    <span  style={{fontSize:"14px",fontWeight:"bold",color:"black"}}>
                    Rs {(res.Product_Price)} <br />
                / Day
                    </span>            
                                        }  */}
                                    <span className="shadow-none" style={{borderRadius:"15px",maxWidth:"150px",color:"white",background :"rgb(87 1 6)",padding :"10px 15px",color  :"white",fontSize:"12px",fontWeight:"bold"}} ><a href="tel:+923332221927" style={{color  :"white"}}>Call Now</a> </span>
                                    <span className="shadow-none" style={{borderRadius:"15px",maxWidth:"150px",color:"white",background :"rgb(87 1 6)",padding :"10px 15px",color  :"white",fontSize:"12px",fontWeight:"bold"}} onClick={()=>addtocartproduct([res])}>Book Now</span>
                                </div>     
                                </div>
                              </div>
                            </div>
       )
    })
    :
                    Subproduct.map((res,i)=>{
                        return(
      <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                              <div className="product product-7 text-center" style={{height : "auto",
    border: "2px solid rgb(149, 6, 13)",
    borderRadius: "20px",
    padding: "10px 0px",
    margin: "11px"}}>
                                <figure className="product-media">
                                  <Link  to={"/"+res.Product_Name_Full}>
                                    <img src={res.Product_Image_Upload} alt="Product image" className="product-image" />
                                  </Link>
                                  <div className="product-action-vertical">
                                    <a className="btn-product-icon btn-wishlist btn-expandable"  onClick={()=>addtocartproduct8([res])}><span>add to wishlist</span></a>
                                    <Link to={"/"+res.Product_Name_Full} className="btn-product-icon btn-quickview" title="Quick view"><span>Quick view</span></Link>
                                  </div>{/* End .product-action-vertical */}
                                </figure>{/* End .product-media */}
                                <div className="product-body">
                                  <div className="product-cat">
                                  </div>{/* End .product-cat */}
                                  <h3 className="product-title"   style={{maxWidth:"100%",maxHeight : "59px",overflow : "hidden",textAlign:"left"}}><Link to={"/"+res.Product_Name_Full}>{res.Product_Name}</Link></h3>{/* End .product-title */}


                            <div style={{display :"flex",flexDirection : "row",justifyContent :"space-between"}}>
                            <div>
                                <div style={{display:"flex",margin:"0px 0px 15px 0px"}}>
                                <img src={icon1} style={{height:"23px",width:"20px",color: "#95060D",fontSize : "15",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.person && res.person } Seats</p>
                            </div>
                                <div style={{display:"flex",}}>
                                <img src={icon2} style={{height:"23px",width:"20px",color: "#95060D",fontSize : "15",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>Automatic</p>
                            </div>
                            </div>
                            <div>
                               
                                <div style={{display:"flex",flexDirection:"column"}}>
                                  <div>

                 
                  <span className="product-price" style={{fontSize:"19px",fontWeight:"bold",color:"rgb(149, 6, 13)",padding:"0px 20px"}}>                  Rs {(res.OutCity)} <br />
             / Day
                  </span>            
                                  
                                  </div>
                              <div>
                              <button class="animated-button8" style={{marginBottom:"0px"}}>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  With Driver
</button>
                              </div>

                             
                            </div>
                           
                            </div>
                            </div>
                            <div style={{display :"flex",flexDirection : "row",justifyContent :"space-between"}}>
                            <div>
                                <div style={{display:"flex",margin:"0px 0px 15px 0px"}}>
                                <img src={icon3} style={{height:"16px",width:"20px",color: "#95060D",fontSize : "15",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black",textAlign:"left"}}> ( 24hrs/day )
                                {/* <p style={{fontSize:11,fontWeight :"600",color:"black",textAlign:"left"}}>With Driver (10hrs/day) */}
                                </p>
                            </div>
                                <div style={{display:"flex",}}>
                                <ReceiptIcon style={{height:"19px",width:"20px",color: "rgb(205 112 112)",fontSize : "15",marginRight  :"10px"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>Without Tax</p>
                            </div>
                            </div>
                            <div>
                                <div style={{display:"flex",marginTop:"20px"}}>
                                {/* <button class="animated-button8">
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  With Driver
</button> */}
                                {/* {res.Product_Price_Discounted ? 
                  <><span className="product-price" style={{fontSize:"14px",fontWeight:"bold",color:"black"}}>Rs {res.Product_Price_Discounted} <br /> / Day</span></>
                  :
                  <span  style={{fontSize:"14px",fontWeight:"bold",color:"black"}}>
                  Rs {(res.Product_Price)} <br />
             / Day
                  </span>            
                                    } */}
                            </div>
                            </div>
                            </div>
                        







                                  {/* <hr style={{margin:"0px"}}/>           */}
                                  {/* { res && res.luggage &&  res.luggage > 0 ?
                            <div style={{display :"flex",flexDirection : "row",justifyContent :"space-between"}}>
                            <div>
                                <div style={{display:"flex",}}>
                                <DirectionsCarIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.doors && res.doors} Doors</p>
                            </div>
                                <div style={{display:"flex",}}>
                                <MeetingRoomIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.petrol && res.petrol}</p>
                            </div>
                            </div>
                            <div>
                                <div style={{display:"flex",}}>
                                <WhatshotIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.cc && res.cc} cc</p>
                            </div>
                                <div style={{display:"flex",}}>
                                <LocalMallIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.luggage && res.luggage} Lugg</p>
                            </div> 
                            </div>
                            <div>
                                <div style={{display:"flex",}}>
                                <ReceiptIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.ac && res.ac === "yes" ? "Ac" : "No AC"}</p>
                            </div>
                            <div style={{display:"flex",}}>
                                <SupervisorAccountIcon style={{color: "#95060D",fontSize : "15",marginRight  :"10"}}/>
                                <p style={{fontSize:11,fontWeight :"600",color:"black"}}>{res && res.person && res.person} </p>
                            </div>
                            </div>
                            </div>
                            :""           
                      } */}
                      {/* <hr  style={{margin:"0px"}}/>
                    <div style={{display:"flex",marginTop:"10px"}}>
                         <h3 className="product-title"   style={{maxWidth:"100%",maxHeight : "59px",overflow : "hidden",textAlign:"left"}}>Earn upto 5% Off  </h3>
                         
                        </div>  */}
                      {/* <hr  style={{margin:"0px"}}/>               */}
                                  <div className="product-action position-relative visible" style={{display:"flex",justifyContent:"space-between",marginTop:"10px"}}>
                                    {/* {res.Product_Price_Discounted ? 
                    <><span className="product-price" style={{fontSize:"14px",fontWeight:"bold",color:"black"}}>Rs {res.Product_Price_Discounted} <br /> / Day</span></>
                    :
                    <span  style={{fontSize:"14px",fontWeight:"bold",color:"black"}}>
                    Rs {(res.Product_Price)} <br />
                / Day
                    </span>            
                                        }  */}
                                    <span className="shadow-none" style={{borderRadius:"15px",maxWidth:"150px",color:"white",background :"rgb(87 1 6)",padding :"10px 15px",color  :"white",fontSize:"12px",fontWeight:"bold"}} ><a href="tel:+923332221927" style={{color  :"white"}}>Call Now</a> </span>
                                    <span className="shadow-none" style={{borderRadius:"15px",maxWidth:"150px",color:"white",background :"rgb(87 1 6)",padding :"10px 15px",color  :"white",fontSize:"12px",fontWeight:"bold"}} onClick={()=>addtocartproduct([res])}>Book Now</span>
                                </div>     
                                </div>
                              </div>
                            </div>
       )
    })
}
      
<div className='container' style={{margin : "30px 10px"}}>
            <div className='row'  >
            <div className="col-10 col-lg-10  col-sm-12" style={{margin  :"auto"}}>
            <section>
            <h1>Affordable and Reliable Transportation for Every Journey</h1>
<p style={{color:"black", fontWeight:"400"}}>
    Pak Dream Rent a Car offers a wide variety of budget-friendly rental options designed to meet the diverse needs of our customers. Whether you're traveling for work, running daily errands, or planning a short family trip, our affordable budget car rentals ensure a comfortable and economical ride.
</p>
<p style={{color:"black", fontWeight:"400"}}>
    Our fleet of low-budget cars includes popular hatchbacks such as Suzuki Alto, Daihatsu Mira (660cc), Suzuki Cultus, and WagonR (1000cc). Each vehicle is well-maintained, clean, and ready to accompany you on your next adventure, ensuring reliability and peace of mind.
</p>
<p style={{color:"black", fontWeight:"400"}}>
    When you choose Pak Dream Rent a Car for your budget rental needs, you get much more than just a vehicle. We are committed to providing an exceptional rental experience tailored to your requirements.
</p>

<h2>Why Rent Budget Cars with Pak Dream Rent a Car?</h2>
<ul style={{color:"black", fontWeight:"400"}}>
    <li><strong>Affordable Rates:</strong> Our budget-friendly rentals are priced to help you save without compromising quality.</li>
    <li><strong>Wide Selection:</strong> From compact city cars to versatile hatchbacks, we have a variety of vehicles to suit your needs.</li>
    <li><strong>Fuel Efficiency:</strong> Our cars are equipped with fuel-efficient engines, allowing you to save on rising fuel costs while enjoying smooth rides.</li>
    <li><strong>Flexible Rental Packages:</strong> We offer customizable rental periods and pricing to cater to your schedule and budget.</li>
    <li><strong>Transparent Pricing:</strong> No hidden charges! What you see online is what you pay, with insurance and VAT already included.</li>
    <li><strong>Well-Maintained Cars:</strong> Our vehicles are regularly inspected and kept in top condition for your comfort and safety.</li>
    <li><strong>24/7 Customer Assistance:</strong> Our team is available around the clock to support you, whether it's assistance during a breakdown or helping with bookings.</li>
</ul>

<h2>Why Choose Pak Dream Rent a Car?</h2>
<p style={{color:"black", fontWeight:"400"}}>
    Pak Dream Rent a Car is trusted as one of the leading car rental services, serving Karachi and other major cities across Pakistan. With years of experience, our dedicated team works tirelessly to simplify your travel needs, ensuring you find the ideal car for your journey.
</p>
<p style={{color:"black", fontWeight:"400"}}>
    We take pride in making your rental experience seamless and stress-free:
</p>
<ul style={{color:"black", fontWeight:"400"}}>
    <li><strong>No Hidden Costs:</strong> Insurance is included in our rates for transparency and peace of mind.</li>
    <li><strong>Flexible Packages:</strong> Designed to match your time and mileage needs, with options to adjust as required.</li>
    <li><strong>Rapid Assistance:</strong> In the rare event of a breakdown, a replacement vehicle will be dispatched promptly.</li>
</ul>
<p style={{color:"black", fontWeight:"400"}}>
    Whether you're on a tight budget or simply seeking to save money, Pak Dream Rent a Car has got you covered. Book your budget car today and experience the perfect balance of affordability, quality, and reliability!
</p>

<h2>Frequently Asked Questions - Budget Car Rentals</h2>
{/* <ul style={{color:"black", fontWeight:"400"}}>
    <li><strong>What types of cars are available for Budget Rentals?</strong>
        <ul>
            <li>Suzuki Alto</li>
            <li>Daihatsu Mira</li>
            <li>Suzuki Cultus</li>
            <li>Suzuki WagonR</li>
        </ul>
    </li>
    <li><strong>Can the Budget cars be booked for Out-City travels?</strong>
        <p>No, budget cars are only available for rental within the city of Karachi.</p>
    </li>
    <li><strong>Do the Budget cars come with an Air Conditioner?</strong>
        <p>Yes, all our budget cars are equipped with a fully functioning air conditioner to ensure a comfortable ride.</p>
    </li>
    <li><strong>Are the Budget cars offered with a driver?</strong>
        <p>Yes, you can rent budget cars both with or without a driver. However, the self-drive option is currently available only within Karachi.</p>
    </li>
    <li><strong>What is the per litre mileage on the budget cars?</strong>
        <ul>
            <li>660cc cars (e.g., Mira, Alto): Average mileage of 18 to 22 KM/L</li>
            <li>1000cc cars (e.g., Cultus, WagonR): Average mileage of 14 to 18 KM/L</li>
            </ul>
            </li>
            </ul> */}

            <div class="faq-content">
    <div class="faq-question">
        <input id="q30" type="checkbox" class="panel"/>
        <div class="plus">+</div>
        <label for="q30" class="panel-title">What types of cars are available for Budget Rentals?</label>
        <div class="panel-content">
            <p>Our fleet of budget cars includes popular models like:</p>
            <ul>
                <li>Suzuki Alto</li>
                <li>Daihatsu Mira</li>
                <li>Suzuki Cultus</li>
                <li>Suzuki WagonR</li>
            </ul>
        </div>
    </div>

    <div class="faq-question">
        <input id="q31" type="checkbox" class="panel"/>
        <div class="plus">+</div>
        <label for="q31" class="panel-title">Can the Budget cars be booked for Out-City travels?</label>
        <div class="panel-content">
            <p>No, budget cars are only available for rental within the city of Karachi.</p>
        </div>
    </div>

    <div class="faq-question">
        <input id="q32" type="checkbox" class="panel"/>
        <div class="plus">+</div>
        <label for="q32" class="panel-title">Do the Budget cars come with an Air Conditioner?</label>
        <div class="panel-content">
            <p>Yes, all our budget cars are equipped with a fully functioning air conditioner to ensure a comfortable ride.</p>
        </div>
    </div>

    <div class="faq-question">
        <input id="q33" type="checkbox" class="panel"/>
        <div class="plus">+</div>
        <label for="q33" class="panel-title">Are the Budget cars offered with a driver?</label>
        <div class="panel-content">
            <p>Yes, you can rent budget cars both with or without a driver. However, the self-drive option is currently available only within Karachi.</p>
        </div>
    </div>

    <div class="faq-question">
        <input id="q34" type="checkbox" class="panel"/>
        <div class="plus">+</div>
        <label for="q34" class="panel-title">What is the per litre mileage on the budget cars?</label>
        <div class="panel-content">
            <p>660cc cars (e.g., Mira, Alto): Average mileage of 18 to 22 KM/L</p>
            <p>1000cc cars (e.g., Cultus, WagonR): Average mileage of 14 to 18 KM/L</p>
        </div>
    </div>
</div>
</section>


    </div>
            </div>
            </div>
         

                      
                    </div>{/* End .row */}
                  </div>{/* End .products */}
                  <nav aria-label="Page navigation">
                    <ul className="pagination justify-content-center">
                      <li className="page-item disabled">
                        <a className="page-link page-link-prev" href="#" aria-label="Previous" tabIndex={-1} aria-disabled="true">
                          <span aria-hidden="true"><i className="icon-long-arrow-left" /></span>Prev
                        </a>
                      </li>
                      <li className="page-item active" aria-current="page"><a className="page-link" href="#">1</a></li>
                      {/* <li className="page-item"><a className="page-link" href="#">2</a></li>
                      <li className="page-item"><a className="page-link" href="#">3</a></li>
                      <li className="page-item-total">of 6</li> */}
                      <li className="page-item">
                        <a className="page-link page-link-next" href="#" aria-label="Next">
                          Next <span aria-hidden="true"><i className="icon-long-arrow-right" /></span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>{/* End .col-lg-9 */}
                
              </div>{/* End .row */}
            </div>{/* End .container */}
          </div>{/* End .page-content */}
        </main>{/* End .main */}




</>
        )
    }



export default SubCategoriesProduct;