import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// import ch34 from '../assets/images/Unt.png';
import ch344 from '../assets/images/man-with-car.png';
import ch3444 from '../assets/images/support.png';
import ch34444 from '../assets/images/car-insurance.png';
// import ch344444 from '../assets/images/dollar-bill.png';
// import ch3444444 from '../assets/images/calendar.png';
import ch34444444 from '../assets/images/parking-area.png';
import ab1 from '../assets/images/LAND-CRUISER-SMK-removebg-preview.png';
import Toyo51 from '../assets/images/bannerFortuner.jpeg';
import { Carousel } from 'react-responsive-carousel';

// import aboutBlog from '../assets/images/blog_1.jpg';
// import person1 from '../assets/images/person_1.jpg';
// import person2 from '../assets/images/person_2.jpg';
// import person3 from '../assets/images/person_3.jpg';
// import person4 from '../assets/images/person_4.jpg';



import {Helmet} from "react-helmet";

import ab11 from '../assets/images/about-icon-1.png';
import ab2 from '../assets/images/about-icon-2.png';


import ab3 from '../assets/images/about-img.jpg';



class About extends Component {
    state = {

    }
    componentDidMount(){
        window.scrollTo(0, 0)

    }
    render() {
        return (
            <div>
            
            {/* End Main Top */}
            {/* Start Top Search */}
            
            {/* End Top Search */}
            {/* Start All Title Box */}
            
            {/* End All Title Box */}
            {/* Start About Page  */}



            <Helmet>
                <link rel="canonical" href="https://pakdreamrentacar.com/about" />
            </Helmet>

            <div className="page-header text-center" style={{backgroundImage: 'url("assets/images/page-header-bg.jpg")'}}>
            <div className="container">
              <h1 className="page-title">ABOUT US</h1>
            </div>{/* End .container */}
          </div>{/* End .page-header */}
          <nav aria-label="breadcrumb" className="breadcrumb-nav mb-2">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="">Home</a></li>
                <li className="breadcrumb-item"><a href="#">About Us</a></li>
              </ol>
            </div>{/* End .container */}
          </nav>{/* End .breadcrumb-nav */}





          <div class="container-fluid overflow-hidden about py-5 animate fadeInLeftBig eight">
              <div class="container py-5">
                  <div class="row g-5">
                      <div class="col-xl-6 wow fadeInLeft mb-2" data-wow-delay="0.2s">
                          <div class="about-item">
                              <div class="pb-5">
                                  <h1 class="display-5 text-capitalize" style={{margin:"0px"}}>Pak Dream Transport <span class="text-primary">About</span></h1>
                                  <p class="mb-0">Welcome to Pak Dream Transport , your premier destination for reliable and affordable car rental services in Karachi. With a wide selection of vehicles, competitive rates, and flexible rental options, we strive to meet all your transportation needs.
                                  Book with us today and discover the convenience and quality that set us apart.
                                  </p>
                              </div>
                              <div class="row g-4">
                                  <div class="col-lg-6">
                                      <div class="about-item-inner border p-2 mb-2">
                                          <div class="about-icon mb-4">
                                              <img src={ab11} class="img-fluid w-50 h-50" alt="Icon"/>
                                          </div>
                                          <h5 class="mb-3" style={{fontWeight:"bold"}}>Our Vision</h5>
                                          <p class="mb-0" style={{color:"black",fontWeight:"500"}}>To become Karachi's leading car rental provider by delivering superior service, and innovative transportation solutions.

</p>
                                      </div>
                                  </div>
                                  <div class="col-lg-6">
                                      <div class="about-item-inner border p-2 mb-2">
                                          <div class="about-icon mb-4">
                                              <img src={ab2} class="img-fluid h-50 w-50" alt="Icon"/>
                                          </div>
                                          <h5 class="mb-3" style={{fontWeight:"bold"}}>Our Mission</h5>
                                          <p class="mb-0" style={{color:"black",fontWeight:"500"}}>To offer a  enjoyable car rental experience with a diverse fleet and 24/7 support, ensuring customer satisfaction and convenience.






</p>
                                      </div>
                                  </div>
                              </div>
                              <p class="text-item my-4" style={{color:"black",fontWeight:"500"}}>Whether you're traveling for business, leisure, or special occasions, Pak Dream is here to make your journey smooth and enjoyable.
                              </p>
                              <div class="row g-4">
                                  {/* <div class="col-lg-6">
                                      <div class="text-center rounded bg-secondary p-4">
                                          <h1 class="display-6 text-white">17</h1>
                                          <h5 class="text-light mb-0">Years Of Experience</h5>
                                      </div>
                                  </div>
                                  <div class="col-lg-6">
                                      <div class="rounded">
                                          <p class="mb-2"><i class="fa fa-check-circle text-primary me-1"></i> Wide Range of Vehicles: </p>
                                          <p class="mb-2"><i class="fa fa-check-circle text-primary me-1"></i> Affordable Rates:</p>
                                          <p class="mb-2"><i class="fa fa-check-circle text-primary me-1"></i> 24/7 Customer Support: </p>
                                          <p class="mb-0"><i class="fa fa-check-circle text-primary me-1"></i> Flexible Rental Options:</p>
                                      </div>
                                  </div> */}
                                    {/* <a class="animated-button1">
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  Learn More
</a> */}
                                  {/* <div class="col-lg-5 d-flex align-items-center">
                                      <a href="/about" class="btn btn-primary rounded py-3 px-5">Learn More</a>
                                  </div> */}
                                  <a href="/about"><button class="animated-button8" style={{marginBottom:"0px"}}>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  Learn More
</button></a>
                                  {/* <div class="col-lg-7">
                                      <div class="d-flex align-items-center">
                                          <img src="img/attachment-img.jpg" class="img-fluid rounded-circle border border-4 border-secondary" style={{width: '100px', height: "100px"}} alt="Image"/>
                                          <div class="ms-4">
                                              <h4>William Burgess</h4>
                                              <p class="mb-0">Carveo Founder</p>
                                          </div>
                                      </div>
                                  </div> */}
                              </div>
                          </div>
                      </div>
                      <div class="col-xl-6 wow fadeInRight" data-wow-delay="0.2s">
                          <div class="about-img">
                              <div class="img-1">
                                  <img src={ab3} class="img-fluid rounded h-100 w-100" alt="" style={{borderRadius:"30px"}}/>
                              </div>
                              <div class="img-2">
                                  <img src={ab1 } class="img-fluid rounded w-100" alt=""/>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
  


          <div className='container' style={{margin : "30px 10px"}}>
            <div className='row'  >
            <div className="col-10 col-lg-10  col-sm-12" style={{margin  :"auto"}}>
            <h2>About Us</h2>
<h3>Welcome to Pak Dream Rent A Car</h3>
<p style={{color:"black", fontWeight:"400"}}>
    At Pak Dream Rent A Car, we are more than just a car rental service—we are your trusted travel partner, dedicated to providing you with luxurious, reliable, and unforgettable journeys across Pakistan. Whether you’re looking for a high-end vehicle for a business trip, a family-friendly car for a vacation, or a luxury coach for a group outing, we are here to offer you a seamless, stress-free experience with every ride.
</p>
<p style={{color:"black", fontWeight:"400"}}>
    Founded on a passion for luxury travel and a commitment to exceptional customer service, Pak Dream Rent A Car has grown into one of Pakistan’s leading car rental companies. Our fleet is made up of the finest vehicles from globally renowned brands, including Mercedes Benz, Audi, Toyota, Honda, and more, ensuring that you always travel in comfort and style. Our professional chauffeurs are dedicated to providing you with the best possible service, making your journey not only convenient but also memorable.
</p>

<h3>Our Vision</h3>
<p style={{color:"black", fontWeight:"400"}}>
    Our vision is to become Pakistan’s leading luxury car rental and travel service provider, offering exceptional experiences to our customers at competitive prices. We aim to deliver not just a vehicle, but an entire journey filled with comfort, safety, and style. Whether you're traveling for business, leisure, or a special occasion, we believe everyone deserves the best, and that’s what we promise.
</p>

<h3>What We Offer</h3>
<p style={{color:"black", fontWeight:"400"}}>
    At Pak Dream Rent A Car, we provide a wide range of vehicles and services to meet your every need. Our offerings include:
</p>
<ol style={{color:"black", fontWeight:"400"}}>
    <li>
        <strong>Luxury Car Rentals:</strong> 
        Indulge in the ultimate driving experience with our luxury cars, including Mercedes Benz, Audi, and BMW. Perfect for corporate events, weddings, or any special occasion, our fleet guarantees class, style, and comfort. All cars come with professional drivers who ensure your trip is hassle-free.
    </li>
    <li>
        <strong>SUVs and Off-Road Rentals:</strong> 
        For those who seek adventure or require more space, we offer SUVs like Toyota Land Cruiser, Prado, Fortuner, and Honda BRV. Our SUVs are equipped to handle both city roads and rugged terrains, making them ideal for both urban excursions and outdoor adventures.
    </li>
    <li>
        <strong>Vans and Coasters for Group Travel:</strong> 
        For group or family travel, we provide 12-seater vans and 22-seater coasters. Whether you are heading out on a family vacation, a corporate retreat, or a sightseeing tour, our vehicles offer comfort, space, and convenience for everyone.
    </li>
    <li>
        <strong>Armored Bullet-Proof Vehicles:</strong> 
        We specialize in providing bulletproof armored vehicles for clients who require enhanced security during travel. These vehicles are fully equipped to ensure your safety while maintaining the luxurious comfort you expect from Pak Dream Rent A Car.
    </li>
    <li>
        <strong>Customized Travel and Tourism Packages:</strong> 
        Explore the beauty of Pakistan with our tailored tourism packages. From the mountains of Hunza to the cultural streets of Lahore, we offer personalized tours that match your travel style. Our team of experts will work with you to create the perfect itinerary that includes comfortable transport, knowledgeable guides, and premium accommodations.
    </li>
</ol>

<h2>Why Choose Pak Dream Rent A Car?</h2>
<ol style={{color:"black", fontWeight:"400"}}>
    <li>
        <strong>1. Premium Fleet:</strong> 
        We take pride in offering a luxurious fleet of vehicles that are regularly maintained, fully insured, and equipped with the latest features to ensure your comfort and safety. Whether you're looking for a luxury sedan, a spacious SUV, or a bulletproof vehicle, we have the perfect option for you.
    </li>
    <li>
        <strong>2. Customer-Centric Service:</strong> 
        Our goal is to provide you with personalized service that exceeds your expectations. From the moment you book with us to the time you arrive at your destination, we are dedicated to ensuring your journey is smooth, comfortable, and stress-free.
    </li>
    <li>
        <strong>3. Experienced Chauffeurs:</strong> 
        All of our chauffeurs are professional, courteous, and highly trained. With extensive knowledge of the roads and a commitment to safety, they ensure that your travel experience is not just about the vehicle, but about the service as well. Whether you need assistance with local attractions or help with your luggage, our chauffeurs are there to serve you.
    </li>
    <li>
        <strong>4. Competitive Pricing:</strong> 
        While we specialize in luxury and premium services, we believe that comfort and elegance should be accessible. Our competitive rates ensure that you can enjoy a first-class experience without breaking the bank. Plus, we offer flexible rental packages that suit your specific needs, from hourly rates to long-term rentals.
    </li>
    <li>
        <strong>5. 24/7 Support:</strong> 
        Your journey with us is backed by 24/7 customer support. Whether you need assistance with booking, have questions during your trip, or face an emergency, our dedicated support team is always just a call away to ensure your needs are met promptly.
    </li>
</ol>

<h3>Our Commitment to Safety and Quality</h3>
<p style={{color:"black", fontWeight:"400"}}>
    At Pak Dream Rent A Car, safety is our top priority. We ensure that all our vehicles undergo regular inspections and are maintained to the highest standards. Our drivers are fully licensed and trained to navigate Pakistan’s diverse terrain and ensure a safe and comfortable journey.
</p>
<p style={{color:"black", fontWeight:"400"}}>
    We understand that every trip is unique, which is why we offer flexible solutions that are customized to your preferences, making sure that your experience with us is nothing short of perfect.
</p>

<h3>Contact Us Today!</h3>
<p style={{color:"black", fontWeight:"400"}}>
    We invite you to experience the Pak Dream Rent A Car difference for yourself. Whether you're planning a luxurious city tour, a long road trip, or require a specialized vehicle for security, we are here to cater to your needs. Let us make your next journey in Pakistan an experience to remember.
</p>
<p style={{color:"black", fontWeight:"400"}}>
    Contact us today for booking inquiries, quotes, or to simply learn more about our services. We look forward to serving you!
</p>

</div>
</div>
</div>


          {/* <div class="video-banner bg-light pt-5 pb-5">
	                <div class="container align-items-center">
	                	<div class="video-banner-box bg-white">
		                	<div class="row align-items-center">
		                		<div class="col-md-6 mb-3 mb-md-0">
		                			<div class="video-box-content">
		                				<h3 class="video-banner-title h1"><span class="text-primary">About Us </span><strong>Pak Dream Rent a Car</strong></h3>
	                					<p>Pak Dream Rent a Car is an on-demand, car-rental marketplace geared to disrupt the traditional renting industry. Pak Dream Rent a Car car-rental services are available at your fingertips online, for a more modern and convenient customer experience. Pak Dream Rent a Car provides both on-demand and pre-scheduled vehicles for any situation, occasion or event, such as: Corporate Office Staff Tours and Trips Weddings Families / Individuals

</p>
	                					<a href="/about" class="btn btn-outline-primary"><span>More About Us</span><i class="icon-long-arrow-right"></i></a>
		                			</div>
		                		</div>
		                		<div class="col-md-6">
		                			<div class="video-poster">
		                				<img src={ab1} alt="poster"/>

		                				<div class="video-poster-content">
		                					<a target='_blank' href="https://www.youtube.com/watch?v=dgOSE4M9-y4" class="btn-video btn-iframe"><i class="icon-play"></i></a>
		                				</div>
		                			</div>
		                		</div>
		                	</div>
	                	</div>
	                </div>
            	</div>
 */}




{/* 
              <div className='container' style={{background :  "#f0f0f0"}}>
<br />
             <p class="text-center">AFFORDABLE CARS IN KARACHI


</p>
             <h2 class="title mb-4 text-center">Why <b>PakDreamRentACar.com?</b> 

</h2>
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-sm-6">
                            <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                                <span class="icon-box-icon cfg" style={{padding  :"0px" , }}>
                                    <img src={ch344} alt="" style={{height   : "40px"}}/>
                                </span>
                                <div class="icon-box-content">
                                    <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}> Tours and Trips

</h3>
                                    <p className="cgg">We offer our services to the Tourists and Group Tours of Offices or Schools.





</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                                                            <span class="icon-box-icon cfg" style={{padding  :"0px" , }}>

                            <img src={ch3444} alt="" style={{height   : "40px"}}/>
</span>
                                <div class="icon-box-content">
                                    <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}>Weddings
</h3>
                                    <p className="cgg" style={{}}>We provide our car rental service on weddings for Groom and other attendees.


</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                                                            <span class="icon-box-icon cfg" style={{padding  :"0px" , }}>

                            <img src={ch34444} alt="" style={{height   : "40px"}}/>
</span>
                                <div class="icon-box-content">
                                    <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}> Families / Individuals

</h3>
                                    <p className="cgg">We offer vehicles like cars and vans to the individuals as well as to the families.



</p>
                                </div>
                            </div>
                        </div>
                      
                    </div>
</div> */}




<div class="bg-image bg-overlay pt-5 pb-4" style={{backgroundImage: 'url('+Toyo51+')',backgroundRepeat : "no-repeat" }}>
<br />
            		<div class="container">
            			<h2 class="title text-center text-white mb-3">What Our Clients Say
</h2>
                  <Carousel autoPlay={true} interval={4000} showThumbs={false} showArrows={true} showStatus={false} swipeable={true} swipeAble={true} infiniteLoop={true} style={{height: "300px"}}>

	                	{/* <div class="owl-carousel owl-theme owl-testimonials owl-light" data-toggle="owl" 
                           > */}
	                		<blockquote class="testimonial testimonial-icon text-center text-white">
		                		<p style={{color : "white"}}>“ Our Rent a car service experience with Pak Dream Rent a Car was very good with best new model cars and Driver services appreciated in future.

”</p>

		                		<cite>
		                		Yasir Arafat

		                			<span>Customer</span>
		                		</cite>
		                	</blockquote>

		                	<blockquote class="testimonial testimonial-icon text-center text-white">
		                		<p style={{color : "white"}}>“ I rented 25 days Toyota corola for my needs to travel within the city Karachi. With family. Excellent service. Driver behavior very good. Best rates.

”</p>

		                		<cite>
		                		Haji Nazeer Gujjar

		                			<span>Customer</span>
		                		</cite>
		                	</blockquote>

		                	<blockquote class="testimonial testimonial-icon text-center text-white">
		                		<p style={{color : "white"}}>“ Our Rent a car service experience with Pak Dream Rent a Car was very good with best new model cars and Driver services appreciated in future.

”</p>

		                		<cite>
		                			Junaid Ghani
		                			<span>Customer</span>
		                		</cite>
		                	</blockquote>
	                	{/* </div> */}
                    </Carousel>

            		</div>
            	</div>
<br />



{/* <div className='container' style={{background :  "#f23e3e"}}>
<br />
<h2 class="title mb-4 text-center" style={{color  :"white"}}> <b>We Are Experts In Car Rental Service Company</b> 

</h2>
          <div class="row justify-content-center">
              <div class="col-lg-3 col-sm-6">
                  <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "100px",justifyContent  : "center",color : "white", background : "#f23e3e" , display : "flex"}} >
                          <img src={ch344} alt="" style={{height   : "40px"}}/>
                    
                      <div class="icon-box-content" style={{paddingLeft:  "20px"}}>
                          <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}> 5,000+

</h3>
                          <p className="cgg1">HAPPY CUSTOMERS




</p>
                      </div>
                  </div>
              </div>

              <div class="col-lg-3 col-sm-6">
                  <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "100px",justifyContent  : "center",color : "white", background : "#f23e3e" , display : "flex"}} >

                  <img src={ch34444} alt="" style={{height   : "40px"}}/>

                      <div class="icon-box-content" style={{paddingLeft:  "20px"}}>
                          <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}>250+ 
</h3>
                          <p className="cgg1" style={{}}>NO. OF CARS

</p>
                      </div>
                  </div>
              </div>

              <div class="col-lg-3 col-sm-6">
                  <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "100px",justifyContent  : "center",color : "white", background : "#f23e3e" , display : "flex"}} >

                  <img src={ch34444444} alt="" style={{height   : "40px"}}/>
                      <div class="icon-box-content" style={{paddingLeft:  "20px"}}>
                          <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}> 4
</h3>
                          <p className="cgg1">Operate In Locations

</p>
                      </div>
                  </div>
              </div>
              
              <div class="col-lg-3 col-sm-6">
                  <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "100px",justifyContent  : "center",color : "white", background : "#f23e3e" , display : "flex"}} >

                  <img src={ch3444} alt="" style={{height   : "40px"}}/>
                      <div class="icon-box-content" style={{paddingLeft:  "20px"}}>
                          <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}> 20+
</h3>
                          <p className="cgg1">Professional Team


</p>
                      </div>
                  </div>
              </div>
              
          </div>
</div>

<br /> */}


























{/* 

<div className='container' style={{margin : "30px 10px"}}>
            <div className='row'  >
            <div className="col-10 col-lg-10  col-sm-12" style={{margin  :"auto"}}>
            <h2>Pak Dream Rent a Car</h2>

                 <p>Welcome to <em>Pak Dream Rent a Car</em>, your premier destination for top-notch rental car services. Discover the perfect blend of style and reliability with our diverse fleet, featuring renowned models such as the Toyota Corolla and Honda HR-V. As your trusted partner in transportation, we are committed to delivering an unparalleled experience that combines comfort, convenience, and excellence.</p>

<h2>Our Mission</h2>

<p>At <em>Pak Dream Rent a Car</em>, our mission is to redefine the rental car experience through a seamless fusion of quality service and customer satisfaction. Whether it's a business trip or a family vacation, our dedication to providing exceptional services ensures that every journey is not just a trip but a memorable adventure.</p>

<h2>Fleet Excellence</h2>

<p>Experience the pinnacle of performance with our meticulously maintained fleet, showcasing the efficiency of the Toyota Corolla and the style of the Honda HR-V. Our commitment to excellence extends to every vehicle, guaranteeing a smooth and enjoyable ride. Choose <em>Pak Dream Rent a Car</em> for a journey that reflects our passion for quality and precision.</p>

<h2>Pickup and Drop-off Services</h2>

<p>Embrace the convenience of our tailored pickup and drop-off services. At <em>Pak Dream Rent a Car</em>, we understand the value of your time, and our services are designed to make your experience seamless. Whether you prefer doorstep delivery or choose to pick up your vehicle at our location, our flexible options cater to your schedule, ensuring a stress-free rental experience.</p>

<h2>Rental Solutions</h2>

<p>Unlock a world of possibilities with our rental solutions. From short-term daily rentals to long-term leases, <em>Pak Dream Rent a Car</em> provides customizable packages to suit your unique needs. Our commitment to versatility ensures that you have the perfect vehicle for every occasion, allowing you to focus on the journey ahead.</p>

<p>Choose <em>Pak Dream Rent a Car</em> for a rental experience that goes beyond expectations. With our dedication to excellence, a diverse and well-maintained fleet, and services optimized for your convenience, we are your ultimate destination for top-tier transportation solutions. Book with us today and elevate your travel experience to new heights.</p>

            </div>
            </div>
            </div> */}
            {/* End About Page */}
            {/* Start Instagram Feed  */}
            
          </div>
        )
    }
}

export default About;